import React, { useEffect, useState } from "react"
import { APP_NAME } from "../.."
import { Link, useNavigate } from "react-router-dom"
import * as ROUTES from "../../constants/routes"
import Fieldset from "../../components/Fieldset"
import { supabase } from "../../lib/supabaseClient"
import Spinner from "../../components/Spinner"

export default function LoginPage() {
	const navigate = useNavigate()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [emailError, setEmailError] = useState(false)
	const [passwordError, setPasswordError] = useState(false)

	async function handleOnSubmit(e) {
		e.preventDefault()

		if (email === "") {
			setEmailError("Email required")
			return
		}

		if (password === "") {
			setPasswordError("Password required")
			return
		}

		setLoading(true)
		const { data, error } = await supabase.auth.signInWithPassword({
			email: email,
			password: password,
		})
		if (error) {
			setError(error.message)
			setLoading(false)
		}
		if (data?.session) {
			navigate(ROUTES.DASHBOARD)
		}
		setLoading(false)
	}

	useEffect(() => {
		document.title = `Log in • ${APP_NAME}`
	}, [])

	return (
		<>
			<form onSubmit={handleOnSubmit}>
				<h1>Log in</h1>

				<Fieldset
					id="email"
					label="Email"
					type="email"
					field={email}
					setField={setEmail}
					error={emailError}
					setError={setEmailError}
				/>
				<Fieldset
					id="password"
					label="Password"
					type="password"
					field={password}
					setField={setPassword}
					error={passwordError}
					setError={setPasswordError}
				/>
				<fieldset>
					<button className="button">
						{loading ? <Spinner inline white /> : "Log in"}
					</button>
				</fieldset>
			</form>
			{error && <p className="error-message">{error}</p>}

			<p>
				<Link to={ROUTES.FORGOT_PASSWORD}>Forgot your password?</Link>
			</p>
			<p>
				<Link to={ROUTES.SIGN_UP}>Don't have an account yet? Sign up</Link>
			</p>
		</>
	)
}
