import React from "react"
import ToggleSwitch from "../ToggleSwitch"
import { useOutletContext } from "react-router-dom"

const SiteNav = () => {
	const { user, setIsWine, isWine } = useOutletContext()

	const currentHour = new Date().getHours()
	const morningRange = { start: 6, end: 12 }
	const eveningRange = { start: 18, end: 22 }
	let greeting = "Hello"
	// Check if current hour falls within each range
	if (currentHour >= morningRange.start && currentHour < morningRange.end) {
		greeting = "Good morning"
	} else if (
		currentHour >= eveningRange.start &&
		currentHour <= eveningRange.end
	) {
		greeting = "Good evening"
	}

	return (
		<div className="siteNav">
			<div className="greeting">
				<span>{greeting} </span>
				{user?.f_name}
			</div>
			<ToggleSwitch isWine={isWine} setIsWine={setIsWine} />
		</div>
	)
}

export default SiteNav
