import AnimatedNumber from "react-animated-numbers"

export default function NumberAnimation({ number }) {
	return (
		<AnimatedNumber
			animateToNumber={number}
			includeComma
			config={{ tension: 89, friction: 40 }}
			animationType={"calm"}
		/>
	)
}
