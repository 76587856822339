import React, { useEffect } from "react"
import { APP_NAME } from ".."
// import Logo from "../components/Logo"
import { useNavigate, useRouteError } from "react-router-dom"
import { useSession } from "../context/SessionContext"
import * as ROUTES from "../constants/routes"

export default function ErrorPage() {
	let error = useRouteError()
	console.error(error)
	const { session } = useSession()
	useEffect(() => {
		document.title = `Page Not Found • ${APP_NAME}`
	}, [])
	const navigate = useNavigate()
	function handleOnSubmit() {
		navigate(session ? ROUTES.DASHBOARD : ROUTES.LANDING_PAGE)
	}

	return (
		<>
			<div className="error-page">
				<div className="container">
					{/* <Logo /> */}
					<form style={{ textAlign: "center" }} onSubmit={handleOnSubmit}>
						<h1>Page not found</h1>
						<p>You seem lost...</p>
						<button className="button">
							Go to {session ? `dashboard` : `log in page`}
						</button>
					</form>
				</div>
			</div>
		</>
	)
}
