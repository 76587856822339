import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

export default function Fieldset({
	id,
	field,
	setField,
	error,
	setError,
	type = "text",
	label,
	checkFunction,
	instructions,
	disabled = false,
	preFix,
	autocomplete,
	max,
	min,
	pattern,
	required,
	spellcheck,
	autocapitalize,
}) {
	return (
		<fieldset className={`fieldset type-${type}`}>
			<div
				style={{ width: type === "time" ? 90 : "100%" }}
				className={`group${field?.length > 0 ? " active" : ""}${
					preFix ? " has-prefix" : ""
				}`}>
				{preFix && <div className="prefix">{preFix}</div>}
				{setField && (
					<input
						disabled={disabled}
						required={required}
						id={id}
						max={max}
						min={min}
						type={type}
						value={field || ""}
						autoComplete={autocomplete || "off"}
						onChange={(e) => {
							setField(e.target.value)
							error && setError(false)
						}}
						spellCheck={spellcheck}
						autoCapitalize={autocapitalize}
						pattern={pattern}
					/>
				)}
				{type === "time" && (
					<div className="clock">
						<FontAwesomeIcon icon="clock" />
					</div>
				)}

				<label htmlFor={id}>{label}</label>
				<div className="bar" />
				{checkFunction && checkFunction(field) && (
					<div className="check">
						<FontAwesomeIcon icon="check" />
					</div>
				)}
				{instructions && !error && (
					<div className="instructions">{instructions}</div>
				)}
				{error && <div className="error">{error}</div>}
			</div>
		</fieldset>
	)
}
