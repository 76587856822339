import React, { useRef } from "react"
import { NavLink, useMatch } from "react-router-dom"
import SearchBar from "./SearchBar"

const ExploreNav = ({ query, setQuery }) => {
	const focusRef = useRef()
	const match1 = useMatch("/explore/locations")
	const match2 = useMatch("/explore/profiles")
	let m = match1 ? 1 : match2 ? 2 : 0
	return (
		<div className="explore-nav">
			<div>
				<SearchBar focusRef={focusRef} query={query} setQuery={setQuery} />
				<nav className="explore-tabs">
					<ul>
						<li className="line" style={{ left: `${33.33 * m}%` }} />
						<li>
							<NavLink to={"./drinks"}>Drinks</NavLink>
						</li>
						<li>
							<NavLink to={"./locations"}>Locations</NavLink>
						</li>
						<li>
							<NavLink to={"./profiles"}>Users</NavLink>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	)
}

export default ExploreNav
