import { useIsFetching } from "@tanstack/react-query"

export default function GlobalLoadingIndicator() {
	const isFetching = useIsFetching()

	return (
		<div className="globale-loading-bar">
			<div className={`progress ${isFetching === 0 ? "loaded" : "loading"}`} />
		</div>
	)
}
