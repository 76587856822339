import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	addDays,
	setHours,
	setMilliseconds,
	setMinutes,
	setSeconds,
} from "date-fns"
import React, { useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { Link, useNavigate, useOutletContext } from "react-router-dom"
import { supabase } from "../../lib/supabaseClient"
import * as ROUTES from "../../constants/routes"
import executeQuery from "../../helpers/executeQuery"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { findNearest, getDistance } from "geolib"

export default function AddLocationPage() {
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { user, currentVisit } = useOutletContext()
	let userId = user?.user_id || null
	let userCoordinates = user?.coordinates || null

	// const [currentVisit, setCurrentVisit] = useState(null)
	const [currentLocationId, setCurrentLocationId] = useState(null)
	const [drinkCount, setDrinkCount] = useState(0)
	const [expireTime, setExpireTime] = useState(null)
	const [timestamp, setTimestamp] = useState(null)

	// LOCATION
	useEffect(() => {
		if (currentVisit) {
			setCurrentLocationId(currentVisit?.location?.id)
		}
	}, [currentVisit])

	// COUNT
	useEffect(() => {
		if (currentVisit) {
			setDrinkCount(currentVisit?.drinks?.length || 0)
		}
	}, [currentVisit])

	// TIME
	useEffect(() => {
		const currentDate = new Date()
		let tempExpireTime
		// If it's already past 8 AM today, set it for 8 AM tomorrow
		if (currentDate.getHours() >= 8) {
			tempExpireTime = setMilliseconds(
				setSeconds(setMinutes(setHours(addDays(currentDate, 1), 8), 0), 0),
				0
			)
		} else {
			// If it's before 8 AM today, set it for 8 AM today
			tempExpireTime = setMilliseconds(
				setSeconds(setMinutes(setHours(currentDate, 8), 0), 0),
				0
			)
		}
		tempExpireTime = tempExpireTime.toJSON()
		setExpireTime(tempExpireTime)
		let tempTimestamp = currentDate.toJSON()
		setTimestamp(tempTimestamp)
	}, [currentVisit])

	// Handle search
	const [error, setError] = useState(false)
	const [locationId, setLocationId] = useState()

	const [query, setQuery] = useState("")
	const [searchResults, setSearchResults] = useState([])

	useEffect(() => {
		const handleSearch = async () => {
			const { data } = await executeQuery(
				supabase
					.from("locations")
					.select(`name, id, address, location_types ( name )`)
					.or(`name.ilike.%${query}%,address.ilike.%${query}%`)
				// .limit(5)
			)

			setSearchResults(data)
		}

		if (query.trim() === "") {
			setSearchResults([])
			return
		}
		handleSearch()
	}, [query])

	function handleResetSearch(e) {
		e.preventDefault()
		setQuery("")
		setError(false)
		setLocationId()
	}

	function handleOnChange(e) {
		setError(false)
		setQuery(e.target.value)
	}

	// new session
	const addNewVisit = useMutation({
		mutationFn: async (obj) => {
			const { data } = await executeQuery(
				supabase.from("user_visits").insert([obj]).select()
			)
			await executeQuery(
				supabase
					.from("profiles")
					.update({ current_visit_id: obj.id })
					.eq("user_id", userId)
					.select()
			)
			return data
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["current_visit", userId])
		},
	})

	//update session
	const updateCurrentVisit = useMutation({
		mutationFn: async (obj) => {
			const { data } = await executeQuery(
				supabase
					.from("user_visits")
					.update({ location_id: obj.location_id })
					.eq("id", currentVisit.id)
					.select()
			)
			return data
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["current_visit", userId])
		},
	})

	async function handleOnSubmit(e) {
		e.preventDefault()

		try {
			if (!locationId) {
				throw new Error("Please add location")
			}

			let newObj = {
				id: uuidv4(),
				user_id: userId,
				location_id: locationId,
				timestamp: timestamp,
				expires: expireTime,
			}

			// Checklist
			let state = "new"
			let has_current_visit = currentVisit !== null
			let has_no_drinks = drinkCount === 0
			let is_same_location = currentLocationId === locationId

			if (has_current_visit && is_same_location && has_no_drinks) {
				state = "update"
			} else if (has_current_visit && is_same_location) {
				state = "update"
			} else if (has_current_visit) {
				state = "new"
			}

			if (state === "new") {
				addNewVisit.mutate(newObj)
			} else {
				updateCurrentVisit.mutate(newObj)
			}
			navigate(ROUTES.ADD_ATTENDEES)
		} catch (error) {
			setError(true)
			console.error(error.message)
		}
	}

	function handleReturn() {
		navigate(ROUTES.DASHBOARD)
	}

	const [nearestLocation, setNearestLocation] = useState(null)
	const [isInProximity, setIsInProximity] = useState(false)

	const { data: geoLocations } = useQuery({
		queryKey: ["geolocations"],
		queryFn: async () => {
			const { data, error } = await supabase
				.from("locations")
				.select("id, name, longitude, latitude")
				.neq("latitude", null)
			if (error) console.error(error)
			return data
		},
	})

	useEffect(() => {
		if (!userCoordinates) return
		if (!geoLocations) return

		setNearestLocation(findNearest(userCoordinates, geoLocations || {}))
	}, [userCoordinates, geoLocations])

	useEffect(() => {
		if (!userCoordinates) return
		if (!nearestLocation) return

		const distance = getDistance(userCoordinates, {
			latitude: nearestLocation?.latitude || 0,
			longitude: nearestLocation?.longitude || 0,
		})
		setIsInProximity(distance <= 20)
	}, [nearestLocation, userCoordinates])

	return (
		<>
			<div className="page-dashboard">
				<div className="page-container">
					<main>
						<div>
							<form method="post" onSubmit={handleOnSubmit}>
								<p>
									<FontAwesomeIcon
										icon="arrow-left"
										style={{ cursor: "pointer" }}
										onClick={() => handleReturn()}
									/>
								</p>
								{currentVisit?.location && (
									<div style={{ opacity: 0.4 }}>
										Moved on from{" "}
										<strong>{currentVisit?.location?.name}</strong>?
									</div>
								)}
								<h3>Where are you{currentVisit ? " now" : ""}?</h3>
								<fieldset>
									<label htmlFor="location">Your location:</label>
									<div className="search-bar">
										<button>
											<FontAwesomeIcon
												icon={`${locationId ? "check" : "search"}`}
											/>
										</button>
										<input
											id="location"
											autoComplete="off"
											className="input"
											placeholder={`_`}
											type="text"
											name="location"
											value={query || ""}
											onChange={handleOnChange}
										/>
										<button onClick={handleResetSearch}>
											<FontAwesomeIcon icon={`times`} />
										</button>
									</div>
									{error && <div className="error">Please add location</div>}
									{!locationId && query && query.length > 0 && (
										<div className="search-bar-results">
											<ul>
												{searchResults &&
													searchResults.length > 0 &&
													searchResults.map((result) => {
														return (
															<li
																key={result.id}
																onClick={() => {
																	setQuery(result.name)
																	setLocationId(result.id)
																	setSearchResults([])
																}}>
																<div>
																	{result.name}
																	{" - "}
																	<small
																		style={{
																			textTransform: "uppercase",
																			opacity: 0.5,
																		}}>
																		{result.location_types?.name}
																	</small>
																</div>
																<div
																	style={{
																		lineHeight: 1,
																	}}>
																	<small>{result.address}</small>
																</div>
															</li>
														)
													})}
											</ul>
										</div>
									)}
								</fieldset>
								<fieldset>
									<p>
										<button className="button">Submit</button>
									</p>
									<p style={{ textAlign: "center" }}>
										<Link to={`../new-location`}>
											Not in the list? <strong>Add it</strong>!
										</Link>
									</p>
								</fieldset>
								{isInProximity && (
									<div className="nearby-location">
										Are you at {nearestLocation?.name} ?{" "}
										<strong
											onClick={() => {
												setQuery(nearestLocation?.name)
												setLocationId(nearestLocation?.id)
											}}>
											Yes
										</strong>
									</div>
								)}
							</form>
						</div>
					</main>
				</div>
			</div>
		</>
	)
}
