import React, { useEffect, useState } from "react"
import bark from "../assets/sounds/bark.mp3"
import { supabase } from "../lib/supabaseClient"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { isToday } from "date-fns"

export function ShibaIcon({ handleOnClick, setPressed, button = true }) {
	return (
		<div
			className="shiba-icon"
			onClick={button ? handleOnClick : undefined}
			onPointerDown={button ? () => setPressed(true) : undefined}
			onPointerUp={button ? () => setPressed(false) : undefined}
		/>
	)
}
export default function ShibaCount({ userId }) {
	const queryClient = useQueryClient()

	const [count, setCount] = useState(0)
	const [currentId, setCurrentId] = useState(null)
	const [isOutdated, setIsOutdated] = useState(false)

	const [pressed, setPressed] = useState(false)
	const [message, setMessage] = useState(null)
	const [disabled, setDisabled] = useState(false)

	// fetch latest Shiba query
	const { data } = useQuery({
		queryKey: ["shibas", userId],
		queryFn: async () => {
			const { data } = await supabase
				.from("shibas")
				.select("id, timestamp, count")
				.eq("user_id", userId)
			let rows = data?.filter((row) => isToday(new Date(row.timestamp))) || []
			return rows[0] || false
		},
		enabled: !!userId,
	})

	// Check if row is outdated
	useEffect(() => {
		let isFromToday = data ? isToday(new Date(data.timestamp)) : false

		setIsOutdated(!isFromToday)

		setCurrentId(data?.id)

		setCount(isFromToday ? data?.count || 0 : 0)
		if (data) {
			setIsOutdated(!isFromToday)
		}
	}, [data])

	useEffect(() => {
		if (count === 9) {
			setMessage(`Do you own a Shiba farm?`)
		}
		if (count > 9) {
			setDisabled(true)
			setMessage(`Okay, that's enough Shibas for one day`)
		}
	}, [count])

	const insertShiba = useMutation({
		mutationFn: async () => {
			const { data } = await supabase
				.from("shibas")
				.insert([{ user_id: userId, count: count }])
				.select()

			return data
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["shibas", userId])
		},
	})

	const updateShiba = useMutation({
		mutationFn: async () => {
			const { data } = await supabase
				.from("shibas")
				.update({ count: count })
				.eq("id", currentId)
				.select()
			return data
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["shibas", userId])
		},
	})

	async function addShiba() {
		if (!userId) return
		let audio = new Audio(bark)
		audio.play()
		setCount(count + 1)
		if (isOutdated) {
			insertShiba.mutate()
		} else {
			currentId ? updateShiba.mutate() : console.error("missing id")
		}
	}

	async function removeShiba() {
		if (!userId) return
		setCount(count - 1)
		currentId ? updateShiba.mutate() : console.error("missing id")
	}

	const handleOnClick = () => {
		if (count <= 9) {
			if (insertShiba.isLoading || updateShiba.isLoading) return
			addShiba()
		}
	}

	const handleOnRemove = () => {
		if (count > 0) {
			removeShiba()
			setDisabled(false)
		}
	}

	return (
		<div className="shiba-modal">
			<h4 className="title">
				{message
					? message
					: `Click the button when
				you spot a shiba`}
			</h4>
			<div
				className={`shiba-button ${pressed && "pressed"} ${
					disabled && "disabled"
				}`}>
				<div
					className="shiba-icon"
					onClick={handleOnClick}
					onPointerDown={() => setPressed(true)}
					onPointerUp={() => setPressed(false)}
				/>
			</div>
			<div className="shiba-pill">
				<div className="cancel" onClick={handleOnRemove}>
					<span />
				</div>
				{count}
			</div>
			<div className="message">{`Shibas seen today`}</div>
		</div>
	)
}
