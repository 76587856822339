import { format } from "date-fns"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import sunset from "../assets/sunset.png"

const niceWeather = {
	city: "Nice",
	country: "France",
	timezone: "Europe/Paris",
	days: [
		{
			date: "2024-01-23",
			temperature: {
				min: 8,
				max: 14,
			},
			description: "Rainy",
			icon: "cloud-rain",
		},
		{
			date: "2024-01-24",
			temperature: {
				min: 12,
				max: 17,
			},
			description: "Sunny",
			icon: "sun",
		},
		{
			date: "2024-01-25",
			temperature: {
				min: 9,
				max: 16,
			},
			description: "Cloudy",
			icon: "cloud",
		},
		{
			date: "2024-01-26",
			temperature: {
				min: 11,
				max: 18,
			},
			description: "Clear Sky",
			icon: "sun",
		},
		{
			date: "2024-01-27",
			temperature: {
				min: 10,
				max: 14,
			},
			description: "Rainy",
			icon: "cloud-rain",
		},
		{
			date: "2024-01-28",
			temperature: {
				min: 7,
				max: 12,
			},
			description: "Partly Cloudy",
			icon: "cloud-sun",
		},
		{
			date: "2024-01-29",
			temperature: {
				min: 10,
				max: 15,
			},
			description: "Partly Cloudy",
			icon: "cloud-sun",
		},
	],
}

function CalendarDay({ day }) {
	const { icon, date } = day
	return (
		<div className={`day`}>
			<div className="weather">
				<div className="icon">
					<FontAwesomeIcon icon={icon} />
				</div>
			</div>
			<div className="name">{format(new Date(date), "EEE")}</div>
		</div>
	)
}

export default function CalendarPage() {
	const today = niceWeather?.days[0]
	return (
		<div className="calendar-page">
			<div className="page-container">
				<header className="page-header">
					<div className="temperature">
						<div className="number">{today?.temperature?.max}</div>
						<div className="unit">
							<span>o</span>
							<span>c</span>
						</div>
					</div>
				</header>
				<main className="page-main">
					<div className="weather">{today?.description}</div>
					<div className="illustration">
						<img src={sunset} alt="sunset" />
					</div>
					<div className="birds" />
				</main>
				<footer className="page-footer">
					<div className="city">{niceWeather?.city}</div>
					<div className="week">
						{niceWeather?.days.map((day) => (
							<CalendarDay key={day?.date} day={day} />
						))}
					</div>
				</footer>
			</div>
		</div>
	)
}
