import { createContext, useContext, useEffect, useState } from "react"
import { supabase } from "../lib/supabaseClient"

const SessionContext = createContext()

export const SessionProvider = ({ children }) => {
	const [session, setSession] = useState(null)

	useEffect(() => {
		const {
			data: { subscription },
		} = supabase.auth.onAuthStateChange((event, session) => {
			if (event === "SIGNED_OUT") {
				setSession(null)

				// clear local and session storage
				Object.entries(window.localStorage).forEach(([key]) => {
					window.localStorage.removeItem(key)
				})

				Object.entries(window.sessionStorage).forEach(([key]) => {
					window.sessionStorage.removeItem(key)
				})
			} else if (session) {
				setSession(session)
			}
		})

		return () => {
			subscription.unsubscribe()
		}
	}, [])

	return (
		<SessionContext.Provider value={{ session }}>
			{children}
		</SessionContext.Provider>
	)
}

export const useSession = () => {
	const context = useContext(SessionContext)
	if (!context) {
		throw new Error("useSession must be used within a SessionProvider")
	}
	return context
}
