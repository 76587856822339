import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { supabase } from "../../lib/supabaseClient"
import Select from "react-select"
import { useQuery } from "@tanstack/react-query"
import Fieldset from "../../components/Fieldset"

export default function NewLocationPage() {
	const navigate = useNavigate()

	const [name, setName] = useState("")
	const [nameError, setNameError] = useState(false)

	const [selectedType, setSelectedType] = useState(null)
	const [typeError, setTypeError] = useState(false)

	const [address, setAddress] = useState("")
	const [addressError, setAddressError] = useState(false)

	const [city, setCity] = useState("Nice")
	const [cityError, setCityError] = useState(false)

	const [postCode, setPostCode] = useState("06000")
	const [postCodeError, setPostCodeError] = useState(false)

	const [website, setWebsite] = useState("")

	const [instagram, setInstagram] = useState("")

	const [wifi, setWifi] = useState("")

	async function handleOnSubmit(e) {
		e.preventDefault()

		if (!name.length > 0) {
			setNameError("Name is required")
			return
		}
		if (!selectedType?.value) {
			setTypeError("Type is required")
			return
		}
		if (!address.length > 0) {
			setAddressError("Address is required")
			return
		}
		if (!city.length > 0) {
			setCityError("City is required")
			return
		}

		if (!isValidPostcode(postCode)) {
			setPostCodeError("Postcode isn't valid")
			return
		}

		let formattedAddress = `${toTitleCase(address)}, ${formatPostcode(
			postCode
		)} ${toTitleCase(city)}`

		const coordinates = await fetch(
			`https://api.mapbox.com/geocoding/v5/mapbox.places/$${formattedAddress}.json?access_token=pk.eyJ1IjoidG9tdW5nZXJlciIsImEiOiJjbGM2dmE5NGcybTZuM3FwOHc4YWY0a3drIn0.K1kFbu6oiqXAFhmaxdxsVA`
		)
			.then((response) => {
				return response.json()
			})
			.then((data) => {
				return data.features[0].center
			})

		let newLocation = {
			name: toTitleCase(name),
			type: selectedType.value,
			address: formattedAddress,
			website: website,
			instagram: instagram,
			wifi: wifi,
			longitude: coordinates[0],
			latitude: coordinates[1],
		}

		await updateDatabase(newLocation)

		navigate(-1)
	}

	const { data: types } = useQuery({
		queryKey: ["location_types"],
		queryFn: async () => {
			const { data } = await supabase
				.from("location_types")
				.select("name, id")
				.order("name", { ascending: true })
			return data
		},
		refetchOnWindowFocus: false,
	})

	const handleTypeChange = (selectedOption) => {
		setTypeError(false)
		setSelectedType(selectedOption)
	}

	async function updateDatabase(newLocation) {
		const { error } = await supabase.from("locations").insert(newLocation)
		if (error) {
			console.error(error)
		}
	}

	function toTitleCase(str) {
		if (typeof str === "undefined") return
		return str.toLowerCase().replace(/(?:^|\s|\/|-)\w/g, function (match) {
			return match.toUpperCase()
		})
	}

	function isValidPostcode(code) {
		const postalCodePattern = /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/
		return postalCodePattern.test(code.toUpperCase())
	}

	function formatPostcode(p) {
		if (isValidPostcode(p)) {
			var postcodeRegEx = /(^[A-Z]{1,2}[0-9]{1,2})([0-9][A-Z]{2}$)/i
			return p.replace(postcodeRegEx, "$1 $2")
		} else {
			return p
		}
	}

	function handleReturn() {
		navigate(-1)
	}

	return (
		<div className="page-dashboard">
			<div className="page-container">
				<main>
					<div>
						<form onSubmit={handleOnSubmit}>
							<p>
								<FontAwesomeIcon
									icon={`arrow-left`}
									style={{ cursor: "pointer" }}
									onClick={() => handleReturn()}
								/>
							</p>
							<h3>New location</h3>
							<Fieldset
								id="name"
								label="Location name"
								field={name}
								setField={setName}
								error={nameError}
								setError={setNameError}
								autocomplete="off"
								required
							/>

							<fieldset className="fieldset">
								<label className="label">
									Select type:
									<Select
										className="react-select-container"
										classNamePrefix="react-select"
										value={selectedType}
										onChange={handleTypeChange}
										theme={(theme) => ({
											...theme,
											borderRadius: 0,
											colors: {
												...theme.colors,
												primary25: "rgba(34, 34, 34, 0.25)",
												primary50: "rgba(34, 34, 34, 0.5)",
												primary: "#222222",
											},
										})}
										options={types?.map((type) => ({
											label: type.name,
											value: type.id,
										}))}
										required
									/>
								</label>
								{typeError && (
									<p className="error">Please select a drink type</p>
								)}
							</fieldset>

							<Fieldset
								id="address"
								label="Address"
								field={address}
								setField={setAddress}
								error={addressError}
								setError={setAddressError}
								autocomplete="off"
								required
							/>

							<fieldset>
								<div className="row">
									<div>
										<Fieldset
											id="city"
											label="City"
											field={city}
											setField={setCity}
											error={cityError}
											setError={setCityError}
											autocomplete="off"
											required
										/>
									</div>
									<div>
										<Fieldset
											id="postcode"
											label="Post code"
											field={postCode}
											setField={setPostCode}
											error={postCodeError}
											setError={setPostCodeError}
											checkFunction={isValidPostcode}
											autocomplete="off"
											required
										/>
									</div>
								</div>
							</fieldset>

							<Fieldset
								id="wifi"
								label="Wifi password"
								field={wifi}
								setField={setWifi}
								autocomplete="off"
								spellcheck="false"
								autocapitalize="off"
							/>

							<Fieldset
								id="website"
								label="Website"
								placeholder="https://example.com"
								field={website}
								setField={setWebsite}
								autocomplete="off"
								spellcheck="false"
								autocapitalize="off"
							/>

							<Fieldset
								id="instagram"
								label="instagram_handle"
								placeholder="insta_handle"
								field={instagram}
								setField={setInstagram}
								autocomplete="off"
								preFix="@"
								spellcheck="false"
								autocapitalize="off"
							/>

							<fieldset>
								<button className="button">Submit</button>
							</fieldset>
						</form>
					</div>
				</main>
			</div>
		</div>
	)
}
