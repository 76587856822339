import React, { Fragment, useEffect, useState } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { RECENT_SEARCHES_STORAGE_KEY } from "../../constants/paths"
import * as ROUTES from "../../constants/routes"
import { supabase } from "../../lib/supabaseClient"
import executeQuery from "../../helpers/executeQuery"
import ResultUser from "../../components/explore/ResultUser"
import Spinner from "../../components/Spinner"
import { useInfiniteQuery } from "@tanstack/react-query"

export default function ResultsUsersPage() {
	const navigate = useNavigate()
	const { query, storedSearches } = useOutletContext()

	const [recentUsers, setRecentUsers] = useState([]) // Initialize to empty array
	const [recentUserUsernames, setRecentUserUsernames] = useState([]) // Initialize to empty array
	const [searchResults, setSearchResults] = useState([])
	const [isLoading, setIsLoading] = useState(false) // Add loading state
	useEffect(() => {
		const fetchRecentUsers = async () => {
			try {
				const storedKey = `${RECENT_SEARCHES_STORAGE_KEY}`
				const storedData = JSON.parse(localStorage.getItem(storedKey))
				setRecentUsers(storedData?.recent_profiles || []) // Handle missing data
			} catch (error) {
				console.error("Error fetching recent users:", error)
			}
		}

		fetchRecentUsers()
	}, [])

	useEffect(() => {
		let ids =
			recentUsers.length > 0 ? recentUsers.map((item) => item.username) : []
		setRecentUserUsernames(ids)
	}, [recentUsers])

	useEffect(() => {
		const handleSearch = async () => {
			setIsLoading(true) // Set loading state

			try {
				const { data } = await executeQuery(
					supabase
						.from("profiles")
						.select("username, user_id, f_name, l_name, avatar")
						.or(
							`username.ilike.%${query}%,f_name.ilike.%${query}%,l_name.ilike.%${query}%`
						)
						.limit(5)
				)
				setSearchResults(data)
			} catch (error) {
				console.error("Error searching users:", error)
			} finally {
				setIsLoading(false) // Clear loading state
			}
		}

		if (query.trim() === "") {
			setSearchResults([])
			return
		}

		handleSearch()
	}, [query])

	async function updateRecentUsers(arr) {
		if (arr) {
			try {
				const updatedData = {
					...storedSearches,
					recent_profiles: arr.slice(0, 5), // Limit to 5 recent users
				}
				const storedKey = `${RECENT_SEARCHES_STORAGE_KEY}`
				localStorage.setItem(storedKey, JSON.stringify(updatedData))
			} catch (error) {
				console.error("Error storing recent users:", error)
			}
		}
	}

	async function navigateToUser(path) {
		navigate(path)
	}

	async function handleOnClick(user) {
		try {
			const filteredRecentUsers = recentUsers.filter(
				(item) => item.user_id !== user.user_id
			)
			const updatedRecentUsers = [user, ...filteredRecentUsers].slice(0, 5) // Update and limit

			await updateRecentUsers(updatedRecentUsers)
			await navigateToUser(`${ROUTES.PROFILE}/${user.username}`)
		} catch (error) {
			console.error("Error handling click:", error)
		}
	}

	const size = 5

	const {
		isLoading: isLoadingSuggestedProfiles,
		data: suggestedProfiles,
		fetchNextPage: fetchNextPageSuggestedProfiles,
		hasNextPage: hasNextPageSuggestedProfiles,
		isFetchingNextPage: isFetchingNextPageSuggestedProfiles,
	} = useInfiniteQuery({
		queryKey: ["suggested_profiles", recentUserUsernames],
		queryFn: async ({ pageParam }) => {
			const { data, error } = await supabase
				.from("profiles")
				.select("username, user_id, f_name, l_name, avatar")
				.not("username", "in", `(${recentUserUsernames})`)
				.range(pageParam * size, (pageParam + 1) * size - 1)
				.order("user_id", { ascending: false })
			if (error) {
				console.log(error)
			} else {
				return data
			}
		},
		initialPageParam: 0,
		getNextPageParam: (lastPage, allPages, lastPageParam) => {
			if (lastPage.length === 0) {
				return undefined
			}
			return lastPageParam + 1
		},
		enabled: !!recentUserUsernames,
	})

	return (
		<>
			<section className="results-list" id="results">
				{(searchResults.length > 0 || query.length > 0) && (
					<>
						<h3>Results</h3>
						{searchResults.length > 0 ? (
							isLoading ? (
								<p>
									<Spinner inline dark />
								</p>
							) : (
								<ul className="search-results">
									{searchResults.map((user) => (
										<ResultUser
											key={user.id}
											user={user}
											handleOnClick={handleOnClick}
										/>
									))}
								</ul>
							)
						) : (
							<p className="no-results">No results</p>
						)}
					</>
				)}
			</section>

			{recentUsers.length > 0 && (
				<section className="results-list" id="recent-results">
					<h3>Recent user searches</h3>
					<ul className="search-results inline">
						{recentUsers.map((user) => (
							<ResultUser
								key={user.user_id}
								user={user}
								handleOnClick={handleOnClick}
								// inline
							/>
						))}
					</ul>
				</section>
			)}
			{suggestedProfiles?.pages[0]?.length > 0 && (
				<section className="results-list" id="suggested-results">
					<h3>Suggested profiles</h3>
					{!isLoadingSuggestedProfiles ? (
						<>
							<ul className="search-results">
								{suggestedProfiles.pages.map((group, i) => (
									<Fragment key={i}>
										{group.map((user) => (
											<ResultUser
												key={user.user_id}
												user={user}
												handleOnClick={handleOnClick}
											/>
										))}
									</Fragment>
								))}
							</ul>
							{hasNextPageSuggestedProfiles && (
								<div className="load-more-container">
									<button
										className="load-more-button"
										onClick={() => fetchNextPageSuggestedProfiles()}
										disabled={isFetchingNextPageSuggestedProfiles}>
										{isFetchingNextPageSuggestedProfiles ? (
											<Spinner inline dark />
										) : (
											"Load more"
										)}
									</button>
								</div>
							)}
						</>
					) : (
						<Spinner inline dark />
					)}
				</section>
			)}
		</>
	)
}
