import React, { useEffect } from "react"
import { APP_NAME } from "../.."
import AvatarIcon from "../../components/AvatarIcon"
import ProfileNav from "../../components/profile/ProfileNav"
import { Link, Outlet, useOutletContext } from "react-router-dom"
import { differenceInHours, formatDistance } from "date-fns"
import * as ROUTES from "../../constants/routes"
import FollowersStats from "../../components/profile/FollowersStats"

export default function MeLayout() {
	const data = useOutletContext()
	let user = data?.user || null
	let loading = data?.loading
	let fullname = user
		? `${user?.f_name || "User not found"} ${user?.l_name || ""}`
		: "..."

	let current_visit = user?.current_visit_id
	let is_current = false
	if (current_visit?.timestamp) {
		is_current =
			differenceInHours(new Date(), new Date(current_visit.timestamp)) < 2
	}

	useEffect(() => {
		document.title = `@${user?.username} • ${APP_NAME}`
	}, [user])

	return (
		<>
			<div className="profile-page me">
				<ProfileNav user={user} />
				<div className="nav-spacer" />
				<div className="page-container">
					<header>
						<AvatarIcon url={user?.avatar} size={100} loading={loading} />
						<div className="name">{fullname}</div>

						<FollowersStats
							created_at={user?.created_at}
							loading={loading}
							followers={user?.followers}
							friends={user?.friends}
						/>
						{current_visit && (
							<div
								className={`last-logged-in${is_current ? " is-current" : ""}`}>
								{is_current ? "Logged in" : "Last seen"} :{" "}
								{current_visit.location?.id === 25 ? (
									<b>{current_visit.location?.name}</b>
								) : (
									<Link to={`${ROUTES.LOCATION}/${current_visit.location?.id}`}>
										{current_visit.location?.name}
									</Link>
								)}{" "}
								<span className="time">
									(
									{formatDistance(
										new Date(current_visit.timestamp),
										new Date(),
										{
											addSuffix: true,
										}
									)}
									)
								</span>
							</div>
						)}
					</header>
					<main>
						<Outlet context={user} />
					</main>
				</div>
			</div>
		</>
	)
}
