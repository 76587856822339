import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	addDays,
	addWeeks,
	format,
	getTime,
	isFuture,
	isPast,
	isThisWeek,
	isThisYear,
	isToday,
	subDays,
	subWeeks,
} from "date-fns"
import { useNavigate } from "react-router"

export function Day({ date, selected, handleOnClick }) {
	let time = isToday(date) ? date : getTime(date)
	return (
		<div
			className={`day${selected ? " selected" : ""}${
				isPast(date) ? " is-past" : ""
			}${isToday(date) ? " is-today" : ""}${
				isFuture(date) ? " is-future" : ""
			}`}
			onClick={() => handleOnClick(time)}>
			<div className="top">{format(date, "EEE")}</div>
			<div className="bottom">{format(date, "d")}</div>
		</div>
	)
}

export default function WeekNav({ today, currentDate, setCurrentDate }) {
	const navigate = useNavigate()

	async function handleOnClick(date) {
		if (isFuture(date)) return
		handleUrl(date)
		setCurrentDate(date)
	}

	async function handleUrl(date) {
		navigate(format(date, "yyyy/MM/dd"), { replace: true })
	}

	function handlePrevDay() {
		let day = currentDate
		let prevDay = subDays(day, 1)
		handleUrl(prevDay)
		setCurrentDate(prevDay)
	}

	function handleNextDay() {
		if (isToday(currentDate)) return
		let day = currentDate
		let nextDay = addDays(day, 1)
		handleUrl(nextDay)
		setCurrentDate(nextDay)
	}

	function handleResetButton() {
		if (isToday(currentDate)) return
		handleUrl(today)
		setCurrentDate(today)
	}

	function handlePrevWeek() {
		let week = currentDate
		let prevWeek = subWeeks(week, 1)
		handleUrl(prevWeek)
		setCurrentDate(prevWeek)
	}

	function handleNextWeek() {
		if (isToday(currentDate)) return
		let day = currentDate
		let nextWeek = addWeeks(day, 1)
		if (isFuture(nextWeek)) {
			handleUrl(today)
			setCurrentDate(today)
		} else {
			handleUrl(nextWeek)
			setCurrentDate(nextWeek)
		}
	}

	return (
		<nav className="week-nav">
			<div className="month">
				<div className="double-prev" onClick={() => handlePrevWeek()}>
					<FontAwesomeIcon icon="angles-left" />
				</div>
				<div className="prev" onClick={() => handlePrevDay()}>
					<FontAwesomeIcon icon="angle-left" />
				</div>
				<h2>
					<span onClick={handleResetButton}>
						{isThisYear(currentDate)
							? format(currentDate, "MMMM")
							: format(currentDate, "MMM Y")}
					</span>
				</h2>
				<div
					className={`double-next${isThisWeek(currentDate) ? " disabled" : ""}`}
					onClick={() => handleNextWeek()}>
					<FontAwesomeIcon icon="angles-right" />
				</div>
				<div
					className={`next${isToday(currentDate) ? " disabled" : ""}`}
					onClick={() => handleNextDay()}>
					<FontAwesomeIcon icon="angle-right" />
				</div>
			</div>
			<div className="week">
				<Day date={subDays(currentDate, 3)} handleOnClick={handleOnClick} />
				<Day date={subDays(currentDate, 2)} handleOnClick={handleOnClick} />
				<Day date={subDays(currentDate, 1)} handleOnClick={handleOnClick} />
				<Day date={currentDate} handleOnClick={handleOnClick} selected />
				<Day date={addDays(currentDate, 1)} handleOnClick={handleOnClick} />
				<Day date={addDays(currentDate, 2)} handleOnClick={handleOnClick} />
				<Day date={addDays(currentDate, 3)} handleOnClick={handleOnClick} />
			</div>
		</nav>
	)
}
