import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function SearchBar({ focusRef, query, setQuery }) {
	return (
		<div className="search-bar">
			<button>
				<FontAwesomeIcon icon="search" />
			</button>
			<input
				autoComplete="off"
				ref={focusRef}
				className="input"
				placeholder="Search"
				aria-label="search"
				type="text"
				name="search-form"
				value={query || ""}
				onChange={(e) => setQuery(e.target.value)}
			/>
			<button onClick={() => setQuery("")}>
				{query.length > 0 && <FontAwesomeIcon icon="times" />}
			</button>
		</div>
	)
}
