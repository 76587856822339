import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import DrinkEdit from "./DrinkEdit";
import { useState } from "react";
import { supabase } from "../../lib/supabaseClient";
import executeQuery from "../../helpers/executeQuery";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { format } from "date-fns";

export default function VisitEdit({ visit, setModal, userId }) {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(
    visit?.location && {
      label: visit.location?.name,
      value: visit.location?.id,
    },
  );
  const [drinks, setDrinks] = useState(visit?.drinks || []);

  const [time, setTime] = useState(format(new Date(visit?.timestamp), "HH:mm"));
  const [dateTime, setDateTime] = useState(
    format(new Date(visit?.timestamp), "yyyy-MM-dd"),
  );

  const [deletedVisit, setDeletedVisit] = useState(false);
  const [deletedDrinks, setDeletedDrinks] = useState([]);

  const [activeModal, setActiveModal] = useState(null);

  const { isLoading: isLoadingLocations, data: allLocations } = useQuery({
    queryKey: ["locations"],
    queryFn: async () => {
      const { data } = await supabase
        .from("locations")
        .select("id, name")
        .order("name", { ascending: true });
      return data;
    },
  });

  function removeDrink(id) {
    setDeletedDrinks([...deletedDrinks, id]);
    return drinks.filter((a) => a?.id !== id);
  }

  //update session
  const updateVisits = useMutation({
    mutationFn: async ({ visitUpdates, drinkUpdates }) => {
      await executeQuery(
        supabase.from("user_visits").update(visitUpdates).eq("id", visit.id),
      );
      await executeQuery(
        supabase.from("user_visit_drinks").upsert(drinkUpdates),
      );
      await executeQuery(
        supabase.from("user_visit_drinks").delete().in("id", deletedDrinks),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        ["current_visit", userId],
        ["history_visits", userId],
      );
    },
    enabled: !!userId,
  });

  //update session
  const deleteVisits = useMutation({
    mutationFn: async (id) => {
      await executeQuery(supabase.from("user_visits").delete().eq("id", id));
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        ["current_visit", userId],
        ["history_visits", userId],
      );
    },
    enabled: !!userId,
  });

  function updateVisit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      let visitUpdates = {
        timestamp: `${dateTime}T${time}:00+01:00`,
        location_id: location.value,
      };
      let drinkUpdates = drinks.map((d) => ({
        id: d.id,
        timestamp: d.timestamp,
        drink_id: d.drink_id,
        ml: d.ml,
        user_visit_id: visit.id,
        alcohol_percentage: d.alcohol_percentage,
        type: d.type === 9 ? "wine" : "beer",
      }));

      updateVisits.mutate({ visitUpdates, drinkUpdates });
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
      setModal(false);
    }
  }

  function deleteVisit(e) {
    e.preventDefault();
    try {
      setLoading(true);

      deleteVisits.mutate(visit.id);
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
      setModal(false);
    }
  }

  function addDrink() {
    let id = uuidv4();
    setDrinks([...drinks, { id: id }]);
    setActiveModal(id);
  }

  return (
    <div className="edit-modal-wrapper">
      <div className="edit-modal-background" onClick={() => setModal(false)} />
      <div className="edit-modal">
        <div className="close" onClick={() => setModal(false)}>
          <FontAwesomeIcon icon={"times"} />
        </div>
        {!activeModal ? (
          <div className="step" id="step-1">
            <form
              className="form"
              onSubmit={deletedVisit ? deleteVisit : updateVisit}
            >
              {deletedVisit ? (
                <div>
                  <p style={{ textAlign: "center" }}>Are you sure ?</p>
                </div>
              ) : (
                <>
                  <fieldset>
                    <div className="row">
                      <div style={{ flex: 1 }}>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          defaultValue={location}
                          isLoading={isLoadingLocations}
                          onChange={setLocation}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: "rgba(34, 34, 34, 0)",
                              primary50: "rgba(34, 34, 34, 0.5)",
                              primary: "rgba(34, 34, 34, 1)",
                            },
                          })}
                          options={allLocations?.map((location) => ({
                            label: location.name,
                            value: location.id,
                          }))}
                          id="location"
                        />
                      </div>
                      <div>
                        <div className="remove-visit">
                          <div
                            className="icon"
                            onClick={() => setDeletedVisit(visit?.id)}
                          >
                            <FontAwesomeIcon icon="trash" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <fieldset>
                    <div className="row">
                      <div>
                        <input
                          id="date"
                          type="date"
                          value={dateTime}
                          onChange={(e) => {
                            setDateTime(e.target.value);
                          }}
                          max={format(new Date(), "yyyy-MM-dd")}
                          autoComplete="off"
                        />
                      </div>
                      <div>
                        <input
                          id="time"
                          type="time"
                          value={time}
                          onChange={(e) => {
                            setTime(e.target.value);
                          }}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </fieldset>

                  <fieldset>
                    <ul className="drinks">
                      {drinks.length > 0 ? (
                        drinks.map((drink) => (
                          <li className="drink" key={drink.id}>
                            {drink.timestamp && (
                              <span className="time">
                                {format(new Date(drink.timestamp), "HH:mm")}
                              </span>
                            )}
                            {"•"}

                            <span style={{ maxWidth: 100 }} className="title">
                              {drink?.name}
                            </span>

                            {"•"}
                            <span className="description">{drink.ml}ml</span>

                            {"•"}
                            <span
                              className="edit-button"
                              onClick={() => setActiveModal(drink.id)}
                            >
                              <FontAwesomeIcon icon="pen" />
                            </span>

                            {"•"}
                            <span
                              className="edit-button"
                              onClick={() => setDrinks(removeDrink(drink.id))}
                            >
                              <FontAwesomeIcon icon="trash" />
                            </span>
                          </li>
                        ))
                      ) : (
                        <p style={{ textAlign: "center" }}>
                          no drinks logged in
                        </p>
                      )}
                      <li className="add-drink" onClick={addDrink}>
                        +
                      </li>
                    </ul>
                  </fieldset>
                </>
              )}
              <p>
                {deletedVisit ? (
                  <button className="button">
                    {loading ? "updating..." : "delete visit"}
                  </button>
                ) : (
                  <button className="button">
                    {loading ? "updating..." : "update visit"}
                  </button>
                )}
              </p>
              <div style={{ textAlign: "center" }}>
                <span className="cancel" onClick={() => setModal(false)}>
                  cancel
                </span>
              </div>
            </form>
          </div>
        ) : (
          <div className="step" id="step-2">
            {drinks.length > 0 &&
              drinks
                .filter((drink) => drink.id === activeModal)
                .map((drink) => (
                  <DrinkEdit
                    key={drink.id}
                    date={visit?.timestamp}
                    drink={drink}
                    drinks={drinks}
                    setDrinks={setDrinks}
                    setActiveModal={setActiveModal}
                    removeDrink={removeDrink}
                  />
                ))}
          </div>
        )}
      </div>
    </div>
  );
}
