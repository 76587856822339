import { format, isSameWeek, parseISO } from "date-fns"
import React, { useMemo } from "react"
import { useOutletContext } from "react-router-dom"
import { useSession } from "../../context/SessionContext"
import Visit from "../../components/history/Visit"

export default function HistoryVisit() {
	const { currentDate, totalVisits } = useOutletContext()
	const { session } = useSession()
	const userId = session?.user?.id || null

	// GET TODAYS VISITS
	const todaysVisits = useMemo(() => {
		return totalVisits.filter(
			(item) =>
				format(parseISO(item.timestamp), "P") === format(currentDate, "P")
		)
	}, [totalVisits, currentDate])

	let todaysUnits = 0
	for (const event of todaysVisits) {
		for (const drink of event.drinks) {
			todaysUnits += drink.units
		}
	}
	todaysUnits = Math.round((todaysUnits + Number.EPSILON) * 10) / 10

	let todaysL = 0
	for (const event of todaysVisits) {
		for (const drink of event.drinks) {
			todaysL += drink.ml
		}
	}
	todaysL = parseFloat((todaysL / 1000).toFixed(3))

	// GET THIS WEEKS VISITS
	const thisWeeksVisits = useMemo(() => {
		return totalVisits.filter((item) =>
			isSameWeek(new Date(item.timestamp), new Date(currentDate), {
				weekStartsOn: 1,
			})
		)
	}, [totalVisits, currentDate])

	let thisWeeksUnits = 0
	for (const event of thisWeeksVisits) {
		for (const drink of event.drinks) {
			thisWeeksUnits += drink.units
		}
	}
	thisWeeksUnits = Math.round((thisWeeksUnits + Number.EPSILON) * 10) / 10

	let thisWeeksL = 0
	for (const event of thisWeeksVisits) {
		for (const drink of event.drinks) {
			thisWeeksL += drink.ml
		}
	}
	thisWeeksL = parseFloat((thisWeeksL / 1000).toFixed(3))

	const TotalsToday = ({ units, l }) => {
		return (
			<div className="totals">
				<h3>Today</h3>
				<div>
					Units: <strong>{units}</strong>
				</div>
				<div>
					Amount: <strong>{l}L</strong>
				</div>
			</div>
		)
	}
	const TotalsWeek = ({ units, l }) => {
		return (
			<div className="totals week">
				<h3>This week</h3>
				<div>
					Units: <strong>{units}</strong>
				</div>
				<div>
					Amount: <strong>{l}L</strong>
				</div>
			</div>
		)
	}

	return todaysVisits?.length > 0 ? (
		<>
			{todaysVisits.map((visit) => (
				<Visit key={visit.id} visit={visit} userId={userId} />
			))}
			<div className="row">
				<TotalsToday units={todaysUnits} l={todaysL} />
				<TotalsWeek units={thisWeeksUnits} l={thisWeeksL} />
			</div>
		</>
	) : (
		<>
			<p className="no-visits-message">no visits logged in</p>
			<TotalsWeek units={thisWeeksUnits} l={thisWeeksL} />
		</>
	)
}
