import React, { useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"
import Actions from "../../components/dashboard/Actions"
import BeersIcon from "../../components/profile/Beers"
import WinesIcon from "../../components/profile/Wines"
import * as ROUTES from "../../constants/routes"
import SiteNav from "../../components/dashboard/SiteNav"
import Spinner from "../../components/Spinner"
import ShibaCount from "../../components/ShibaCount"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import executeQuery from "../../helpers/executeQuery"
import { supabase } from "../../lib/supabaseClient"
import RandomLocation from "../../components/RandomLocation"

function SameAgainButton({ currentVisit, userId, isWine }) {
	const queryClient = useQueryClient()

	const drinks = currentVisit?.drinks || []
	let latestDrink = drinks[drinks.length - 1]
	let formattedLatestDrink
	if (latestDrink) {
		formattedLatestDrink = {
			user_visit_id: currentVisit?.id,
			drink_id: latestDrink.drink.id,
			ml: latestDrink.ml,
			alcohol_percentage: latestDrink.alcohol_percentage,
			type: latestDrink.type,
		}
	}

	const insertUserVisitDrink = useMutation({
		mutationFn: async (drink) => {
			const { data } = await executeQuery(
				supabase.from("user_visit_drinks").insert([drink]).select()
			)
			return data
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["current_visit", userId])
		},
	})

	async function handleOnSubmit(e) {
		e.preventDefault()

		if (!formattedLatestDrink) return
		try {
			insertUserVisitDrink.mutate(formattedLatestDrink)
		} catch (error) {
			console.error(error.message)
		}
	}
	if (
		(formattedLatestDrink?.type === "beer" && !isWine) ||
		(formattedLatestDrink?.type === "wine" && isWine)
	)
		return (
			<div className="action same-again" onClick={handleOnSubmit}>
				<FontAwesomeIcon icon="repeat" />
				<div className="text">
					<div className="title">Same again</div>
				</div>
			</div>
		)
}
export default function DashboardPage() {
	const { user, currentVisit, isLoading, setIsWine, isWine } =
		useOutletContext()
	let userId = user?.user_id || null

	const [attendees, setAttendees] = useState([])
	const [drinks, setDrinks] = useState([])
	const [totalMl, setTotalMl] = useState(0)

	useEffect(() => {
		if (currentVisit) {
			setAttendees(currentVisit.attendees)
			setDrinks(currentVisit.drinks)
		}
	}, [currentVisit])

	useEffect(() => {
		if (drinks?.length > 0) {
			setTotalMl(drinks.reduce((acc, item) => acc + item.ml, 0))
		}
	}, [drinks])

	return (
		<div className="page-dashboard">
			<div className="site-nav-spacer" />
			<SiteNav user={user} isWine={isWine} setIsWine={setIsWine} />
			<div className="page-container">
				{isLoading ? (
					<Spinner />
				) : (
					<main>
						<div>
							<section id="total-beers-section">
								{!isWine ? (
									<BeersIcon
										comments={true}
										title={currentVisit?.location?.name}
										ml={currentVisit ? totalMl : 0}
									/>
								) : (
									<WinesIcon
										comments={true}
										title={currentVisit?.location?.name}
										ml={currentVisit ? totalMl : 0}
									/>
								)}
							</section>
							<section id="actions-section">
								{currentVisit && (
									<div className="row">
										<div style={{ width: "100%" }}>
											<Actions
												icon={`${!isWine ? "beer" : "wine-bottle"}`}
												title={
													drinks && drinks.length > 0
														? "Had another?"
														: `Had a ${!isWine ? "beer" : "wine"}?`
												}
												description="Log it in!"
												link={isWine ? ROUTES.ADD_WINE : ROUTES.ADD_DRINK}
											/>
										</div>
										{drinks && drinks.length > 0 && (
											<SameAgainButton
												currentVisit={currentVisit}
												userId={userId}
												isWine={isWine}
											/>
										)}
									</div>
								)}
								{currentVisit && (
									<Actions
										icon={
											attendees && attendees.length > 1
												? "user-friends"
												: "user"
										}
										title={
											attendees && attendees.length > 0
												? `Currently with ${attendees.length} friend${
														attendees.length === 1 ? "" : "s"
												  }`
												: "Drinking alone?"
										}
										description={
											attendees && attendees.length > 0
												? "Has anyone else joined?"
												: "Add attendees"
										}
										link={ROUTES.ADD_ATTENDEES}
									/>
								)}
								<Actions
									icon={currentVisit ? `shop` : "hand-point-right"}
									title={currentVisit ? "Moved on?" : "Starting a session?"}
									description={
										currentVisit ? "Update your location" : "Add your location."
									}
									link={ROUTES.ADD_LOCATION}
								/>
							</section>
							<section id="random-location-section">
								<RandomLocation />
							</section>
							<section id="shiba-count-section">
								<ShibaCount userId={userId} />
							</section>
						</div>
					</main>
				)}
			</div>
		</div>
	)
}
