import { isToday } from "date-fns"
import React, { useEffect, useState } from "react"
import mlToL from "../../helpers/mlToL"

export const WineIcon = ({
	percentage,
	amount,
	type,
	currentDate,
	comments,
}) => {
	const [comment, setComment] = useState("Thirsty?")
	useEffect(() => {
		let comments = [
			"Parched through and through...",
			"A day devoid of delight",
			"Not a drop to quench the thirst",
			"Gasping for a gulp",
			"Drinkless",
		]
		setComment(
			isToday(currentDate)
				? "Thirsty?"
				: comments[Math.floor(Math.random() * comments.length)]
		)
	}, [currentDate])

	let amountW
	switch (type) {
		case "keg":
			amountW = `${amount} keg${amount !== 1 ? "s" : ""}`
			break
		case "bottle":
			amountW = `${amount} bottle${amount !== 1 ? "s" : ""}`
			break
		case "full":
			amountW = `${amount} lg glass${amount !== 1 ? "es" : ""}`
			break
		case "half":
			amountW = `${amount}ml`
			break
		default:
			amountW = ""
			break
	}

	return (
		<div
			className={`wine ${type}`}
			style={{ cursor: isToday(currentDate) && "pointer" }}>
			<div className="liquidW">
				<div
					className="liquid"
					style={{
						height: `${percentage}%`,
						width: percentage > 35 ? "100%" : "75%",
						left: percentage > 35 ? "0" : "10%",
					}}
				/>
			</div>
			<div className="container" />
			<div className="amount">
				{amountW}
				{comments && (
					<div className="comment">{type === "empty" && `${comment}`}</div>
				)}
			</div>
		</div>
	)
}

const WinesIcon = ({ ml, currentDate, comments, title }) => {
	// ml = 0
	const keg = 30000
	const bottle = 750
	const glass = 250

	let total_of_kegs = ml / keg
	let total_of_kegs_exact = Math.floor(total_of_kegs)

	let total_of_bottles = ((total_of_kegs - total_of_kegs_exact) * keg) / bottle
	let total_of_bottles_exact = Math.floor(total_of_bottles)

	let total_of_glasses =
		((total_of_bottles - total_of_bottles_exact) * bottle) / glass

	let total_of_glasses_exact = Math.floor(total_of_glasses)

	let total_of_ml = Math.round(
		(total_of_glasses - total_of_glasses_exact) * glass
	)

	const percentage = (total_of_ml / glass) * 100

	return (
		<div className="winesW">
			<div className="title">
				{
					<>
						{`Amount drank ${title ? "at" : ":"}`}
						{title ? (
							<span>{title}</span>
						) : (
							<span>
								{ml > 0
									? mlToL(ml)
									: `nothing${isToday(currentDate) ? " yet" : ""}...`}
							</span>
						)}
					</>
				}
			</div>
			<div className="wines">
				<div className="circleW">
					<div className="cicle" />
				</div>
				{/* 1 keg = 40 bottles = 30000ml */}
				{total_of_kegs_exact > 0 && (
					<>
						<WineIcon
							type="keg"
							currentDate={currentDate}
							comments={comments}
							amount={total_of_kegs_exact}
						/>
						{(total_of_ml > 0 ||
							total_of_glasses_exact > 0 ||
							total_of_bottles_exact > 0) && <div className="text">{"+"}</div>}
					</>
				)}
				{/* 1 bottle = 3 glasses = 750ml */}
				{total_of_bottles_exact > 0 && (
					<>
						<WineIcon
							type="bottle"
							currentDate={currentDate}
							comments={comments}
							amount={total_of_bottles_exact}
						/>
						{(total_of_ml > 0 || total_of_glasses_exact > 0) && (
							<div className="text">{"+"}</div>
						)}
					</>
				)}
				{/* 1 glass = 250ml */}
				{total_of_glasses_exact > 0 && (
					<>
						<WineIcon
							type="full"
							percentage={100}
							currentDate={currentDate}
							amount={total_of_glasses_exact}
						/>
						{total_of_ml > 0 && <div className="text">{"+"}</div>}
					</>
				)}
				{total_of_ml > 0 && (
					<>
						<WineIcon
							type="half"
							percentage={percentage}
							currentDate={currentDate}
							amount={total_of_ml}
						/>
					</>
				)}

				{/* 0ml */}
				{ml === 0 && (
					<WineIcon
						type="empty"
						percentage={percentage}
						currentDate={currentDate}
						comments={comments}
					/>
				)}
			</div>
		</div>
	)
}

export default WinesIcon
