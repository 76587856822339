import React from "react"
import ReactDOM from "react-dom/client"
import "./styles/index.scss"
import App from "./App"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
	faBottleDroplet,
	faUser,
	faBeer,
	faArrowLeft,
	faArrowRight,
	faUserFriends,
	faPlus,
	faSackDollar,
	faStore,
	faShop,
	faPen,
	faSearch,
	faWineBottle,
	faChartPie,
	faGlassWater,
	faJar,
	faSterlingSign,
	faUserPlus,
	faCheck,
	faEllipsisVertical,
	faMedal,
	faPercent,
	faLocationDot,
	faTrash,
	faCaretDown,
	faGlobe,
	faCopy,
	faHouse,
	faBars,
	faPaperPlane,
	faShare,
	faClockRotateLeft,
	faStar,
	faHeart,
	faWineGlass,
	faEuroSign,
	faHandPointRight,
	faAngleLeft,
	faAngleRight,
	faTimes,
	faTrophy,
	faMinus,
	faRepeat,
	faAnglesLeft,
	faAnglesRight,
	faCloud,
	faSun,
	faCloudRain,
	faCloudSun,
	faCog,
	faClock,
	faCalculator,
	faDice,
	faBell,
	faSquare,
	faSquareCheck,
	faCalendar,
	faMessage,
	faCircleUser,
} from "@fortawesome/free-solid-svg-icons"

import {
	faGoogle,
	faInstagram,
	faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { SessionProvider } from "./context/SessionContext"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

library.add(
	faSquare,
	faCircleUser,
	faMessage,
	faSquareCheck,
	faDice,
	faBell,
	faTimes,
	faCloud,
	faCog,
	faClock,
	faSun,
	faCalendar,
	faCloudRain,
	faCloudSun,
	faRepeat,
	faTrophy,
	faBottleDroplet,
	faTimes,
	faAnglesLeft,
	faAnglesRight,
	faWineGlass,
	faEuroSign,
	faHandPointRight,
	faAngleLeft,
	faAngleRight,
	faArrowLeft,
	faArrowRight,
	faBars,
	faBeer,
	faCaretDown,
	faChartPie,
	faCheck,
	faClockRotateLeft,
	faCopy,
	faEllipsisVertical,
	faGlassWater,
	faGlobe,
	faGoogle,
	faHeart,
	faHouse,
	faInstagram,
	faJar,
	faLocationDot,
	faMedal,
	faMinus,
	faPaperPlane,
	faPen,
	faPercent,
	faPlus,
	faSackDollar,
	faSearch,
	faShare,
	faShop,
	faSterlingSign,
	faStar,
	faStore,
	faTrash,
	faTwitter,
	faUser,
	faUserFriends,
	faUserPlus,
	faWineBottle,
	faCalculator
)

export const APP_NAME = "Pints of Nice"

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			// refetchOnWindowFocus: false, // default: true
			staleTime: 60 * 1000,
		},
	},
})

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<SessionProvider>
				{/* <AchievementsProvider> */}
				<App />
				<ReactQueryDevtools />
				{/* </AchievementsProvider> */}
			</SessionProvider>
		</QueryClientProvider>
	</React.StrictMode>
)
