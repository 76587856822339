import React, { useEffect, useState } from "react"
import { Link, useOutletContext, useParams } from "react-router-dom"
import LocationNav from "../../components/profile/LocationNav"
import MapStatic from "../../components/MapStatic"
import Socials from "../../components/Socials"
import LikeButton from "../../components/LikeButton"
import copy from "copy-to-clipboard"
import { APP_NAME } from "../.."
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { supabase } from "../../lib/supabaseClient"
import * as ROUTES from "../../constants/routes"
import { getDistance, orderByDistance } from "geolib"
import Spinner from "../../components/Spinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatDistance } from "date-fns"
import AvatarIcon from "../../components/AvatarIcon"

export default function LocationPage() {
	const queryClient = useQueryClient()
	const { id } = useParams()
	let locationId = Number(id) || null
	const { user } = useOutletContext()
	let userId = user?.user_id || null

	const [isLiked, setIsLiked] = useState(false)
	const [likeButtonLoading, setLikeButtonLoading] = useState(true)

	const { isLoading, data: location } = useQuery({
		queryKey: ["location", locationId],
		queryFn: async () => {
			const { error, data } = await supabase
				.from("locations")
				.select(
					`id,
          name,
          address,
          wifi,
          website,
          instagram,
          updated_at,
          favourite_count,
          type: location_types (
            id,
            name
          ),
          longitude,
          latitude`
				)
				.eq("id", locationId)
				.single()

			if (error) {
				console.error(error)
			} else {
				setLikeButtonLoading(false)
				return data
			}
		},
		enabled: !!userId,
	})

	const { data: favourite_count } = useQuery({
		queryKey: ["favourite-count", `location-${locationId}`],
		queryFn: async () => {
			const { data } = await supabase
				.from("favourite_locations")
				.select("id")
				.eq("location_id", locationId)

			return data.length
		},
		enabled: !!locationId,
	})

	useEffect(() => {
		let locs = user?.favourite_locations || []
		let filteredLoc = false
		if (locs.length > 0) {
			filteredLoc =
				locs.find((item) => item.location_id === locationId) || false
		}
		if (filteredLoc) {
			filteredLoc = filteredLoc.id
		}
		setIsLiked(filteredLoc)
	}, [user, locationId])

	const addFavouriteLocation = useMutation({
		mutationFn: async () => {
			const { data, error } = await supabase
				.from("favourite_locations")
				.insert([{ user_id: userId, location_id: location.id }])
				.select()
			if (error) {
				console.error(error)
			} else {
				return data
			}
		},
		onMutate: () => {
			setLikeButtonLoading(true)
		},
		onSuccess: () => {
			queryClient.invalidateQueries(
				["favourite-count", `location-${locationId}`],
				["favourite_locations", userId]
			)
			setLikeButtonLoading(false)
		},
	})

	const removeFavouriteLocation = useMutation({
		mutationFn: async () => {
			const { error } = await supabase
				.from("favourite_locations")
				.delete()
				.eq("id", isLiked)
			if (error) {
				console.error(error)
			}
		},
		onMutate: () => {
			setLikeButtonLoading(true)
		},
		onSuccess: () => {
			queryClient.invalidateQueries(
				["favourite-count", `location-${locationId}`],
				["favourite_locations", userId]
			)
			setLikeButtonLoading(false)
			setIsLiked(false)
		},
	})

	function updateFavouriteLocation() {
		if (!likeButtonLoading) {
			if (!isLiked) {
				addFavouriteLocation.mutate()
			} else {
				removeFavouriteLocation.mutate()
			}
		}
	}

	const { data: geoLocations } = useQuery({
		queryKey: ["geolocations"],
		queryFn: async () => {
			const { data, error } = await supabase
				.from("locations")
				.select("id, name, longitude, latitude")
				.neq("latitude", null)
				.neq("type", 14)
			if (error) console.error(error)
			return data
		},
	})

	const [nearbyLocations, setNearbyLocations] = useState([])

	useEffect(() => {
		if (!location?.latitude || !location?.longitude) return
		const orderedLocations = orderByDistance(
			{
				latitude: location?.latitude || 0,
				longitude: location?.longitude || 0,
			},
			geoLocations || []
		)
		let filteredLocations = orderedLocations
			.filter((l) => l.id !== locationId)
			.slice(0, 5)
		setNearbyLocations(filteredLocations)
	}, [geoLocations, location, locationId])

	const { data: drinks } = useQuery({
		queryKey: ["location_drinks", `location-${locationId}`],
		queryFn: async () => {
			const { data } = await supabase
				.from(`location_drinks`)
				.select(`drink:drink_id(id, name)`)
				.eq("location_id", locationId)
			const uniqueDrinks = [
				...new Set(data.map((obj) => JSON.stringify(obj))),
			].map((str) => JSON.parse(str))
			let formattedDrinks = uniqueDrinks.map((d) => d.drink)
			let filteredDrinks = formattedDrinks.filter((d) => d !== null)
			return filteredDrinks
		},
		enabled: !!locationId,
	})

	const { data: recentActivities } = useQuery({
		queryKey: ["user_visit_drinks", `location-${locationId}`],
		queryFn: async () => {
			const { error, data } = await supabase
				.from("user_visit_drinks")
				.select(
					"id, ml, private, timestamp, user_visits!inner(user_id(username, avatar)), drinks(name, id)"
				)
				.or(`location_id.eq.${locationId}`, {
					referencedTable: "user_visits",
				})
				.order("timestamp", { ascending: false })
				.limit(5)
			if (error) {
				console.error(error)
			} else {
				setLikeButtonLoading(false)
				return data
			}
		},
		enabled: !!locationId,
	})

	const copyToClipboard = (e) => {
		copy(e.target.innerText)
		alert(`Copied "${e.target.innerText}"`)
	}

	function convertMetersToKilometers(meters) {
		if (meters > 1000) {
			const kilometers = (meters / 1000).toFixed(2)
			return `${kilometers}km away`
		} else {
			return `${meters}m away`
		}
	}

	useEffect(() => {
		if (location) {
			document.title = `${location.name} • ${APP_NAME}`
		}
	}, [location])

	return (
		<div className="profile-location main-content">
			{isLoading ? (
				<>
					<Spinner />
				</>
			) : (
				<>
					<LocationNav
						count={favourite_count}
						setCount={updateFavouriteLocation}
						isLiked={isLiked}
					/>
					<MapStatic
						lng={location?.longitude}
						lat={location?.latitude}
						zoom={14}
					/>
					<div className="content">
						<div className="container">
							<h1 className="title">{location?.name || "_"}</h1>
							{location?.type?.name && (
								<div className="type">
									<div className="typeW">{location?.type?.name}</div>
								</div>
							)}
							<div className="address">{location?.address}</div>
							{location?.wifi && (
								<div className="wifi">
									<p>
										<b>WiFi password:</b>{" "}
										<span onClick={copyToClipboard}>{location?.wifi}</span>
										<br />
										<small>(click password to copy)</small>
									</p>
								</div>
							)}
							<Socials
								instagram={location?.instagram}
								twitter={location?.twitter}
								website={location?.website}
							/>
							<div className="location-stats">
								<div className="like-stat" onClick={updateFavouriteLocation}>
									<LikeButton count={favourite_count} isLiked={isLiked} />
								</div>
								<div className="number-stat">
									Visited 1 times / Drank x drinks
								</div>
							</div>
							<div className="location-modals">
								<div className="available-drinks">
									<h3 className="results-title">
										<FontAwesomeIcon icon="beer" /> Available drinks
									</h3>
									<ul className="results">
										{drinks?.length > 0 ? (
											drinks.map((d, i) => (
												<li key={d.id}>
													<Link to={`${ROUTES.DRINK}/${d.id}`}>{d.name}</Link>
												</li>
											))
										) : (
											<div
												style={{
													display: "flex",
													height: "100%",
													alignItems: "center",
													justifyContent: "center",
												}}>
												None yet...
											</div>
										)}
									</ul>
								</div>
								<div className="available-drinks">
									<h3 className="results-title">
										<FontAwesomeIcon icon="location-dot" /> Nearby Locations
									</h3>
									<ul className="results">
										{nearbyLocations.length > 0 ? (
											nearbyLocations.map((l, i) => (
												<li key={l.id}>
													<Link to={`${ROUTES.LOCATION}/${l.id}`}>
														{i + 1}. {l.name}{" "}
														<small>
															(
															{convertMetersToKilometers(
																getDistance(
																	{
																		latitude: location?.latitude,
																		longitude: location?.longitude,
																	},
																	{
																		latitude: l.latitude,
																		longitude: l.longitude,
																	}
																)
															)}
															)
														</small>
													</Link>
												</li>
											))
										) : (
											<div
												style={{
													display: "flex",
													height: "100%",
													alignItems: "center",
													justifyContent: "center",
												}}>
												None yet...
											</div>
										)}
									</ul>
								</div>
							</div>
						</div>

						<div className="recent-activities">
							<h3>
								<FontAwesomeIcon icon="beer" /> Recent Activities
							</h3>
							{recentActivities?.length > 0 ? (
								<div className="activities">
									{recentActivities.map((activity) => (
										<div className="activity" key={activity.id}>
											<Link
												to={`${ROUTES.PROFILE}/${activity.user_visits?.user_id?.username}`}>
												<AvatarIcon
													inline
													size={40}
													url={activity.user_visits?.user_id?.avatar || 1}
												/>
											</Link>
											<div className="body">
												<span className="username">
													{activity.user_visits?.user_id?.username}
												</span>{" "}
												drank a{" "}
												<span className="quantity">
													<Link to={`${ROUTES.DRINK}/${activity.drinks?.id}`}>
														{activity.drinks?.name}
													</Link>
												</span>{" "}
												here
												<div className="info">
													<span className="date">
														{formatDistance(
															new Date(activity.timestamp),
															new Date(),
															{
																addSuffix: true,
															}
														)}
													</span>
												</div>
											</div>
											{/* <FontAwesomeIcon icon="ellipsis-vertical" /> */}
										</div>
									))}
								</div>
							) : (
								<div className="activity">No recent visits...</div>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	)
}
