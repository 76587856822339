import React, { useEffect, useState } from "react"
import { Link, useOutletContext, useParams } from "react-router-dom"
import { APP_NAME } from "../.."
import DrinkNav from "../../components/profile/DrinkNav"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { supabase } from "../../lib/supabaseClient"
import * as ROUTES from "../../constants/routes"
import LikeButton from "../../components/LikeButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AvatarIcon from "../../components/AvatarIcon"
import { formatDistance } from "date-fns"

export default function DrinkPage() {
	const queryClient = useQueryClient()
	const { id } = useParams()
	let drinkId = Number(id) || null
	const { user } = useOutletContext()
	let userId = user?.user_id || null

	const [isLiked, setIsLiked] = useState(false)
	const [likeButtonLoading, setLikeButtonLoading] = useState(true)

	const { data: drink } = useQuery({
		queryKey: ["drink", drinkId],
		queryFn: async () => {
			const { error, data } = await supabase
				.from("drinks")
				.select(
					`name, alcohol_percentage, id, updated_at, favourite_count, 
								type,
								drink_subtypes (
									id,
									name,
									drink_type ( id, name)
								),
								
								country,
								countries (
									name_en
								)`
				)
				.eq("id", drinkId)
				.single()
			if (error) {
				console.error(error)
			} else {
				setLikeButtonLoading(false)
				return data
			}
		},
		enabled: !!userId,
	})

	const { data: recentActivities } = useQuery({
		queryKey: ["user_visit_drinks", `drink-${drink}`],
		queryFn: async () => {
			const { error, data } = await supabase
				.from("user_visit_drinks")
				.select(
					"id, ml, private, timestamp, user_visits(locations(id, name), profiles:user_id(username, avatar))"
				)
				.eq("drink_id", drinkId)
				.eq("private", false)
				.order("timestamp", { ascending: false })
				.limit(5)
			if (error) {
				console.error(error)
			} else {
				setLikeButtonLoading(false)
				return data
			}
		},
		enabled: !!drinkId,
	})

	const { data: favourite_count } = useQuery({
		queryKey: ["favourite-count", `drink-${drinkId}`],
		queryFn: async () => {
			const { data } = await supabase
				.from("favourite_drinks")
				.select("id")
				.eq("drink_id", drinkId)

			return data.length
		},
		enabled: !!drinkId,
	})

	useEffect(() => {
		let bevs = user?.favourite_drinks || []
		let filteredBev = false
		if (bevs.length > 0) {
			filteredBev = bevs.find((item) => item.drink_id === drinkId) || false
		}
		if (filteredBev) {
			filteredBev = filteredBev.id
		}
		setIsLiked(filteredBev)
	}, [user, drinkId])

	const addFavouriteDrink = useMutation({
		mutationFn: async () => {
			const { data, error } = await supabase
				.from("favourite_drinks")
				.insert([{ user_id: userId, drink_id: drink.id }])
				.select()
			if (error) {
				console.error(error)
			} else {
				return data
			}
		},
		onMutate: () => {
			setLikeButtonLoading(true)
		},
		onSuccess: () => {
			queryClient.invalidateQueries(
				["favourite-count", `drink-${drinkId}`],
				["favourite_drinks", userId]
			)
			setLikeButtonLoading(false)
		},
	})

	const removeFavouriteDrink = useMutation({
		mutationFn: async () => {
			const { error } = await supabase
				.from("favourite_drinks")
				.delete()
				.eq("id", isLiked)
			if (error) {
				console.error(error)
			}
		},
		onMutate: () => {
			setLikeButtonLoading(true)
		},
		onSuccess: () => {
			queryClient.invalidateQueries(
				["favourite-count", `drink-${drinkId}`],
				["favourite_drinks", userId]
			)
			setLikeButtonLoading(false)
			setIsLiked(false)
		},
	})

	function updateFavouriteDrink() {
		if (!likeButtonLoading) {
			if (!isLiked) {
				addFavouriteDrink.mutate()
			} else {
				removeFavouriteDrink.mutate()
			}
		}
	}

	const { data: locations } = useQuery({
		queryKey: ["location_drinks", `drink-${drinkId}`],
		queryFn: async () => {
			const { data } = await supabase
				.from(`location_drinks`)
				.select(`location:location_id(id, name)`)
				.eq("drink_id", drinkId)
			const uniqueLocations = [
				...new Set(data.map((obj) => JSON.stringify(obj))),
			].map((str) => JSON.parse(str))
			const formattedLocations = uniqueLocations.map((l) => l.location)
			const filteredLocations = formattedLocations
				.filter((d) => d !== null)
				.filter((l) => l.id !== 25)
			return filteredLocations
		},
		enabled: !!drinkId,
	})

	useEffect(() => {
		if (drink) {
			document.title = `${drink.name} • ${APP_NAME}`
		}
	}, [drink])

	return (
		<div className="profile-drink">
			<div
				className={`hero ${
					drink?.drink_subtypes?.drink_type?.id === 5 ? "wine" : "beer"
				}`}>
				<DrinkNav
					count={favourite_count}
					setCount={updateFavouriteDrink}
					isLiked={isLiked}
				/>
			</div>
			<div className="content">
				<div className="container">
					<h1 className="title">{drink?.name || "_"}</h1>
					<div onClick={updateFavouriteDrink}>
						<LikeButton count={favourite_count} isLiked={isLiked} />
					</div>
					{drink?.countries?.name_en && (
						<div className="origin">
							<div className="originW">{drink.countries.name_en}</div>
						</div>
					)}

					<div className="description">
						{drink?.drink_subtypes && (
							<div className="type">
								<span className="label">Type: </span>{" "}
								{drink?.drink_subtypes.name} (
								{drink?.drink_subtypes.drink_type?.name})
							</div>
						)}
						<div className="percentage">
							<span className="label">Alcohol: </span>{" "}
							{drink?.alcohol_percentage || 0}%
						</div>
					</div>
					<div
						className="row"
						style={{ justifyContent: "stretch", alignItems: "stretch" }}>
						<div className="found-in">
							<h3 className="results-title">
								<FontAwesomeIcon icon="location-dot" /> Found at
							</h3>

							<ul className="results">
								{locations?.length > 0 ? (
									locations.map((l) => (
										<li key={l.id}>
											<Link to={`${ROUTES.LOCATION}/${l.id}`}>{l.name}</Link>
										</li>
									))
								) : (
									<li>Nowhere yet... 👀</li>
								)}
							</ul>
						</div>
						{/* {locations?.length > 0 && (
							<div className="found-in">
								<h3 className="results-title">Found at</h3>

								<ul className="results">
									<li>Hello</li>
								</ul>
							</div>
						)} */}
					</div>
				</div>
				<div className="recent-activities">
					<h3>
						<FontAwesomeIcon icon="beer" /> Recent Activities
					</h3>
					{recentActivities?.length > 0 ? (
						<div className="activities">
							{recentActivities.map((activity) => (
								<div className="activity" key={activity.id}>
									<Link
										to={`${ROUTES.PROFILE}/${activity.user_visits?.profiles?.username}`}>
										<AvatarIcon
											inline
											size={40}
											url={activity.user_visits?.profiles?.avatar}
										/>
									</Link>
									<div className="body">
										<span className="username">
											{activity.user_visits?.profiles?.username}
										</span>{" "}
										drank <span className="quantity">{activity.ml}ml</span>
										<div className="info">
											at{" "}
											<span className="location">
												<Link
													to={`${ROUTES.LOCATION}/${activity.user_visits?.locations?.id}`}>
													{activity.user_visits?.locations?.name}
												</Link>
											</span>{" "}
											<span className="date">
												{" "}
												-{" "}
												{formatDistance(
													new Date(activity.timestamp),
													new Date(),
													{
														addSuffix: true,
													}
												)}
											</span>
										</div>
									</div>
									{/* <FontAwesomeIcon icon="ellipsis-vertical" /> */}
								</div>
							))}
						</div>
					) : (
						<div className="activity">No recent visits... 👀</div>
					)}
				</div>
			</div>
		</div>
	)
}
