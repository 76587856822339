import React, { useEffect, useState } from "react"
import {
	createBrowserRouter,
	createRoutesFromElements,
	RouterProvider,
	Route,
	Navigate,
} from "react-router-dom"
import { format } from "date-fns"

import * as ROUTES from "./constants/routes"
import Root from "./routes/root"

import LandingPage from "./pages/auth/landing-page"
import LoginPage from "./pages/auth/login-page"

import DashboardPage from "./pages/dashboard/dashboard-page"
import AddDrinkPage from "./pages/dashboard/add-drink-page"
import AddLocationPage from "./pages/dashboard/add-location-page"
import AddProfilesPage from "./pages/dashboard/add-profiles-page"
import NewDrinkPage from "./pages/dashboard/new-drink-page"
import NewLocationPage from "./pages/dashboard/new-location-page"

import ExploreLayout from "./pages/explore/explore-layout"
import ResultsProfilesPage from "./pages/explore/results-profiles-page"
import ResultsLocationsPage from "./pages/explore/results-locations-page"
import ResultsDrinksPage from "./pages/explore/results-drinks-page"
import ProfilePage from "./pages/explore/profile-page"
import LocationPage from "./pages/explore/location-page"
import DrinkPage from "./pages/explore/drink-page"

import EditProfile from "./pages/me/me-edit"
import HistoryVisit from "./pages/history/visit"
import MeLayout from "./pages/me/me-layout"
import MePage from "./pages/me/me-page"
import AddWinePage from "./pages/dashboard/add-wine-page"
import AuthLayout from "./pages/auth/auth-layout"
import DashboardLayout from "./pages/dashboard/dashboard-layout"
import HistoryPage from "./pages/history/history-page"
import ProfileLayout from "./pages/explore/profile-layout"
import AchievementsPage from "./pages/achievements/achievements-page"
import ErrorPage from "./pages/error-page"
import SignUpPage from "./pages/auth/signup-page"
import ForgotPasswordPage from "./pages/auth/forgot-password-page"
import UpdatePasswordPage from "./pages/auth/update-password-page"
import SiteRoot from "./routes/site-root"
import CalendarPage from "./pages/calendar"
import CalculatorPage from "./pages/calculator/calculator-page"
import RandomLocationPage from "./pages/random-location/random-location"
import ListLayout from "./pages/explore/list-layout"
import ListPageFriends from "./pages/explore/list-page-friends"
import ListPageDrinks from "./pages/explore/list-page-drinks"
import ListPageLocations from "./pages/explore/list-page-locations"
import NotificationsPage from "./pages/me/notifications-page"

export default function App() {
	const [todayURL, setTodayURL] = useState(null)

	useEffect(() => {
		let today = new Date()
		today = today.getTime()
		setTodayURL(format(today, "yyyy/MM/dd"))
	}, [])

	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				<Route element={<SiteRoot />} errorElement={<ErrorPage />}>
					<Route index element={<LandingPage />} />
					<Route element={<AuthLayout />}>
						<Route path={ROUTES.LOGIN} element={<LoginPage />} />
						<Route path={ROUTES.SIGN_UP} element={<SignUpPage />} />
						<Route
							path={ROUTES.FORGOT_PASSWORD}
							element={<ForgotPasswordPage />}
						/>
						<Route
							path={ROUTES.UPDATE_PASSWORD}
							element={<UpdatePasswordPage />}
						/>
					</Route>
					<Route element={<Root />}>
						{/* DASHBOARD */}
						<Route path={ROUTES.DASHBOARD} element={<DashboardLayout />}>
							<Route element={<DashboardPage />} index />

							<Route element={<AddLocationPage />} path={"add-location"} />
							<Route element={<NewLocationPage />} path={"new-location"} />

							<Route element={<AddProfilesPage />} path={"add-attendees"} />

							<Route element={<AddDrinkPage />} path={"add-drink"} />
							<Route element={<AddWinePage />} path={"add-wine"} />
							<Route element={<NewDrinkPage />} path={"new-drink"} />
						</Route>

						<Route path={`cal`} element={<CalendarPage />} />
						<Route path={ROUTES.CALCULATOR} element={<CalculatorPage />} />
						<Route
							path={ROUTES.RANDOM_LOCATION}
							element={<RandomLocationPage />}
						/>

						{/* ME */}
						<Route path={`me`} element={<MeLayout />}>
							<Route index element={<MePage />} />
						</Route>

						{/* ACCOUNTS */}
						<Route path={"accounts"}>
							<Route path={"edit"} element={<EditProfile />} />
						</Route>

						{/* ACHIEVEMENTS */}
						<Route path={`achievements`} element={<AchievementsPage />}></Route>
						<Route
							path={`notifications`}
							element={<NotificationsPage />}></Route>

						{/* HISTORY */}
						<Route path={`${ROUTES.HISTORY}`} element={<HistoryPage />}>
							<Route
								index
								element={
									<Navigate to={`${ROUTES.HISTORY}/${todayURL}`} replace />
								}
							/>

							<Route
								path=":y/"
								errorElement={<Navigate to={`${ROUTES.HISTORY}/${todayURL}`} />}
							/>

							<Route
								path=":y/:m"
								errorElement={<Navigate to={`${ROUTES.HISTORY}/${todayURL}`} />}
							/>
							<Route
								path=":y/:m/:d"
								element={<HistoryVisit />}
								errorElement={<Navigate to={`${ROUTES.HISTORY}/${todayURL}`} />}
							/>
						</Route>

						{/* EXPLORE */}
						<Route path={`${ROUTES.EXPLORE}`} element={<ExploreLayout />}>
							<Route path={`locations`} element={<ResultsLocationsPage />} />
							<Route path={`drinks`} element={<ResultsDrinksPage />} />
							<Route index element={<Navigate to="drinks" replace />} />
							<Route path={`profiles`} element={<ResultsProfilesPage />} />
						</Route>

						<Route
							path={`${ROUTES.PROFILE}/:username`}
							element={<ProfileLayout />}>
							<Route index element={<ProfilePage />} />
							<Route element={<ListLayout />}>
								<Route path={`friends`} element={<ListPageFriends />} />
								<Route path={`drinks`} element={<ListPageDrinks />} />
								<Route path={`locations`} element={<ListPageLocations />} />
							</Route>
						</Route>

						<Route path={`${ROUTES.LOCATION}/:id`} element={<LocationPage />} />

						<Route path={`${ROUTES.DRINK}/:id`} element={<DrinkPage />} />
					</Route>
				</Route>
			</>
		)
	)

	return <RouterProvider router={router} />
}
