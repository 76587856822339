import React, { useEffect, useState } from "react"
import { APP_NAME } from "../.."
import {
	Link,
	//  useNavigate
} from "react-router-dom"
import * as ROUTES from "../../constants/routes"
import Fieldset from "../../components/Fieldset"
import { supabase } from "../../lib/supabaseClient"

export default function ForgotPasswordPage() {
	// const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [email, setEmail] = useState("")
	const [emailError, setEmailError] = useState(false)

	useEffect(() => {
		document.title = `Forgot Password • ${APP_NAME}`
	}, [])

	async function handleOnSubmit(e) {
		e.preventDefault()

		if (email === "") {
			setEmailError("Email required")
			return
		}

		setLoading(true)

		const { data, error } = await supabase.auth.resetPasswordForEmail(email)
		if (error) {
			console.error(error)
			setError(error.message)
			setLoading(false)
		} else {
			setLoading(false)
			return data
		}
	}

	return (
		<>
			<form onSubmit={handleOnSubmit}>
				<h1>Forgot password</h1>

				<Fieldset
					id="email"
					label="Email"
					type="email"
					field={email}
					setField={setEmail}
					error={emailError}
					setError={setEmailError}
				/>

				<fieldset>
					<button className="button">
						{loading ? "Loading..." : "Send reset instructions"}
					</button>
				</fieldset>
			</form>
			{error && <p className="error-message">{error}</p>}
			<p>
				<Link to={ROUTES.LOGIN}>Back to log in</Link>
			</p>
			<p>
				<Link to={ROUTES.SIGN_UP}>Don't have an account yet? Sign up</Link>
			</p>
		</>
	)
}
