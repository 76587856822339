import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { supabase } from "../../lib/supabaseClient"
import * as ROUTES from "../../constants/routes"
import executeQuery from "../../helpers/executeQuery"
import WineButton from "../../components/WineButton"
import { useMutation, useQueryClient } from "@tanstack/react-query"

export default function AddWinePage() {
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { user, currentVisit } = useOutletContext()
	let userId = user?.user_id || null

	// Handle search
	const [queryError, setQueryError] = useState(false)
	const [selectedDrink, setSelectedDrink] = useState(13)
	const [percentage, setPercentage] = useState(12.5)
	const [percentageError, setPercentageError] = useState(false)

	// Quick add
	const [quantity, setQuantity] = useState(0)
	const [quantityError, setQuantityError] = useState(false)

	const insertUserVisitDrink = useMutation({
		mutationFn: async (drink) => {
			const { data } = await executeQuery(
				supabase.from("user_visit_drinks").insert([drink]).select()
			)
			return data
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["current_visit", userId])
		},
	})

	const insertLocationDrink = useMutation({
		mutationFn: async (upload) => {
			const { data } = await executeQuery(
				supabase.from("location_drinks").insert([upload]).select()
			)
			return data
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["location_drinks"])
		},
	})

	async function handleOnSubmit(e) {
		e.preventDefault()

		try {
			if (!selectedDrink) {
				setQueryError(true)
				throw new Error("Please select a type of wine")
			}
			if (!quantity > 0) {
				setQuantityError(true)
				throw new Error("Please select an amount")
			}
			if (!currentVisit) {
				throw new Error("No current visit")
			}

			let newLocationDrink = {
				drink_id: selectedDrink.id,
				location_id: currentVisit.location.id,
			}
			insertLocationDrink.mutate(newLocationDrink)

			let newUserVisitDrink = {
				user_visit_id: currentVisit?.id,
				drink_id: selectedDrink,
				ml: quantity,
				alcohol_percentage: percentage,
				type: "wine",
			}
			insertUserVisitDrink.mutate(newUserVisitDrink)
			navigate(ROUTES.DASHBOARD)
		} catch (error) {
			console.error(error.message)
		}
	}

	const QuickAdd = ({ icon, title, ml, size }) => {
		return (
			<div
				className="quick-add"
				onClick={() => {
					setQuantityError(false)
					setQuantity(ml)
				}}>
				{icon && (
					<div className="icon">
						<FontAwesomeIcon icon={icon} size={size} />
					</div>
				)}
				{title && <div className="title">{title}</div>}
				{ml && <div className="amount">{`(${ml} ml)`}</div>}
			</div>
		)
	}

	function handleReturn() {
		navigate(ROUTES.DASHBOARD)
	}

	return (
		<div className="page-dashboard">
			<div className="page-container">
				<main>
					<div>
						<form method="post" onSubmit={handleOnSubmit} className="form">
							<p>
								<FontAwesomeIcon
									icon={`arrow-left`}
									style={{ cursor: "pointer" }}
									onClick={() => handleReturn()}
								/>
							</p>
							<h3>What are you drinking?</h3>
							<fieldset>
								<p>
									<strong>Type of wine</strong>
								</p>

								<div className="wine-buttons">
									<WineButton
										selectedDrink={selectedDrink}
										setSelectedDrink={setSelectedDrink}
										setQueryError={setQueryError}
										setPercentage={setPercentage}
										title="Red Wine"
										id={13}
										icon={"wine-bottle"}
									/>
									<WineButton
										selectedDrink={selectedDrink}
										setSelectedDrink={setSelectedDrink}
										setQueryError={setQueryError}
										setPercentage={setPercentage}
										title="White Wine"
										id={14}
										icon={"wine-bottle"}
									/>
									<WineButton
										selectedDrink={selectedDrink}
										setSelectedDrink={setSelectedDrink}
										setQueryError={setQueryError}
										setPercentage={setPercentage}
										title="Rosé"
										id={15}
										icon={"wine-bottle"}
									/>
									<WineButton
										selectedDrink={selectedDrink}
										setSelectedDrink={setSelectedDrink}
										setPercentage={setPercentage}
										title="Champagne"
										id={16}
										icon={"wine-bottle"}
									/>
								</div>

								{queryError && <p className="error">Please chose a drink</p>}
							</fieldset>
							<fieldset>
								<div className="row" style={{ gap: 40 }}>
									<div className="amount-field">
										<label htmlFor="quantity">Amount</label>
										<div className="input-group">
											<input
												id="quantity"
												type="number"
												placeholder="0"
												min={0}
												value={quantity || ""}
												onChange={(e) => {
													setQuantityError(false)
													setQuantity(e.target.value)
												}}
											/>
											<div className="unit">ml</div>
										</div>
									</div>
									<div className="amount-field">
										<label htmlFor="percentage">Percentage</label>
										<div className="input-group">
											<input
												id="percentage"
												type="number"
												placeholder="0.0"
												step="0.1"
												min={0}
												max={100}
												value={percentage || null}
												onChange={(e) => {
													setPercentageError(false)
													setPercentage(e.target.value)
												}}
											/>
											<div className="unit">%</div>
										</div>
									</div>
								</div>
								{quantityError && (
									<p className="error" style={{ textAlign: "center" }}>
										Please add quantity
									</p>
								)}

								{percentageError && (
									<p className="error" style={{ textAlign: "center" }}>
										Please add percentage
									</p>
								)}
							</fieldset>

							<fieldset>
								<p>
									<strong>Quick adds</strong>
								</p>
								<div className="quick-adds">
									<QuickAdd
										icon="wine-glass"
										title="small"
										ml={125}
										size="sm"
									/>
									<QuickAdd
										icon="wine-glass"
										title="medium"
										ml={175}
										size="lg"
									/>
									<QuickAdd
										icon="wine-glass"
										title="large"
										ml={250}
										size="xl"
									/>
								</div>
							</fieldset>

							<fieldset>
								<button className="button">Submit</button>
							</fieldset>
						</form>
					</div>
				</main>
			</div>
		</div>
	)
}
