import React from "react"
import NumberAnimation from "../NumberAnimation"
import { format } from "date-fns"

export default function FollowersStats({
	created_at,
	loading,
	followers,
	friends,
}) {
	return (
		<div className="followers-stats">
			<div className="followers-amount">
				<div className="text">Followers</div>
				<div className="number">
					{followers ? <NumberAnimation number={followers.length} /> : 0}
				</div>
			</div>
			<div className="followers-amount date">
				<div className="text">Joined</div>
				<div className="number">
					{loading
						? "..."
						: created_at
						? ` ${format(new Date(created_at), "do MMM yyyy")}`
						: "???"}
				</div>
			</div>
			<div className="followers-amount">
				<div className="text">Friends</div>
				<div className="number">
					{friends ? <NumberAnimation number={friends.length} /> : 0}
				</div>
			</div>
		</div>
	)
}
