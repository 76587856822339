import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import React, { useEffect, useState } from "react"
import { supabase } from "../../lib/supabaseClient"
import { format } from "date-fns"
import Spinner from "../Spinner"

export default function AddFriendButton({
	userFriends,
	userId,
	friendId,
	friends,
}) {
	const queryClient = useQueryClient()
	const [isFriend, setIsFriend] = useState(null)
	const [followsMe, setFollowsMe] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	useEffect(() => {
		if (userFriends.length > 0) {
			setIsFriend(userFriends.find((item) => item.user_id === friendId) || null)
		}
		setIsLoading(false)
	}, [friendId, userFriends])

	useEffect(() => {
		const profileFriends = friends?.map((item) => item.user) || []
		if (profileFriends.length > 0) {
			setFollowsMe(
				profileFriends.find((item) => item.user_id === userId) || null
			)
		}
		setIsLoading(false)
	}, [userId, friends])

	const addFriend = useMutation({
		mutationFn: async () => {
			const { data, error } = await supabase
				.from("friends")
				.insert([{ user_id: userId, friend_id: friendId }])
				.select()
			if (error) {
				console.error(error)
			} else {
				return data
			}
		},
		onMutate: () => {
			setIsLoading(true)
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["friends", userId])
			setIsLoading(false)
		},
	})

	const removeFriend = useMutation({
		mutationFn: async () => {
			const { error } = await supabase
				.from("friends")
				.delete()
				.eq("id", isFriend?.id)
			if (error) {
				console.error(error)
			}
		},
		onMutate: () => {
			setIsLoading(true)
			queryClient.invalidateQueries(["friends", userId])
			queryClient.invalidateQueries(["followers", userId])
		},
		onSuccess: () => {
			setIsFriend(false)
			setIsLoading(false)
		},
	})

	function updateFriend() {
		if (!isFriend) {
			addFriend.mutate()
		} else {
			removeFriend.mutate()
		}
	}

	return (
		<div className="friend-button-w">
			<button
				className="button"
				disabled={isLoading}
				onClick={updateFriend}
				aria-label={
					isFriend ? "Unfriend" : followsMe ? "Follow back" : "Add friend"
				}
				aria-disabled={isLoading}>
				{isLoading ? (
					<Spinner inline white />
				) : (
					<>
						{isFriend ? "Friends" : followsMe ? "Follow back" : "Add friend"}
						<FontAwesomeIcon icon={isFriend ? "check" : "user-plus"} />
					</>
				)}
			</button>

			<div className={`following-since ${isFriend ? "active" : ""}`}>
				{isFriend
					? `Following since ${format(
							new Date(isFriend.created_at),
							"MMM do, yyyy"
					  )}`
					: "Not following anymore"}
			</div>
		</div>
	)
}
