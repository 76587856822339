import React from "react"
import m1 from "../assets/avatars/m1.jpg"
import m2 from "../assets/avatars/m2.jpg"
import m3 from "../assets/avatars/m3.jpg"
import m4 from "../assets/avatars/m4.jpg"
import m5 from "../assets/avatars/m5.jpg"
import m6 from "../assets/avatars/m6.jpg"
import f1 from "../assets/avatars/f1.jpg"
import f2 from "../assets/avatars/f2.jpg"
import f3 from "../assets/avatars/f3.jpg"
import f4 from "../assets/avatars/f4.jpg"
import f5 from "../assets/avatars/f5.jpg"
import f6 from "../assets/avatars/f6.jpg"
import d1 from "../assets/avatars/d1.jpg"
import d2 from "../assets/avatars/d2.jpg"
import d3 from "../assets/avatars/d3.jpg"
import d4 from "../assets/avatars/d4.jpg"
import d5 from "../assets/avatars/d5.jpg"
import d6 from "../assets/avatars/d6.jpg"

export default function AvatarIcon({
	url,
	size = 50,
	name,
	centered,
	loading,
}) {
	let avatarUrl
	switch (url) {
		case 1:
			avatarUrl = f1
			break
		case 2:
			avatarUrl = f2
			break
		case 3:
			avatarUrl = f3
			break
		case 4:
			avatarUrl = f4
			break
		case 5:
			avatarUrl = f5
			break
		case 6:
			avatarUrl = f6
			break
		case 7:
			avatarUrl = m1
			break
		case 8:
			avatarUrl = m2
			break
		case 9:
			avatarUrl = m3
			break
		case 10:
			avatarUrl = m4
			break
		case 11:
			avatarUrl = m5
			break
		case 12:
			avatarUrl = m6
			break
		case 13:
			avatarUrl = d1
			break
		case 14:
			avatarUrl = d2
			break
		case 15:
			avatarUrl = d3
			break
		case 16:
			avatarUrl = d4
			break
		case 17:
			avatarUrl = d5
			break
		case 18:
			avatarUrl = d6
			break
		default:
			avatarUrl = false
	}

	let styles = {
		height: size,
		width: size,
		marginLeft: centered ? "auto" : "",
		marginRight: centered ? "auto" : "",
	}

	return (
		<div className="avatarIcon" style={styles}>
			<div className="avatar">
				<div className={loading ? "loader" : "loader finished"} />
				{avatarUrl && (
					<img
						src={avatarUrl}
						alt={"Avatar"}
						className={url ? "image" : "no-image"}
					/>
				)}
			</div>
			{name && <div className="name">{name}</div>}
		</div>
	)
}
