import { NavLink, useMatch } from "react-router-dom"

const FavNav = () => {
	const match1 = useMatch("profiles/:username/locations")
	const match2 = useMatch("profiles/:username/drinks")
	let m = match1 ? 1 : match2 ? 2 : 0
	return (
		<nav className="list-page-nav">
			<ul>
				<div className="line" style={{ left: `${33.33 * m}%` }} />
				<li>
					<NavLink to={"friends"}>Friends</NavLink>
				</li>
				<li>
					<NavLink to={"locations"}>Locations</NavLink>
				</li>
				<li>
					<NavLink to={"drinks"}>Drinks</NavLink>
				</li>
			</ul>
		</nav>
	)
}

export default FavNav
