import React, { Fragment, useEffect, useState } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { RECENT_SEARCHES_STORAGE_KEY } from "../../constants/paths"
import * as ROUTES from "../../constants/routes"
import { supabase } from "../../lib/supabaseClient"
import executeQuery from "../../helpers/executeQuery"
import ResultDrink from "../../components/explore/ResultDrink"
import Spinner from "../../components/Spinner"
import { useInfiniteQuery } from "@tanstack/react-query"

export default function ResultsDrinksPage() {
	const navigate = useNavigate()
	const { query, storedSearches } = useOutletContext()

	const [recentDrinks, setRecentDrinks] = useState([]) // Initialize to empty array
	const [recentDrinkIds, setRecentDrinkIds] = useState([])
	const [searchResults, setSearchResults] = useState([])
	const [isLoading, setIsLoading] = useState(false) // Add loading state

	useEffect(() => {
		const fetchRecentDrinks = async () => {
			try {
				const storedKey = `${RECENT_SEARCHES_STORAGE_KEY}`
				const storedData = JSON.parse(localStorage.getItem(storedKey))
				setRecentDrinks(storedData?.recent_drinks || []) // Handle missing data
			} catch (error) {
				console.error("Error fetching recent drinks:", error)
			}
		}

		fetchRecentDrinks()
	}, [])

	useEffect(() => {
		let ids = recentDrinks.length > 0 ? recentDrinks.map((item) => item.id) : []
		setRecentDrinkIds(ids)
	}, [recentDrinks])

	useEffect(() => {
		const handleSearch = async () => {
			setIsLoading(true) // Set loading state

			try {
				const { data } = await executeQuery(
					supabase
						.from("drinks")
						.select(
							"name,id,alcohol_percentage,type,drink_subtypes (name,drink_type (	name,	id))"
						)
						.ilike("name", `%${query}%`)
						.limit(5)
				)
				setSearchResults(data)
			} catch (error) {
				console.error("Error searching drinks:", error)
			} finally {
				setIsLoading(false) // Clear loading state
			}
		}

		if (query.trim() === "") {
			setSearchResults([])
			return
		}

		handleSearch()
	}, [query])

	async function updateRecentDrinks(arr) {
		if (arr) {
			try {
				const updatedData = {
					...storedSearches,
					recent_drinks: arr.slice(0, 5), // Limit to 5 recent drinks
				}
				const storedKey = `${RECENT_SEARCHES_STORAGE_KEY}`
				localStorage.setItem(storedKey, JSON.stringify(updatedData))
			} catch (error) {
				console.error("Error storing recent drinks:", error)
			}
		}
	}

	async function navigateToDrink(path) {
		navigate(path)
	}

	async function handleOnClick(drink) {
		try {
			const filteredRecentDrinks = recentDrinks.filter(
				(item) => item.id !== drink.id
			)
			const updatedRecentDrinks = [drink, ...filteredRecentDrinks].slice(0, 5) // Update and limit

			await updateRecentDrinks(updatedRecentDrinks)
			await navigateToDrink(`${ROUTES.DRINK}/${drink.id}`)
		} catch (error) {
			console.error("Error handling click:", error)
		}
	}
	const size = 5

	const {
		isLoading: isLoadingSuggestedDrinks,
		data: suggestedDrinks,
		fetchNextPage: fetchNextPageSuggestedDrinks,
		hasNextPage: hasNextPageSuggestedDrinks,
		isFetchingNextPage: isFetchingNextPageSuggestedDrinks,
	} = useInfiniteQuery({
		queryKey: ["suggested_drinks", recentDrinkIds],
		queryFn: async ({ pageParam }) => {
			const { data, error } = await supabase
				.from("drinks")
				.select(
					"name,id,alcohol_percentage,type,drink_subtypes(name,drink_type(name,id))"
				)
				.not("id", "in", `(${recentDrinkIds})`)
				.range(pageParam * size, (pageParam + 1) * size - 1)
				.order("id", { ascending: false })
			if (error) {
				console.log(error)
			} else {
				return data
			}
		},
		initialPageParam: 0,
		getNextPageParam: (lastPage, allPages, lastPageParam) => {
			if (lastPage.length === 0) {
				return undefined
			}
			return lastPageParam + 1
		},
		enabled: !!recentDrinkIds,
	})

	return (
		<>
			<section className="results-list" id="results">
				{(searchResults.length > 0 || query.length > 0) && (
					<>
						<h3>Results</h3>
						{searchResults.length > 0 ? (
							isLoading ? (
								<p>
									<Spinner inline dark />
								</p>
							) : (
								<ul className="search-results">
									{searchResults.map((drink) => (
										<ResultDrink
											key={drink.id}
											drink={drink}
											handleOnClick={handleOnClick}
										/>
									))}
								</ul>
							)
						) : (
							<p className="no-results">No results</p>
						)}
					</>
				)}
			</section>
			{recentDrinks?.length > 0 && (
				<section className="results-list" id="recent-results">
					<h3>Recent drink searches</h3>
					<ul className="search-results reversed">
						{recentDrinks.map((drink) => (
							<ResultDrink
								key={drink.id}
								drink={drink}
								handleOnClick={handleOnClick}
							/>
						))}
					</ul>
				</section>
			)}
			{suggestedDrinks?.pages[0]?.length > 0 && (
				<section className="results-list" id="suggested-results">
					<h3>Suggested drinks</h3>
					{!isLoadingSuggestedDrinks ? (
						<>
							<ul className="search-results">
								{suggestedDrinks.pages.map((group, i) => (
									<Fragment key={i}>
										{group.map((drink) => (
											<ResultDrink
												key={drink.id}
												drink={drink}
												handleOnClick={handleOnClick}
											/>
										))}
									</Fragment>
								))}
							</ul>
							{hasNextPageSuggestedDrinks && (
								<div className="load-more-container">
									<button
										className="load-more-button"
										onClick={() => fetchNextPageSuggestedDrinks()}
										disabled={isFetchingNextPageSuggestedDrinks}>
										{isFetchingNextPageSuggestedDrinks ? (
											<Spinner inline dark />
										) : (
											"Load more"
										)}
									</button>
								</div>
							)}
						</>
					) : (
						<Spinner inline dark />
					)}
				</section>
			)}
		</>
	)
}
