export default async function executeQuery(query) {
	try {
		const { data, error } = await query

		if (error) {
			console.error(error)
		}

		return { data }
	} catch (error) {
		console.error(error)
		throw new Error("An error occurred while processing the request.")
	}
}
