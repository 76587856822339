import { Link } from "react-router-dom"
import AvatarIcon from "../AvatarIcon"
import Drink from "./Drink"
import * as ROUTES from "../../constants/routes"
import { useState } from "react"
import VisitEdit from "./VisitEdit"
import { format } from "date-fns"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function Visit({ visit, userId }) {
	let attendees = []
	let drinks = []
	let location

	if (visit) {
		attendees = visit.attendees
		drinks = visit.drinks
		location = visit.location
	}

	const [modal, setModal] = useState(false)
	return (
		<div className="visit">
			{location && (
				<div className="location">
					{location.id === 25 ? (
						<span className="name">{location.name}</span>
					) : (
						<Link to={`${ROUTES.LOCATION}/${location.id}`}>
							<span className="name">{location.name}</span>
						</Link>
					)}
					{" - "}
					<span className="date">
						{format(new Date(visit.timestamp), "HH:mm")}
					</span>
				</div>
			)}
			{attendees.length > 0 && (
				<>
					<small>with:</small>
					<div className="attendees">
						{attendees.map(
							(attendee) =>
								attendee.avatar && (
									<Link
										key={attendee.id}
										to={`${ROUTES.PROFILE}/${attendee.username}`}>
										<AvatarIcon size={40} url={attendee.avatar} />
									</Link>
								)
						)}
					</div>
				</>
			)}
			<ul className="drinks">
				{drinks.length > 0
					? visit?.drinks.map((drink) => <Drink key={drink.id} drink={drink} />)
					: "no drinks logged in"}
			</ul>
			<div className="edit">
				<span onClick={() => setModal(true)}>
					Edit <FontAwesomeIcon icon="pen" />
				</span>
			</div>
			{modal && <VisitEdit visit={visit} setModal={setModal} userId={userId} />}
		</div>
	)
}
