import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

const Actions = ({ icon, title, description, link, type = "add" }) => {
	return (
		<Link to={link}>
			<div className="action">
				{icon && (
					<div className="icon">
						<FontAwesomeIcon icon={icon} />
					</div>
				)}
				<div className="text">
					<div className="title">{title}</div>
					<div className="description">{description}</div>
				</div>
				<div className="plus">
					<FontAwesomeIcon icon={type === "add" ? "plus" : "pen"} />
				</div>
			</div>
		</Link>
	)
}

export default Actions
