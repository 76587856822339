import React, { useEffect, useState } from "react"
import { APP_NAME } from "../.."
import { Link, useNavigate } from "react-router-dom"
import * as ROUTES from "../../constants/routes"
import { supabase } from "../../lib/supabaseClient"
import executeQuery from "../../helpers/executeQuery"
import Fieldset from "../../components/Fieldset"
import {
	isValidEmail,
	isValidPassword,
	isValidPasswordError,
} from "../../helpers/formChecks"
import { useQuery } from "@tanstack/react-query"
import Spinner from "../../components/Spinner"

export default function SignUpPage() {
	const navigate = useNavigate()

	const [loading, setLoading] = useState(false)
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [username, setUsername] = useState("")
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [firstNameError, setFirstNameError] = useState(false)
	const [lastNameError, setLastNameError] = useState(false)
	const [usernameError, setUsernameError] = useState(false)
	const [emailError, setEmailError] = useState(false)
	const [passwordError, setPasswordError] = useState(false)
	const [success, setSuccess] = useState(false)

	const { data: existingUsernames } = useQuery({
		queryKey: ["all-usernames"],
		queryFn: async () => {
			const { data } = await supabase.from("profiles").select("username")
			return data?.map((u) => u.username)
		},
	})

	const { data: existingEmails } = useQuery({
		queryKey: ["all-emails"],
		queryFn: async () => {
			const { data } = await supabase.from("profiles").select("email")
			return data?.map((u) => u.email)
		},
	})

	function checkFirstName(name) {
		// is empty
		if (name === "") {
			setFirstNameError("")
			return false
		}
		// is not valid
		if (!/^[a-zA-Z0-9- ]+$/.test(name)) {
			setFirstNameError("Not valid")
			return false
		}
		// is too long
		if (firstName.length < 2) {
			setFirstNameError("Too short")
			return false
		}
		return true
	}

	function checkLastName(name) {
		// is empty
		if (name === "") {
			setLastNameError("")
			return false
		}
		// is not valid
		if (!/^[a-zA-Z0-9- ]+$/.test(name)) {
			setLastNameError("Not valid")
			return false
		}
		// is too long
		if (lastName.length < 2) {
			setLastNameError("Too short")
			return false
		}
		return true
	}

	function checkUsername(username) {
		// is empty
		if (username === "") {
			setUsernameError("")
			return false
		}
		// is too short
		if (username.length > 21) {
			setUsernameError("Too long")
			return false
		}
		// is not valid
		if (!/^[a-z0-9-]+$/.test(username)) {
			setUsernameError("Lowercase letters and numbers only")
			return false
		}
		// is too long
		if (username.length <= 3) {
			setUsernameError("Too short")
			return false
		}
		// is not available
		if (
			existingUsernames &&
			existingUsernames.length > 0 &&
			existingUsernames.includes(username)
		) {
			setUsernameError("Already taken")
			return false
		}
		return true
	}

	function checkEmail(email) {
		// is empty
		if (email === "") {
			setEmailError("")
			return false
		}
		// is not valid
		if (!isValidEmail(email)) {
			setEmailError("Email not valid")
			return false
		}
		// is not available
		if (
			existingEmails &&
			existingEmails.length > 0 &&
			existingEmails.includes(email)
		) {
			setEmailError("Already taken")
			return false
		}
		return true
	}

	function checkPassword(password) {
		// is empty
		if (password === "") {
			setPasswordError("")
		}
		// is not valid
		if (!isValidPassword(password)) {
			setPasswordError(isValidPasswordError(password))
			return
		}
		return true
	}

	async function handleOnSubmit(e) {
		e.preventDefault()

		if (
			!checkFirstName(firstName) ||
			!checkLastName(lastName) ||
			!checkUsername(username) ||
			!checkEmail(email) ||
			!checkPassword(password)
		)
			setLoading(true)
		await executeQuery(
			supabase.auth.signUp({
				email: email,
				password: password,
				options: {
					data: {
						f_name: firstName,
						l_name: lastName,
						username: username,
					},
				},
			})
		)
		setSuccess(true)
		setLoading(false)
	}

	function handleModalButtonClick(e) {
		e.preventDefault()
		navigate(ROUTES.LOGIN)
	}

	useEffect(() => {
		document.title = `Sign up • ${APP_NAME}`
	}, [])

	return (
		<>
			{success && (
				<div className="modal-container">
					<div className="modal">
						<h3>Please check your emails for the confirmation link.</h3>
						<button className="button ghost" onClick={handleModalButtonClick}>
							OK
						</button>
					</div>
				</div>
			)}
			<form onSubmit={handleOnSubmit}>
				<h1>Sign up</h1>
				<div className="row">
					<div>
						<Fieldset
							id="f_name"
							label="First Name"
							field={firstName}
							setField={setFirstName}
							error={firstNameError}
							setError={setFirstNameError}
							checkFunction={checkFirstName}
							autocapitalize="words"
							required
						/>
					</div>
					<div>
						<Fieldset
							id="l_name"
							label="Last Name"
							field={lastName}
							setField={setLastName}
							error={lastNameError}
							setError={setLastNameError}
							checkFunction={checkLastName}
							autocapitalize="words"
							required
						/>
					</div>
				</div>

				<Fieldset
					id="username"
					label="Username"
					field={username}
					setField={setUsername}
					error={usernameError}
					setError={setUsernameError}
					checkFunction={checkUsername}
					autocapitalize="off"
					required
				/>
				<Fieldset
					id="email"
					label="Email"
					type="email"
					field={email}
					setField={setEmail}
					error={emailError}
					setError={setEmailError}
					checkFunction={checkEmail}
					required
				/>
				<Fieldset
					id="password"
					label="Password"
					type="password"
					field={password}
					setField={setPassword}
					error={passwordError}
					setError={setPasswordError}
					checkFunction={checkPassword}
					instructions={
						"Must include lower and uppercase letters, digits and symbols"
					}
					required
				/>
				<fieldset>
					<button className="button">
						{loading ? <Spinner inline white /> : "Sign up"}
					</button>
				</fieldset>
			</form>
			<p>
				<Link to={ROUTES.LOGIN}>
					Already have an account? <strong>Sign in</strong>
				</Link>
			</p>
		</>
	)
}
