import React, { useMemo } from "react"
import { Outlet, useNavigate } from "react-router-dom"
// import FooterNav from "../components/FooterNav"
import { CURRENT_MODE_STORAGE_KEY } from "../constants/paths"
import { useEffect, useState } from "react"
import { useSession } from "../context/SessionContext"
import * as ROUTES from "../constants/routes"
import { supabase } from "../lib/supabaseClient"
import GlobalLoadingIndicator from "../components/GlobalLoadingIndicator"
import { useQuery } from "@tanstack/react-query"
import {
	checkAchievementProgress,
	checkAndCreateUserAchievement,
} from "../helpers/achievementFunctions"
// import Notification from "../components/Notification"
import { calculateUnits } from "../helpers/calculateUnits"
import FooterNav from "../components/FooterNav"

export default function Root() {
	const navigate = useNavigate()
	const { session } = useSession()
	let userId = session?.user?.id || null

	const [modal, setModal] = useState(false)

	// REMOVE FLUID
	useEffect(() => {
		async function wait() {
			return new Promise((resolve) => setTimeout(resolve, 500))
		}
		async function fluidLaunch() {
			const fluidEl = document?.querySelector(".fluid")
			fluidEl?.classList?.remove("is-active")
		}
		async function load() {
			await wait()
			await fluidLaunch()
		}
		load()
	}, [userId])

	// CHECK IF LOGGED IN
	useEffect(() => {
		async function wait() {
			return new Promise((resolve) => setTimeout(resolve, 500))
		}
		async function showModal() {
			setModal(true)
		}
		async function load() {
			if (!session) {
				await wait()
				await showModal()
			}
		}
		load()
	}, [session])

	// fetch user data
	const { isLoading: loading, data: user } = useQuery({
		queryKey: ["me", userId],
		queryFn: async () => {
			const { data } = await supabase
				.from("profiles")
				.select(
					"avatar, f_name, l_name, username, user_id, user_number, email, height, gender, weight, dob, created_at, current_visit_id(id, timestamp, location: location_id(name, id))"
				)
				.eq("user_id", userId)
				.single()

			if (data === null) {
				navigate(ROUTES.LANDING_PAGE)
			} else {
				return data
			}
		},
		enabled: !!userId,
		onError: (error) => {
			console.error("Error fetching user data:", error)
		},
	})

	// GEOLOCATION
	const [userCoordinates, setUserCoordinates] = useState(null)

	useEffect(() => {
		var options = {
			enableHighAccuracy: true,
			timeout: 5000,
			maximumAge: 0,
		}
		function success(pos) {
			var crd = pos.coords
			setUserCoordinates({ latitude: crd.latitude, longitude: crd.longitude })
		}

		function errors(err) {
			console.warn(`ERROR(${err.code}): ${err.message}`)
		}

		const handleGetLocation = async () => {
			if (navigator.geolocation) {
				navigator.permissions
					.query({ name: "geolocation" })
					.then(function (result) {
						// console.log(result)
						if (result.state === "granted") {
							//If granted then you can directly call your function here
							navigator.geolocation.getCurrentPosition(success, errors, options)
						} else if (result.state === "prompt") {
							//If prompt then the user will be asked to give permission
							navigator.geolocation.getCurrentPosition(success, errors, options)
						} else if (result.state === "denied") {
							//If denied then you have to show instructions to enable location
							console.log("Please enable geolocation for a better experience.")
						}
					})
			} else {
				console.log("Geolocation is not supported by this browser.")
			}
		}

		handleGetLocation()
	}, [])

	// fetch friends
	const { data: friends } = useQuery({
		queryKey: ["friends", userId],
		queryFn: async () => {
			const { data } = await supabase
				.from("friends")
				.select("user_id: friend_id, created_at, id")
				.eq("user_id", userId)

			return data
		},
		enabled: !!userId,
	})

	// fetch followers
	const { data: followers } = useQuery({
		queryKey: ["followers", userId],
		queryFn: async () => {
			const { data, error } = await supabase
				.from("friends")
				.select(
					"user_id: friend_id, created_at, user: friend_id(user_id, f_name, l_name, avatar, username)"
				)
				.eq("friend_id", userId)
			if (error) {
				console.log(error)
			} else {
				return data
			}
		},
		enabled: !!userId,
	})

	// fetch favourite locations
	const { data: favourite_locations } = useQuery({
		queryKey: ["favourite_locations", userId],
		queryFn: async () => {
			const { data } = await supabase
				.from("favourite_locations")
				.select("location_id, created_at, id")
				.eq("user_id", userId)

			return data
		},
		enabled: !!userId,
	})

	// fetch favourite drinks
	const { data: favourite_drinks } = useQuery({
		queryKey: ["favourite_drinks", userId],
		queryFn: async () => {
			const { data } = await supabase
				.from("favourite_drinks")
				.select("drink_id, created_at, id")
				.eq("user_id", userId)

			return data
		},
		enabled: !!userId,
	})

	// fetch user visits
	const { data: user_visits } = useQuery({
		queryKey: ["user_visits", userId],
		queryFn: async () => {
			const { data } = await supabase
				.from("user_visits")
				.select(
					"id, timestamp, expires, location:locations(id, name), drinks:user_visit_drinks(id, ml, timestamp, drink:drinks(id, alcohol_percentage, name)), attendees:user_visit_attendees(id, user:profiles(user_id, username, avatar))"
				)
				.eq("user_id", userId)
				.order("timestamp", { ascending: true })
			return data
		},
		enabled: !!userId,
	})

	const userVisits = useMemo(() => {
		if (!user_visits) return []

		return user_visits.map((visit) => {
			const attendees = visit.attendees.map((item) => ({
				id: item.id,
				user_id: item.user.user_id,
				username: item.user.username,
				avatar: item.user.avatar,
			}))

			const drinks = visit.drinks.map((item) => ({
				alcohol_percentage: item.drink.alcohol_percentage,
				id: item.id,
				drink_id: item.drink.id,
				name: item.drink.name,
				timestamp: item.timestamp,
				ml: item.ml,
				units: calculateUnits(item.ml, item.drink.alcohol_percentage),
			}))

			return {
				attendees: attendees,
				drinks: drinks,
				id: visit.id,
				location: {
					id: visit.location.id,
					name: visit.location.name,
				},
				timestamp: visit.timestamp,
				expires: visit.expires,
			}
		})
	}, [user_visits])

	const notifications = [
		// {
		// 	id: 1,
		// 	title: "Notification",
		// 	type: "event",
		// 	read: false,
		// },
		// {
		// 	id: 2,
		// 	title: "Notification",
		// 	type: "friend",
		// 	read: false,
		// },
		// {
		// 	id: 3,
		// 	title: "Notification",
		// 	type: "drink",
		// 	read: false,
		// },
		// {
		// 	id: 4,
		// 	title: "Notification",
		// 	type: "location",
		// 	read: false,
		// },
		// {
		// 	id: 5,
		// 	title: "Notification",
		// 	type: "achievement",
		// 	read: false,
		// },
	]

	// ACHIEVEMENTS
	const { data: achievements } = useQuery({
		queryKey: ["achievements"],
		queryFn: async () => {
			const { data } = await supabase
				.from("achievements")
				.select(
					"id, name, description, criteria, target, units:achievement_units(*), points, type"
				)
				.eq("active", true)
			return data
		},
	})

	const { data: user_achievements } = useQuery({
		queryKey: ["user_achievements", userId],
		queryFn: async () => {
			const { data } = await supabase
				.from("user_achievements")
				.select("*")
				.eq("user_id", userId)
			return data
		},
		enabled: !!userId,
	})

	useEffect(() => {
		achievements?.forEach(function (achievement) {
			checkAndCreateUserAchievement(achievement.id, userId)
		})
	}, [achievements, userId])

	useEffect(() => {
		user_achievements?.forEach(function (userAchievement) {
			let id = userAchievement.id
			let achievementId = userAchievement.achievement_id
			let completed = userAchievement.completed
			let target = achievements?.find((a) => a.id === achievementId).target || 0
			let progress = userAchievement.progress
			let type = achievements?.find((a) => a.id === achievementId).type || null
			checkAchievementProgress(
				id,
				achievementId,
				user,
				completed,
				target,
				progress,
				type
			)
		})
	}, [user_achievements, achievements, user])

	// TOGGLE WINE MODE
	const storedIsWine = localStorage.getItem(CURRENT_MODE_STORAGE_KEY)
	const initialIsWine = storedIsWine ? JSON.parse(storedIsWine) : false
	const [isWine, setIsWine] = useState(initialIsWine)

	useEffect(() => {
		localStorage.setItem(CURRENT_MODE_STORAGE_KEY, JSON.stringify(isWine))
		document.body.classList.toggle("wine-mode", isWine)

		return () => {
			document.body.classList.remove("wine-mode")
		}
	}, [isWine])

	return (
		<div className={`site-container`}>
			{!session && modal ? (
				<div className="not-logged-modal">
					<div className="modal">
						<h3>Please log in!</h3>
						<div
							className="button"
							onClick={() => navigate(ROUTES.LANDING_PAGE)}>
							Log in
						</div>
					</div>
				</div>
			) : (
				""
			)}
			<GlobalLoadingIndicator />
			<div className="main-content">
				<Outlet
					context={{
						user: {
							...user,
							friends,
							followers,
							favourite_locations,
							favourite_drinks,
							visits: userVisits,
							coordinates: userCoordinates,
						},
						setIsWine,
						isWine,
						loading,
						notifications,
					}}
				/>
			</div>
			<FooterNav user={user} />
		</div>
	)
}
