import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import AvatarIcon from "../../components/AvatarIcon"
import { supabase } from "../../lib/supabaseClient"
import * as ROUTES from "../../constants/routes"
import executeQuery from "../../helpers/executeQuery"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import ResultUser from "../../components/explore/ResultUser"

export default function AddProfilesPage() {
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { user, currentVisit } = useOutletContext()
	let userId = user?.user_id || null

	// Handle search
	const [query, setQuery] = useState("")
	const [searchResults, setSearchResults] = useState([])
	const [selectedAttendees, setSelectedAttendees] = useState([])
	const [selectedAttendeesIds, setSelectedAttendeesIds] = useState([])

	useEffect(() => {
		let attendees = currentVisit?.attendees || []
		let formattedAttendees = []
		let attendeesIds = []
		if (attendees.length > 0) {
			formattedAttendees = attendees.map((item) => ({
				id: item.id,
				user_id: item.profile.user_id,
				f_name: item.profile.f_name,
				l_name: item.profile.l_name,
				username: item.profile.username,
				avatar: item.profile.avatar,
			}))
			attendeesIds = formattedAttendees.map((item) => item?.user_id)
		}
		setSelectedAttendees(formattedAttendees)
		setSelectedAttendeesIds(attendeesIds)
	}, [currentVisit])

	useEffect(() => {
		const handleSearch = async () => {
			const { data } = await executeQuery(
				supabase
					.from("profiles")
					.select(`username, avatar, f_name, l_name, user_id`)
					.or(
						`username.ilike.%${query}%,f_name.ilike.%${query}%,l_name.ilike.%${query}%`
					)
					.neq("user_id", userId)
					.not("user_id", "in", `(${selectedAttendeesIds})`)
					.limit(5)
			)
			setSearchResults(data)
		}

		if (query.trim() === "") {
			setSearchResults([])
			return
		}
		handleSearch()
	}, [query, userId, selectedAttendeesIds])

	function handleOnClick(result) {
		let updatedSelectedAttendees = [...selectedAttendees, result]
		updatedSelectedAttendees = [...new Set(updatedSelectedAttendees)]
		let updatedSelectedAttendeesIds = [...selectedAttendeesIds, result.user_id]
		updatedSelectedAttendeesIds = [...new Set(updatedSelectedAttendeesIds)]
		setQuery("")
		setSelectedAttendees(updatedSelectedAttendees)
		setSelectedAttendeesIds(updatedSelectedAttendeesIds)
		setSearchResults([])
	}

	async function removeOldAttendees(visit_id) {
		const { error } = executeQuery(
			await supabase
				.from("user_visit_attendees")
				.delete()
				.eq("user_visit_id", visit_id)
		)
		if (error) console.error(error)
	}

	const addNewAttendees = useMutation({
		mutationFn: async (arr) => {
			const { data, error } = await supabase
				.from("user_visit_attendees")
				.insert(arr)
				.select()
			if (error) console.error(error)
			else return data
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["current_visit", userId])
		},
	})

	// update session
	async function updateVisit(visit_id, attendees) {
		// 1. Remove old attendee visits
		await removeOldAttendees(visit_id)

		// 2. Add new attendee visits
		let arr = []
		if (attendees?.length > 0) {
			arr = attendees?.map((item) => ({
				user_id: item,
				user_visit_id: visit_id,
			}))
		}
		addNewAttendees.mutate(arr)
	}

	async function handleOnSubmit(e) {
		e.preventDefault()

		try {
			if (!currentVisit) {
				throw new Error("Error")
			}
			await updateVisit(currentVisit.id, selectedAttendeesIds)
			navigate(ROUTES.DASHBOARD)
		} catch (error) {
			console.error(error.message)
		}
	}

	function handleReturn() {
		navigate(ROUTES.DASHBOARD)
	}

	function removeSelectedAttendee(id) {
		setSelectedAttendees(selectedAttendees.filter((a) => a?.user_id !== id))
		setSelectedAttendeesIds(selectedAttendeesIds.filter((a) => a !== id))
	}

	return (
		<>
			<div className="page-dashboard">
				<div className="page-container">
					<main>
						<div>
							<form method="post" onSubmit={handleOnSubmit}>
								<p>
									<FontAwesomeIcon
										icon={`arrow-left`}
										style={{ cursor: "pointer" }}
										onClick={() => handleReturn()}
									/>
								</p>
								<h3>Are other users there?</h3>
								<fieldset>
									<label>Selected attendees:</label>
									{selectedAttendees.length > 0 ? (
										<div className="selected-attendees">
											{selectedAttendees.map((selectedAttendee, i) => (
												<div className={`selected-attendee`} key={i}>
													<div
														className="remove"
														onClick={() =>
															removeSelectedAttendee(selectedAttendee?.user_id)
														}>
														<FontAwesomeIcon icon={`times`} />
													</div>
													<AvatarIcon
														url={selectedAttendee?.avatar}
														size={50}
													/>
												</div>
											))}
										</div>
									) : (
										<p>0 users selected</p>
									)}
									<div className="search-bar">
										<button>
											<FontAwesomeIcon icon={`fas fa-search`} />
										</button>
										<input
											autoComplete="off"
											className="input"
											placeholder={`Search for users`}
											aria-label={`placeholder`}
											type="text"
											name="attendee"
											value={query || ""}
											onChange={(e) => setQuery(e.target.value)}
										/>
										<button
											onClick={(e) => {
												e.preventDefault()
												setQuery("")
											}}>
											<FontAwesomeIcon icon={`times`} />
										</button>
									</div>
									<div className="search-bar-results">
										{searchResults.length > 0 && (
											<ul>
												{searchResults.map((result, i) => (
													<ResultUser
														key={result.user_id}
														user={result}
														handleOnClick={handleOnClick}
													/>
												))}
											</ul>
										)}
									</div>
								</fieldset>
								<fieldset>
									<button className="button">
										{selectedAttendees.length > 0 ? "Submit" : "No"}
									</button>
								</fieldset>
							</form>
						</div>
					</main>
				</div>
			</div>
		</>
	)
}
