import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"

export default function LikeButton({
	icon = true,
	number = true,
	count = 0,
	isLiked,
}) {
	const [motion, setMotion] = useState(false)
	const [motionOut, setMotionOut] = useState(false)

	useEffect(() => {
		handleOnCountChange()
	}, [count])

	function handleOnCountChange() {
		setMotion(true)
		setTimeout(() => {
			setMotion(false)
			setMotionOut(true)
		}, 150)
		setTimeout(() => {
			setMotionOut(false)
		}, 300)
	}

	return (
		<div className={`like-button ${isLiked ? "active" : ""}`}>
			{icon && (
				<div className={`icon ${motion ? "count-motion" : ""}`}>
					<FontAwesomeIcon icon="heart" />
				</div>
			)}
			{number && (
				<div className="number">
					<div
						className={`count ${motion ? "count-motion" : ""}  ${
							motionOut ? "count-motion-out" : ""
						}`}>
						{count}
					</div>
				</div>
			)}
		</div>
	)
}
