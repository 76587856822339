import React, { useState } from "react"
import DateNav from "../../components/profile/DateNav"
import Stats from "../../components/profile/Stats"
import { useSession } from "../../context/SessionContext"
import { ShibaIcon } from "../../components/ShibaCount"
import { useQuery } from "@tanstack/react-query"
import { supabase } from "../../lib/supabaseClient"
import NumberAnimation from "../../components/NumberAnimation"
import { useSwipeable } from "react-swipeable"
import { addDays, isToday, subDays } from "date-fns"
import Achievements from "../../components/achievements/Achievements"

export default function MePage() {
	const { session } = useSession()
	let userId = session?.user?.id || null

	// GET TODAY
	let today = new Date()
	today = today.getTime()
	const [currentDate, setCurrentDate] = useState(today)

	const { data: count } = useQuery({
		queryKey: ["shibas-spotted", userId],
		queryFn: async () => {
			const { data } = await supabase
				.from("shibas")
				.select("id, count")
				.eq("user_id", userId)
			let totalCount = 0

			if (data) {
				for (const item of data) {
					totalCount += item.count
				}
			}
			return totalCount
		},
		enabled: !!userId,
	})

	const [swiping, setSwiping] = useState(false)

	const handlers = useSwipeable({
		onSwipeStart: (e) => {
			if (e.dir === "Left") {
				setSwiping("left")
			}
			if (e.dir === "Right") {
				setSwiping("right")
			}
		},
		onSwiped: (e) => {
			setSwiping(false)
			if (e.dir === "Left") {
				if (isToday(currentDate)) return
				let day = currentDate
				let nextDay = addDays(day, 1)
				setCurrentDate(nextDay)
			}
			if (e.dir === "Right") {
				let day = currentDate
				let prevDay = subDays(day, 1)
				setCurrentDate(prevDay)
			}
		},
	})

	return (
		<>
			<section id="todays-totals-section" {...handlers}>
				<DateNav
					currentDate={currentDate}
					setCurrentDate={setCurrentDate}
					today={today}
				/>
				<div className={`swiping ${swiping ? swiping : "not"}-swiping`}>
					<Stats
						userId={userId}
						total={false}
						currentDate={currentDate}
						comments={true}
					/>
				</div>
				<div className="warning">
					Maximum recommended per day: <strong>4 units</strong>
				</div>
			</section>
			<section id="todays-totals-section">
				<nav className="total-title-bar">
					<div className="title">Total</div>
				</nav>
				<Stats userId={userId} total={true} comments={true} />
			</section>
			<section id="spotted-shibas">
				<div className="spotted-shibas">
					<ShibaIcon button={false} />
					<div className="count">
						<strong>
							<NumberAnimation number={count || 0} />
						</strong>{" "}
						spotted Shibas
					</div>
				</div>
			</section>
			<section id="achievements-section">
				<h2 className="h2">Achievements</h2>
				<Achievements userId={userId} achieved={true} />
			</section>
		</>
	)
}
