import React, { useEffect } from "react"
import { Outlet, useOutletContext, useParams } from "react-router-dom"
import { APP_NAME } from "../.."
import { supabase } from "../../lib/supabaseClient"
import { useQuery } from "@tanstack/react-query"

export default function ProfileLayout() {
	const { username } = useParams()
	const { user } = useOutletContext()
	const userFriends = user?.friends || []

	const { isLoading: loadingProfile, data: profile } = useQuery({
		queryKey: ["profile", username],
		queryFn: async () => {
			const { data, error } = await supabase
				.from("profiles")
				.select(
					"avatar, f_name, l_name, username, user_id, created_at, current_visit_id(id, timestamp, location: location_id(name, id))"
				)
				.eq("username", username)
				.single()
			if (error) {
				console.log(error)
			} else {
				return data
			}
		},
		enabled: !!username,
	})

	const { data: friends } = useQuery({
		queryKey: ["friends", username],
		queryFn: async () => {
			const { data, error } = await supabase
				.from("friends")
				.select(
					"user_id: friend_id, created_at, user: friend_id(user_id, f_name, l_name, avatar, username)"
				)
				.eq("user_id", profile.user_id)
			if (error) {
				console.log(error)
			} else {
				return data
			}
		},
		enabled: !!profile,
	})

	const { isLoading: loadingFollowers, data: followers } = useQuery({
		queryKey: ["followers", username],
		queryFn: async () => {
			const { data, error } = await supabase
				.from("friends")
				.select(
					"user_id: friend_id, created_at, user: friend_id(user_id, f_name, l_name, avatar, username)"
				)
				.eq("friend_id", profile.user_id)
			if (error) {
				console.log(error)
			} else {
				return data
			}
		},
		enabled: !!profile,
	})

	const { isLoading: loadingLocations, data: favourite_locations } = useQuery({
		queryKey: ["favourite_locations", username],
		queryFn: async () => {
			const { data, error } = await supabase
				.from("favourite_locations")
				.select("location:location_id(name, id, address, location_types(name))")
				.eq("user_id", profile.user_id)
			if (error) {
				console.log(error)
			} else {
				return data
			}
		},
		enabled: !!profile,
	})

	const { isLoading: loadingDrinks, data: favourite_drinks } = useQuery({
		queryKey: ["favourite_drinks", username],
		queryFn: async () => {
			const { data, error } = await supabase
				.from("favourite_drinks")
				.select(
					"drink:drink_id(name, id, type, drink_subtypes (name,drink_type (	name,	id)), alcohol_percentage)"
				)
				.eq("user_id", profile.user_id)

			if (error) {
				console.log(error)
			} else {
				return data
			}
		},
		enabled: !!profile,
	})

	useEffect(() => {
		document.title = `@${username} • ${APP_NAME}`
	}, [username])

	return (
		<div className="profile-page">
			{
				<Outlet
					context={{
						user,
						username,
						userFriends,
						profile,
						loadingProfile,
						friends,
						followers,
						loadingFollowers,
						favourite_locations,
						loadingLocations,
						favourite_drinks,
						loadingDrinks,
					}}
				/>
			}
		</div>
	)
}
