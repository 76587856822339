import { useState } from "react"
import { addMinutes, formatRelative } from "date-fns"
import { calculateUnits } from "../../helpers/calculateUnits"

export default function CalculatorPage() {
	// const [gender, setGender] = useState(0)
	// const [age, setAge] = useState(0)
	// const [height, setHeight] = useState(0)
	// const [weight, setWeight] = useState(0)

	// const [type, setType] = useState(0)
	const [quantity, setQuantity] = useState("")
	const [abv, setAbv] = useState("")

	// const [time, setTime] = useState(0)

	const [units, setUnits] = useState(0)

	return (
		<>
			<div className="calculator-page">
				<div className="calculator-nav">
					<div className="title">Units Calculator</div>
				</div>
				<div className="container">
					<form
						className="form"
						onSubmit={(e) => {
							e.preventDefault()
							setUnits(calculateUnits(quantity, abv))
						}}>
						{/* <section id="meta">
							<h3>Personal info</h3>
							<fieldset className="fieldset">
								<div className="row" style={{ gap: 30 }}>
									<div className="amount-field">
										<label className="label">
											Gender
											<input
												id="gender"
												type="text"
												placeholder="Male"
												value={gender}
												onChange={(e) => {
													setGender(e.target.value)
												}}
												autoComplete="off"
											/>
										</label>
									</div>
									<div className="amount-field">
										<label className="label">
											Age
											<div className="input-group">
												<input
													id="age"
													type="number"
													placeholder="0"
													min={0}
													value={age}
													onChange={(e) => {
														setAge(e.target.value)
													}}
													autoComplete="off"
												/>
												<div className="unit">yrs</div>
											</div>
										</label>
									</div>
								</div>
							</fieldset>

							<fieldset className="fieldset">
								<div className="row" style={{ gap: 30 }}>
									<div className="amount-field">
										<label className="label">
											Height
											<div className="input-group">
												<input
													id="height"
													type="number"
													placeholder="0"
													min={0}
													value={height}
													onChange={(e) => {
														setHeight(e.target.value)
													}}
													autoComplete="off"
												/>
												<div className="unit">cm</div>
											</div>
										</label>
									</div>
									<div className="amount-field">
										<label className="label">
											Weight
											<div className="input-group">
												<input
													id="weight"
													type="number"
													placeholder="0"
													min={0}
													value={weight}
													onChange={(e) => {
														setWeight(e.target.value)
													}}
													autoComplete="off"
												/>
												<div className="unit">Kg</div>
											</div>
										</label>
									</div>
								</div>
							</fieldset>
						</section> */}

						<section id="drink">
							{/* <Fieldset
								type="number"
								label="type"
								field={type}
								setField={setType}
							/> */}
							<fieldset>
								<div className="row" style={{ gap: 50 }}>
									<div className="amount-field">
										<label className="label">
											Quantity
											<div className="input-group">
												<input
													id="quantity"
													type="number"
													placeholder="0"
													min={0}
													value={quantity}
													onChange={(e) => {
														setQuantity(e.target.value)
													}}
													autoComplete="off"
												/>
												<div className="unit">ml</div>
											</div>
										</label>
									</div>
									<div className="amount-field">
										<label className="label">
											ABV
											<div className="input-group">
												<input
													id="abv"
													type="number"
													placeholder="0"
													step={0.1}
													min={0}
													max={100}
													value={abv}
													onChange={(e) => {
														setAbv(e.target.value)
													}}
													autoComplete="off"
												/>
												<div className="unit">%</div>
											</div>
										</label>
									</div>
								</div>
							</fieldset>
						</section>
						{/* <section id="time">
							<fieldset className="fieldset">
								<div className="row" style={{ gap: 30 }}>
									<div>
										<label className="label">
											Time
											<div className="input-group">
												<Fieldset
													id="time"
													type="datetime-local"
													field={time}
													setField={setTime}
													autoComplete="off"
												/>
											</div>
										</label>
									</div>
									<div>
										<button>Now</button>
									</div>
								</div>
							</fieldset>
						</section> */}
						<button className="button">Calculate</button>
					</form>
					{!units || (
						<div className="result">
							<div className="result-inner">
								<h3>Results</h3>
								<p className="units">
									units:
									<span>{units}</span>
								</p>

								<p className="time">
									in blood until:
									<span>
										{units &&
											formatRelative(
												addMinutes(new Date(), units * 60),
												new Date(),
												{
													// addSuffix: true,
												}
											)}
									</span>
								</p>
							</div>
						</div>
					)}

					{/* Cal */}
				</div>
			</div>
		</>
	)
}
