import React, { useEffect, useState, useRef, Fragment } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import ResultPlace from "../../components/explore/ResultPlace"
import SearchBar from "../../components/explore/SearchBar"
import * as ROUTES from "../../constants/routes"
import Spinner from "../../components/Spinner"

export default function ListPageLocations() {
	const navigate = useNavigate()
	const focusRef = useRef()
	const { name, favourite_locations, loadingLocations } = useOutletContext()
	let locations = favourite_locations?.map((item) => item.location) || []

	const [query, setQuery] = useState("")
	const [filteredList, setFilteredList] = useState(locations)

	useEffect(() => {
		if (query === "") {
			setFilteredList(locations)
		}
		const filtered = locations.filter((item) => {
			const searchTerm = query.toLowerCase()
			return (
				item.name.toLowerCase().includes(searchTerm) ||
				item.address.toLowerCase().includes(searchTerm) ||
				item.location_types.name.toLowerCase().includes(searchTerm)
			)
		})
		setFilteredList(filtered)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query, loadingLocations])

	function handleOnClick(item) {
		navigate(`${ROUTES.LOCATION}/${item?.id}`)
	}

	return (
		<>
			{filteredList.length > 4 && (
				<SearchBar focusRef={focusRef} query={query} setQuery={setQuery} />
			)}
			<section className="list-section" id="favourite-locations">
				<h3 className="list-title">{name} favourite locations</h3>
				{loadingLocations ? (
					<Spinner />
				) : filteredList.length > 0 ? (
					<ul className="list">
						{filteredList.map((item) => (
							<ResultPlace
								key={item.id}
								place={item}
								handleOnClick={handleOnClick}
							/>
						))}
					</ul>
				) : (
					<div className="no-result">No favourite locations</div>
				)}
			</section>
			{/* <section className="list-section" id="visited-locations">
				<h3 className="list-title">Visited Locations</h3>
				{loadingLocations ? (
					<Spinner />
				) : filteredList.length > 0 ? (
					<ul className="list">
						{filteredList.map((item) => (
							<ResultPlace
								key={item.id}
								place={item}
								handleOnClick={handleOnClick}
							/>
						))}
					</ul>
				) : (
					<div className="no-result">No results</div>
				)}
			</section> */}
		</>
	)
}
