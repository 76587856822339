import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { supabase } from "../../lib/supabaseClient"
import toTitleCase from "../../helpers/toTitleCase"
import Select from "react-select"
import { format } from "date-fns"
import { useQuery } from "@tanstack/react-query"
import Fieldset from "../../components/Fieldset"

export default function NewDrinkPage() {
	const navigate = useNavigate()

	const [alcoholPercentage, setAlcoholPercentage] = useState("")
	const [alcoholPercentageError, setAlcoholPercentageError] = useState(false)

	const [name, setName] = useState("")
	const [nameError, setNameError] = useState(false)

	const [selectedCountry, setSelectedCountry] = useState(null)
	const [countryError, setCountryError] = useState(false)

	const [selectedType, setSelectedType] = useState(null)
	const [typeError, setTypeError] = useState(false)

	function handleOnSubmit(e) {
		e.preventDefault()
		if (name === "") {
			setNameError("Name is required")
			return
		}

		if (alcoholPercentage === "") {
			setAlcoholPercentageError(true)
			return
		}
		if (selectedCountry === null) {
			setCountryError(true)
			return
		}
		if (selectedType === null) {
			setTypeError(true)
			return
		}

		let currentTimestamp = format(new Date(), "yyyy-MM-dd HH:mm:ssXXX")

		let newDrink = {
			alcohol_percentage: alcoholPercentage,
			country: selectedCountry.value,
			name: name ? toTitleCase(name) : "",
			type: selectedType.value,
			updated_at: currentTimestamp,
		}

		updateDatabase(newDrink)

		navigate(-1)
	}

	const { data: countries } = useQuery({
		queryKey: ["countries"],
		queryFn: async () => {
			const { data } = await supabase
				.from("countries")
				.select("name_en, id")
				.order("name_en", { ascending: true })
			return data
		},
		refetchOnWindowFocus: false,
	})

	const handleCountryChange = (selectedOption) => {
		setCountryError(false)
		setSelectedCountry(selectedOption)
	}

	const { data: types } = useQuery({
		queryKey: ["drink_subtypes"],
		queryFn: async () => {
			const { data } = await supabase
				.from("drink_subtypes")
				.select("name, id")
				.order("name", { ascending: true })
			return data
		},
		refetchOnWindowFocus: false,
	})

	const handleTypeChange = (selectedOption) => {
		setTypeError(false)
		setSelectedType(selectedOption)
	}

	async function updateDatabase(newDrink) {
		const { error } = await supabase.from("drinks").insert(newDrink)
		if (error) {
			console.error(error)
		}
	}

	function handleReturn() {
		navigate(-1)
	}

	return (
		<div className="page-dashboard">
			<div className="page-container">
				<main>
					<div>
						<form onSubmit={handleOnSubmit}>
							<p>
								<FontAwesomeIcon
									icon={`arrow-left`}
									style={{ cursor: "pointer" }}
									onClick={() => handleReturn()}
								/>
							</p>
							<h3>New drink</h3>
							<Fieldset
								id="name"
								label="Drink name"
								field={name}
								setField={setName}
								error={nameError}
								setError={setNameError}
								autocomplete="off"
							/>

							<fieldset className="fieldset">
								<label className="label">
									Select origin country:
									<Select
										className="react-select-container"
										classNamePrefix="react-select"
										value={selectedCountry}
										onChange={handleCountryChange}
										theme={(theme) => ({
											...theme,
											borderRadius: 0,
											colors: {
												...theme.colors,
												primary25: "rgba(34, 34, 34, 0.25)",
												primary50: "rgba(34, 34, 34, 0.5)",
												primary: "#222222",
											},
										})}
										options={countries?.map((country) => ({
											label: country.name_en,
											value: country.id,
										}))}
									/>
								</label>
								{countryError && (
									<p className="error">Please select a country</p>
								)}
							</fieldset>

							<fieldset className="fieldset">
								<label className="label">
									Select type:
									<Select
										className="react-select-container"
										classNamePrefix="react-select"
										value={selectedType}
										onChange={handleTypeChange}
										theme={(theme) => ({
											...theme,
											borderRadius: 0,
											colors: {
												...theme.colors,
												primary25: "rgba(34, 34, 34, 0.25)",
												primary50: "rgba(34, 34, 34, 0.5)",
												primary: "#222222",
											},
										})}
										options={types?.map((type) => ({
											label: type.name,
											value: type.id,
										}))}
									/>
								</label>
								{typeError && (
									<p className="error">Please select a drink type</p>
								)}
							</fieldset>
							<fieldset className="fieldset">
								<div className="amount-field">
									<label htmlFor="percentage">Alcohol percentage</label>
									<div className="input-group">
										<input
											id="percentage"
											type="number"
											placeholder="0.0"
											step="0.1"
											min={0}
											max={100}
											value={alcoholPercentage || ""}
											onChange={(e) => {
												setAlcoholPercentageError(false)
												setAlcoholPercentage(e.target.value)
											}}
											autoComplete="off"
										/>
										<div className="unit">%</div>
									</div>
								</div>
								{alcoholPercentageError && (
									<p className="error">Please indicate the percentage</p>
								)}
							</fieldset>
							<fieldset>
								<button className="button">Submit</button>
							</fieldset>
						</form>
					</div>
				</main>
			</div>
		</div>
	)
}
