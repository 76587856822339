import { Link } from "react-router-dom"
import * as ROUTES from "../../constants/routes"
import { format } from "date-fns"

export default function Drink({ drink }) {
	if (!drink) return
	return (
		<li className="drink">
			{drink.timestamp && (
				<span className="time">
					{format(new Date(drink.timestamp), "HH:mm")}
				</span>
			)}
			{"•"}

			<span className="title">
				<Link to={`${ROUTES.DRINK}/${drink.drink_id}`}>{drink.name}</Link>
			</span>

			{"•"}
			<span className="description">{drink.ml}ml</span>

			{"•"}
			<span className="description">{drink.units} units</span>
		</li>
	)
}
