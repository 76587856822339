import React, { useEffect } from "react"
import { Outlet, useOutletContext } from "react-router-dom"
import { APP_NAME } from "../.."
import { supabase } from "../../lib/supabaseClient"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { isPast, parseISO } from "date-fns"
// import { findNearest, getDistance } from "geolib"

export default function DashboardLayout() {
	const queryClient = useQueryClient()
	const { user, setIsWine, isWine } = useOutletContext()
	let userId = user?.user_id || null

	const { data, isLoading } = useQuery({
		queryKey: ["current_visit", userId],
		queryFn: async () => {
			const { data, error } = await supabase
				.from("profiles")
				.select(
					"current_visit:current_visit_id(id, expires, timestamp, location:location_id(id, address, name, type(id, name)), drinks:user_visit_drinks(id, ml, timestamp, type, drink:drink_id(id, alcohol_percentage, country(name_en), name, type(id, name))), attendees:user_visit_attendees(id, profile:profiles(user_id, f_name, l_name, username, avatar)))"
				)
				.eq("user_id", userId)
				.single()
			if (error) console.error(error)
			return data
		},
		enabled: !!userId,
	})

	const updateProfile = useMutation({
		mutationFn: async () => {
			if (!isOutdated) return
			const { data } = await supabase
				.from("profiles")
				.update({ current_visit_id: null })
				.eq("id", userId)
				.select()
			return data
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["current_visit", userId])
		},
	})

	// CURRENT VISIT
	let currentVisit = data?.current_visit || null
	let isOutdated = false
	if (currentVisit) {
		const givenDate = parseISO(currentVisit.expires)
		isOutdated = isPast(givenDate)
		currentVisit = isOutdated ? null : currentVisit
	}

	useEffect(() => {
		async function removeCurrentVisitId() {
			if (userId) updateProfile.mutate()
		}
		if (currentVisit) {
			if (isOutdated) {
				removeCurrentVisitId()
			}
		}
	}, [currentVisit, isOutdated, userId, updateProfile])

	useEffect(() => {
		document.title = `Dashboard • ${APP_NAME}`
	}, [])

	return (
		<>
			<Outlet context={{ user, setIsWine, isWine, currentVisit, isLoading }} />
		</>
	)
}
