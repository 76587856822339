import { useQuery } from "@tanstack/react-query"
import React, { useState } from "react"
import { supabase } from "../lib/supabaseClient"
import { Link } from "react-router-dom"
import * as ROUTES from "../constants/routes"

export default function RandomLocation() {
	const [pressed, setPressed] = useState(false)
	const [result, setResult] = useState(false)
	const [loading, setLoading] = useState(false)

	// fetch locations
	const { data: locations } = useQuery({
		queryKey: ["locations"],
		queryFn: async () => {
			const { data, error } = await supabase
				.from("locations")
				.select("id, name")
				.neq("id", "25")
				.neq("type", "14")
			if (error) console.error(error)
			return data
		},
	})

	const chooseRandomLocation = () => {
		setLoading(true)
		// Generate a random index within the array length
		const randomIndex = Math.floor(Math.random() * locations.length)

		// Get the random item at the chosen index
		const randomItem = locations[randomIndex]

		// Update the state with the chosen item
		setResult(randomItem)
		setLoading(false)
	}

	return (
		<div className="dice-modal">
			<h4 className="title">Can't decide where to have a drink next?</h4>
			<div className="dice-result">
				{!loading ? (
					result ? (
						<div>
							<Link to={`${ROUTES.LOCATION}/${result.id}`}>{result.name}</Link>
						</div>
					) : (
						<div className="placeholder">Click below to find a place to go</div>
					)
				) : (
					"searching..."
				)}
			</div>
			<div className={`dice-button ${pressed && "pressed"}`}>
				<div
					className="dice-icon"
					onPointerDown={() => setPressed(true)}
					onPointerUp={() => setPressed(false)}
					onClick={chooseRandomLocation}
				/>
			</div>
		</div>
	)
}
