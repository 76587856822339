import { useState } from "react"
import { supabase } from "../../lib/supabaseClient"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useQuery } from "@tanstack/react-query"
import { formatDistance } from "date-fns"

function AchievementBadge({ completed }) {
	return (
		<div className="badgeW">
			<div className="shadow" />
			<div className="badge">
				<div className="icon">
					<FontAwesomeIcon icon={completed ? `check` : `trophy`} />
				</div>
			</div>
		</div>
	)
}

function Achievement({ achievement, achieved }) {
	let achieved_at = achievement ? achievement.achieved_at : null

	let id = achievement?.achievement?.id
	let name = achievement?.achievement?.name
	let criteria = achievement?.achievement?.criteria
	let progress = achievement ? achievement.progress : 0
	let target = achievement ? achievement?.achievement?.target : 0
	let completed = achievement ? achievement?.completed : false
	let percentage = 0
	if (progress && target) {
		percentage = (progress / target) * 100
	}
	const [modal, setModal] = useState(false)

	return (
		<div className="achievement" key={id}>
			{!achieved
				? modal && (
						<div className="modal-container" onClick={() => setModal(!modal)}>
							<div className="close">
								<FontAwesomeIcon icon="times" />
							</div>

							<div className="modal">
								<div className="achievement-container">
									<AchievementBadge completed={completed} />
									<div className="meta">
										<div className="header">
											<div className="title">{name}</div>
											<div className="points">
												{progress}/{target}
											</div>
										</div>
										<div className="progress-bar">
											<div
												className="progress"
												style={{ width: `${percentage}%` }}
											/>
										</div>
										<div className="criteria">{criteria}</div>
										{achieved_at && (
											<div className="date">
												{`Achieved 
												${formatDistance(new Date(achieved_at), new Date(), {
													addSuffix: true,
												})}`}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
				  )
				: ""}
			<div className="achievement-container" onClick={() => setModal(!modal)}>
				<AchievementBadge completed={completed} />
				{!achieved ? (
					<div className="meta">
						<div className="header">
							<div className="title">{name}</div>
							<div className="points">
								{progress}/{target}
							</div>
						</div>
						<div className="progress-bar">
							<div className="progress" style={{ width: `${percentage}%` }} />
						</div>
						<div className="criteria">{criteria}</div>
					</div>
				) : (
					<div className="title">{name}</div>
				)}
			</div>
		</div>
	)
}

export default function Achievements({ userId, achieved }) {
	let query = !achieved
		? "*, achievement:achievements(name, description, criteria, target, id)"
		: "id, achievement:achievements(name, id)"
	const { isLoading, data: achievements } = useQuery({
		queryKey: ["achievements", userId],
		queryFn: async () => {
			const { data } = await supabase
				.from("user_achievements")
				.select(query)
				.eq("user_id", userId)
			return data
		},
		enabled: !!userId,
	})

	return (
		<>
			{isLoading ? (
				"loading..."
			) : achievements && achievements.length > 0 ? (
				<div className={`achievements ${achieved ? "achieved" : ""}`}>
					{achievements.map((achievement, i) => (
						<Achievement
							key={achievement?.id || i}
							achievement={achievement}
							achieved={achieved}
						/>
					))}
				</div>
			) : (
				<p>No achievements yet...</p>
			)}
		</>
	)
}
