import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { useSwipeable } from "react-swipeable"
import * as ROUTES from "../../constants/routes"

export function Notification({
	notification,
	focused,
	setFocused,
	focusedForRemove,
	setFocusedForRemove,
	selectedIds,
	setSelectedIds,
	notifications,
	setNotifications,
}) {
	let icon = "bell"
	switch (notification?.type) {
		case "achievement":
			icon = "trophy"
			break
		case "drink":
			icon = "beer"
			break
		case "event":
			icon = "calendar"
			break
		case "friend":
			icon = "user-plus"
			break
		case "location":
			icon = "location-dot"
			break
		default:
			icon = "bell"
	}

	const [checked, setChecked] = useState(false)

	useEffect(() => {
		if (selectedIds.includes(notification.id)) {
			setChecked(true)
		} else {
			setChecked(false)
		}
	}, [selectedIds, notification])

	const handleOnClick = (e) => {
		if (focusedForRemove) {
			setFocusedForRemove(null)
			return
		}
		setFocused(true)
	}
	const handlers = useSwipeable({
		onSwiped: (e) => {
			if (e.dir === "Left") {
				if (focused) return
				setFocusedForRemove(notification.id)
			}
			if (e.dir === "Right") {
				setFocusedForRemove(false)
			}
		},
	})

	function handleOnSelect() {
		if (checked) {
			setChecked(false)
			let filteredIds = selectedIds.filter((item) => item !== notification.id)
			setSelectedIds(filteredIds)
		} else {
			setChecked(true)
			let filteredIds = [...selectedIds, notification.id]
			setSelectedIds(filteredIds)
		}
	}

	function handleOnDelete() {
		let filteredNotifications = notifications.filter(
			(item) => ![notification.id].includes(item.id)
		)
		setNotifications(filteredNotifications)
	}

	return (
		<div
			className={`notification-w ${
				focused || focusedForRemove === notification.id ? `selected` : ""
			} ${focusedForRemove === notification.id ? `-remove` : ""} ${
				focused ? `-check` : ""
			}`}
			{...handlers}>
			<div className="notification" onClick={handleOnClick}>
				<div className="notification-icon-w">
					<div className="notification-icon">
						<FontAwesomeIcon icon={icon} />
					</div>
				</div>
				<div className="notification-description">
					<div className="notification-header">
						<h3 className="notification-title">{notification.title}</h3>
						<div className="notification-date">15 Jun</div>
					</div>
					<div className="notification-body">
						<div className="notification-text">
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam
							ipsam eum maxime vel.
						</div>
					</div>
				</div>
			</div>
			<div className="notification-action-w">
				<div className="notification-action remove" onClick={handleOnDelete}>
					<FontAwesomeIcon icon="trash" />
				</div>
				<div className="notification-action check" onClick={handleOnSelect}>
					<FontAwesomeIcon icon={checked ? "square-check" : "square"} />
				</div>
			</div>
		</div>
	)
}

export function NotificationModal({
	focused,
	handleDelete,
	setFocused,
	setSelectedIds,
}) {
	return (
		<div className={`notification-modal ${focused ? "active" : ""}`}>
			<div className="modal-actions">
				<button className="button" onClick={handleDelete}>
					Delete
				</button>
				<button
					className="button ghost"
					onClick={() => {
						setFocused(false)
						setSelectedIds([])
					}}>
					Cancel
				</button>
			</div>
		</div>
	)
}

export default function NotificationsPage() {
	const navigate = useNavigate()
	const { notifications: data } = useOutletContext()
	const [notifications, setNotifications] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [focused, setFocused] = useState(false)
	const [focusedForRemove, setFocusedForRemove] = useState(null)

	useEffect(() => {
		setNotifications(data)
	}, [data])

	function handleGoBack() {
		navigate(ROUTES.ME)
	}

	function handleDelete(e) {
		e.preventDefault()
		let filteredNotifications = notifications.filter(
			(item) => !selectedIds.includes(item.id)
		)
		setNotifications(filteredNotifications)
		setFocused(false)
	}

	return (
		<>
			<div className="notifications-page">
				<div className="notifications-nav">
					<div className="cancel" onClick={(e) => handleGoBack(e)}>
						<FontAwesomeIcon icon="arrow-left" />
					</div>
					<div className="title">Notifications</div>
				</div>
				<div className="notifications-w">
					{notifications.length > 0 && (
						<div className="notifications">
							{notifications.map((item, i) => (
								<Notification
									key={item.id}
									i={i}
									notification={item}
									focused={focused}
									setFocused={setFocused}
									focusedForRemove={focusedForRemove}
									setFocusedForRemove={setFocusedForRemove}
									selectedIds={selectedIds}
									setSelectedIds={setSelectedIds}
									notifications={notifications}
									setNotifications={setNotifications}
								/>
							))}
							{focused && <div className="modal-spacer" />}
						</div>
					)}
					<NotificationModal
						focused={focused}
						handleDelete={handleDelete}
						setFocused={setFocused}
						setSelectedIds={setSelectedIds}
					/>
				</div>
			</div>
		</>
	)
}
