import React, { useEffect, useState } from "react"
import { supabase } from "../../lib/supabaseClient"
import { useNavigate, useOutletContext } from "react-router-dom"
import AccountsNav from "../../components/accounts/AccountsNav"
import * as ROUTES from "../../constants/routes"
import AvatarIcon from "../../components/AvatarIcon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Fieldset from "../../components/Fieldset"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import executeQuery from "../../helpers/executeQuery"
import { format, subYears } from "date-fns"

export default function EditProfile() {
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const data = useOutletContext()
	let user = data.user || null
	let userId = user?.user_id || null
	let userLoading = data?.loading || false

	const [loading, setLoading] = useState(userLoading)
	const [email, setEmail] = useState(user?.email)
	const [username, setUsername] = useState(user?.username)
	const [firstName, setFirstName] = useState(user?.f_name)
	const [lastName, setLastName] = useState(user?.l_name)
	const [avatar, setAvatar] = useState(user?.avatar)
	const [dob, setDob] = useState(user?.dob)
	const [firstNameError, setFirstNameError] = useState(false)
	const [lastNameError, setLastNameError] = useState(false)

	const images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]

	const handleNext = () => {
		setAvatar((prevIndex) =>
			prevIndex + 1 === images.length + 1 ? 1 : prevIndex + 1
		)
	}
	const handlePrevious = () => {
		setAvatar((prevIndex) =>
			prevIndex - 1 < 1 ? images.length : prevIndex - 1
		)
	}

	useEffect(() => {
		getProfile(user)
	}, [user])

	const getProfile = async (user) => {
		try {
			setLoading(true)

			if (user) {
				setUsername(user.username)
				setEmail(user.email)
				setAvatar(user.avatar)
				setFirstName(user.f_name)
				setLastName(user.l_name)
				setAvatar(user.avatar)
				setDob(user.dob)
			}
		} catch (error) {
			alert(error.message)
		} finally {
			setLoading(false)
		}
	}

	const handleOnSubmit = async (e) => {
		e.preventDefault()

		try {
			setLoading(true)

			const updates = {
				user_id: user.user_id,
				username: user.username,
				email: user.email,
				avatar: avatar,
				f_name: firstName,
				l_name: lastName,
				updated_at: new Date(),
				dob: dob,
			}

			updateMe.mutate(updates)
		} catch (error) {
			alert(error.message)
		} finally {
			setLoading(false)
		}
	}

	//update session
	const updateMe = useMutation({
		mutationFn: async (updates) => {
			const { data } = await executeQuery(
				supabase.from("profiles").update(updates).eq("user_id", userId)
			)
			return data
		},
		onMutate: () => {
			setLoading(true)
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["me", userId])
			setLoading(false)
			// navigate(ROUTES.ME)
		},
	})

	async function signOut() {
		const { error } = await supabase.auth.signOut()
		if (!error) {
			navigate(ROUTES.LANDING_PAGE)
		}
	}

	function handleSignOut(e) {
		e.preventDefault()

		signOut()
	}

	const [formattedDate, setFormattedDate] = useState(
		format(new Date(), "yyyy-MM-dd")
	)
	useEffect(() => {
		const today = new Date()
		const eighteenYearsAgo = subYears(today, 18)
		setFormattedDate(format(eighteenYearsAgo, "yyyy-MM-dd"))
	}, [])

	return (
		<>
			<AccountsNav
				title="Edit Profile"
				onSubmit={handleOnSubmit}
				loading={userLoading}
			/>
			<div className="accounts-page">
				<div className="nav-spacer" />
				<div className="page-container">
					<main>
						<div>
							<form onSubmit={handleOnSubmit} className="form">
								<fieldset style={{ textAlign: "center", marginBottom: 20 }}>
									<div className="update-avatar">
										<div className="avatars">
											<div className="arrow" onClick={() => handlePrevious()}>
												<FontAwesomeIcon icon="angle-left" />
											</div>
											<div className="avatar -selected">
												<AvatarIcon
													centered
													url={avatar}
													size={80}
													loading={userLoading}
												/>
											</div>
											<div className="arrow " onClick={() => handleNext()}>
												<FontAwesomeIcon icon="angle-right" />
											</div>
										</div>
										<div className="text">Select your avatar</div>
									</div>
								</fieldset>
								<div className="row" style={{ gap: 20 }}>
									<div>
										<Fieldset
											id="f_name"
											label="First Name"
											field={firstName}
											setField={setFirstName}
											error={firstNameError}
											setError={setFirstNameError}
											autocapitalize="words"
										/>
									</div>
									<div>
										<Fieldset
											id="l_name"
											label="Last Name"
											field={lastName}
											setField={setLastName}
											error={lastNameError}
											setError={setLastNameError}
											autocapitalize="words"
										/>
									</div>
								</div>

								<Fieldset
									id="dob"
									label="Date of Birth"
									type="date"
									max={formattedDate}
									field={dob}
									setField={setDob}
								/>

								<Fieldset
									id="username"
									label="Username"
									field={username}
									setField={setUsername}
									disabled
									autocomplete="off"
								/>
								<Fieldset
									id="email"
									label="Email"
									type="email"
									field={email}
									setField={setEmail}
									disabled
									autocomplete="off"
								/>
								<fieldset>
									<button className="button">
										{loading ? "Updating..." : "Update"}
									</button>
								</fieldset>
							</form>
							<p style={{ textAlign: "center" }}>
								<button onClick={handleSignOut}>
									<b>Sign out</b>
								</button>
							</p>
						</div>
					</main>
				</div>
			</div>
		</>
	)
}
