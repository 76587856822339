import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function ResultPlace({ place, handleOnClick }) {
	if (!place) return
	return (
		<li onClick={() => handleOnClick(place)}>
			<div className="result" data-type="place">
				<div className="icon">
					<FontAwesomeIcon icon="location-dot" />
				</div>
				<div className="text">
					<div className="name">{place.name}</div>
					<div className="address">{place.address}</div>
					<div className="type">{place.location_types?.name}</div>
				</div>
			</div>
		</li>
	)
}
