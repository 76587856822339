import { addDays, format, getTime, isToday, subDays } from "date-fns"
import React, { useEffect, useState } from "react"
import {
	Outlet,
	useNavigate,
	useOutletContext,
	useParams,
} from "react-router-dom"
import { APP_NAME } from "../.."
import WeekNav from "../../components/profile/WeekNav"
import { useSwipeable } from "react-swipeable"

export default function HistoryPage() {
	const param = useParams()
	const navigate = useNavigate()
	const { user } = useOutletContext()

	// GET TODAY
	const today = new Date().getTime() // Using getTime directly on the new Date()

	let paramDate
	if ("y" in param && "m" in param && "d" in param) {
		paramDate = getTime(new Date(param.y, param.m - 1, param.d))
	} else {
		paramDate = today
	}

	const [currentDate, setCurrentDate] = useState(paramDate)

	async function handleUrl(date) {
		navigate(format(date, "yyyy/MM/dd"), { replace: true })
	}

	const [swiping, setSwiping] = useState(false)

	const handlers = useSwipeable({
		onSwipeStart: (e) => {
			if (e.dir === "Left") {
				setSwiping("left")
			}
			if (e.dir === "Right") {
				setSwiping("right")
			}
		},
		onSwiped: (e) => {
			setSwiping(false)
			if (e.dir === "Right") {
				let day = currentDate
				let prevDay = subDays(day, 1)
				handleUrl(prevDay)
				setCurrentDate(prevDay)
			}
			if (e.dir === "Left") {
				if (isToday(currentDate)) return
				let day = currentDate
				let nextDay = addDays(day, 1)
				handleUrl(nextDay)
				setCurrentDate(nextDay)
			}
		},
	})

	useEffect(() => {
		document.title = `History • ${APP_NAME}`
	}, [])

	return (
		<>
			<div className={`history-page`} {...handlers}>
				<WeekNav
					today={today}
					currentDate={currentDate}
					setCurrentDate={setCurrentDate}
				/>

				<main className={`swiping ${swiping ? swiping : "not"}-swiping`}>
					<div className="visits">
						<Outlet
							context={{ currentDate, totalVisits: user?.visits || [] }}
						/>
					</div>
				</main>
			</div>
		</>
	)
}
