import { Outlet } from "react-router-dom"

export default function SiteRoot() {
	return (
		<>
			<div className="fluid" />
			<Outlet />
		</>
	)
}
