import { useNavigate, useOutletContext } from "react-router-dom"
import ResultDrink from "../../components/explore/ResultDrink"
import * as ROUTES from "../../constants/routes"
import Spinner from "../../components/Spinner"
import { Fragment, useEffect, useRef, useState } from "react"
import SearchBar from "../../components/explore/SearchBar"

export default function ListPageDrinks() {
	const navigate = useNavigate()
	const focusRef = useRef()
	const { name, favourite_drinks, loadingDrinks } = useOutletContext()
	let drinks = favourite_drinks?.map((item) => item.drink) || []

	const [query, setQuery] = useState("")
	const [filteredList, setFilteredList] = useState(drinks)

	useEffect(() => {
		if (query === "") {
			setFilteredList(drinks)
		}
		const filtered = drinks.filter((item) => {
			const searchTerm = query.toLowerCase()
			return item.name.toLowerCase().includes(searchTerm)
		})
		setFilteredList(filtered)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query, loadingDrinks])

	function handleOnClick(item) {
		navigate(`${ROUTES.DRINK}/${item?.id}`)
	}

	return (
		<>
			{filteredList.length > 4 && (
				<SearchBar focusRef={focusRef} query={query} setQuery={setQuery} />
			)}
			<section className="list-section">
				<h3 className="list-title">{name}favourite drinks</h3>
				{loadingDrinks ? (
					<Spinner />
				) : filteredList?.length > 0 ? (
					<ul className="list">
						{filteredList.map((item) => (
							<ResultDrink
								key={item.id}
								drink={item}
								handleOnClick={handleOnClick}
							/>
						))}
					</ul>
				) : (
					<div className="no-result">No favourite drinks</div>
				)}
			</section>

			{/* <section className="list-section">
				<h3 className="list-title">Consumed Drinks</h3>
				{loadingDrinks ? (
					<Spinner />
				) : filteredList.length > 0 ? (
					<ul className="list">
						{filteredList.map((item) => (
							<ResultDrink
								key={item.id}
								drink={item}
								handleOnClick={handleOnClick}
							/>
						))}
					</ul>
				) : (
					<div className="no-result">No results</div>
				)}
			</section> */}
		</>
	)
}
