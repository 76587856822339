import React, { useEffect, useState } from "react"
import { APP_NAME } from "../.."
import { Link } from "react-router-dom"
import * as ROUTES from "../../constants/routes"
import Fieldset from "../../components/Fieldset"
import { isValidPassword, isValidPasswordError } from "../../helpers/formChecks"
import { supabase } from "../../lib/supabaseClient"

export default function UpdatePasswordPage() {
	// const [loading, setLoading] = useState(false)
	const [password, setPassword] = useState("")
	const [passwordError, setPasswordError] = useState(false)

	useEffect(() => {
		document.title = `Update Password • ${APP_NAME}`
	}, [])

	useEffect(() => {
		supabase.auth.onAuthStateChange(async (event, session) => {
			if (event === "PASSWORD_RECOVERY") {
				const newPassword = prompt(
					"What would you like your new password to be?"
				)
				const { data, error } = await supabase.auth.updateUser({
					password: newPassword,
				})

				if (data) alert("Password updated successfully!")
				if (error) alert("There was an error updating your password.")
			}
		})
	}, [])

	function handleOnSubmit(e) {
		e.preventDefault()

		if (isValidPassword(password))
			setPasswordError(isValidPasswordError(password))
	}

	return (
		<>
			<form onSubmit={handleOnSubmit}>
				<h1>Update password</h1>

				<Fieldset
					id="password"
					label="New password"
					type="password"
					field={password}
					setField={setPassword}
					error={passwordError}
					setError={setPasswordError}
					checkFunction={isValidPassword}
					instructions={false}
					autocomplete="off"
				/>

				<fieldset>
					<button className="button">
						"Update password"
						{/* {loading ? "loading" : "Update password"} */}
					</button>
				</fieldset>
			</form>
			<p>
				<Link to={ROUTES.LOGIN}>Back to log in</Link>
			</p>
			<p>
				<Link to={ROUTES.SIGN_UP}>Don't have an account yet? Sign up</Link>
			</p>
		</>
	)
}
