import React, { useEffect, useState } from "react"
import BeersIcon from "./Beers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import WinesIcon from "./Wines"
import { CURRENT_MODE_STORAGE_KEY } from "../../constants/paths"
import Spinner from "../Spinner"
import { supabase } from "../../lib/supabaseClient"
import { format, parseISO } from "date-fns"
import { useQuery } from "@tanstack/react-query"
import { calculateUnits } from "../../helpers/calculateUnits"

const Stat = ({ icon, number, title, loading }) => (
	<div className="stat">
		<div className="icon">
			{loading ? <Spinner white /> : <FontAwesomeIcon icon={icon} />}
		</div>
		<div className="title">{title}</div>
		<div className="number">{loading ? "Loading..." : number}</div>
	</div>
)

const Stats = ({ userId, total, currentDate, comments }) => {
	// GET USER VISITS
	const [totalVisits, setTotalVisits] = useState([])
	const [totalDrinks, setTotalDrinks] = useState([])
	const [allAttendees, setAllAttendees] = useState([])
	const [totalAttendees, setTotalAttendees] = useState(0)
	const [totalMl, setTotalMl] = useState(0)
	const [totalUnits, setTotalUnits] = useState(0)
	const [totalVenues, setTotalVenues] = useState(0)
	const [todaysVisits, setTodaysVisits] = useState([])
	const [todaysDrinks, setTodaysDrinks] = useState([])
	const [todaysAttendees, setTodaysAttendees] = useState([])
	const [todaysMl, setTodaysMl] = useState(0)
	const [todaysUnits, setTodaysUnits] = useState(0)
	const [todaysVenues, setTodaysVenues] = useState(0)

	const { isLoading: loading, data: userVisitsData } = useQuery({
		queryKey: ["user_visit_stats", userId],
		queryFn: async () => {
			const { data } = await supabase
				.from("user_visits")
				.select(
					"id, timestamp, location:locations(id, name), drinks:user_visit_drinks(id, ml, timestamp, type, drink:drinks(alcohol_percentage)), attendees:user_visit_attendees(id, user:profiles(user_id, username))"
				)
				.eq("user_id", userId)
			return data
		},
		enabled: !!userId,
	})

	useEffect(() => {
		// Create visit objects
		function createVisitObject(obj) {
			const attendees = obj?.attendees || []
			const drinks = obj?.drinks || []
			const id = obj?.id || null
			const location_id = obj?.location?.id || null
			const ml = obj?.ml || null
			const timestamp = obj?.timestamp || null

			const updatedAttendees = attendees.map((item) =>
				createAttendeeObject(item, timestamp)
			)
			const updatedDrinks = drinks.map((item) => createDrinkObject(item))

			return {
				attendees: updatedAttendees,
				drinks: updatedDrinks,
				id,
				location_id,
				ml,
				timestamp,
			}
		}

		// Function to create attendee objects
		function createAttendeeObject(obj, timestamp) {
			const id = obj.id
			const user_id = obj?.user?.user_id || null
			const username = obj?.user?.username || null

			return { id, timestamp, user_id, username }
		}

		// Function to create drink objects
		function createDrinkObject(obj) {
			const alcohol_percentage = obj?.drink?.alcohol_percentage || null
			const id = obj?.id || null
			const timestamp = obj?.timestamp || null
			const ml = obj?.ml || null
			const type = obj?.type || "beer"

			const units = calculateUnits(ml, alcohol_percentage)
			return { alcohol_percentage, id, ml, timestamp, type, units }
		}

		const formattedVisits = userVisitsData?.map((visit) =>
			createVisitObject(visit)
		)
		setTotalVisits(formattedVisits || [])
	}, [userVisitsData])

	// GET TOTAL DRINKS
	useEffect(() => {
		const drinksArray = []

		for (const drinkLog of totalVisits) {
			for (const drink of drinkLog.drinks) {
				drinksArray.push(drink)
			}
		}
		setTotalDrinks(drinksArray)
	}, [totalVisits])

	// GET ALL ATTENDEES
	useEffect(() => {
		const attendeesArray = []

		for (const attendeeLog of totalVisits) {
			for (const attendee of attendeeLog.attendees) {
				attendeesArray.push(attendee)
			}
		}

		setAllAttendees(attendeesArray)
	}, [totalVisits])

	// GET TOTAL ATTENDEES
	useEffect(() => {
		if (total) {
			if (allAttendees.length > 0) {
				let formattedAttendeesTotal = []
				const seenAll = {}
				if (allAttendees.length > 0) {
					formattedAttendeesTotal = allAttendees.map((item) => item.user_id)
				}
				const newTotal = formattedAttendeesTotal.filter((item) => {
					if (!seenAll[item]) {
						seenAll[item] = true
						return true
					}
					return false
				})
				setTotalAttendees(newTotal.length)
			}
		}
	}, [allAttendees, total])

	// GET TOTAL ML
	useEffect(() => {
		let array = totalDrinks
		let ml = array.reduce((acc, item) => acc + item.ml, 0)
		setTotalMl(ml)
	}, [totalDrinks])

	// GET TOTAL UNITS
	useEffect(() => {
		let array = totalDrinks
		let units = array.reduce((acc, item) => acc + item.units, 0)
		units = Math.round((units + Number.EPSILON) * 10) / 10
		setTotalUnits(units)
	}, [totalDrinks])

	// GET TOTAL VENUES
	useEffect(() => {
		let array = new Set()
		for (const item of totalVisits) {
			if (item.location_id !== null) {
				array.add(item.location_id)
			}
		}
		setTotalVenues(array.size)
	}, [totalVisits])

	// TODAYS VISITS
	useEffect(() => {
		if (!total) {
			let totalVisitsToday = []
			if (totalVisits.length > 0) {
				totalVisits.forEach((item) => {
					if (
						[format(currentDate, "P")].includes(
							format(parseISO(item.timestamp), "P")
						)
					) {
						totalVisitsToday.push(item)
					}
				})
				setTodaysVisits(totalVisitsToday)
			}
		}
	}, [totalVisits, currentDate, total])

	// TODAYS DRINKS
	useEffect(() => {
		if (!total) {
			let totalDrinksToday = []
			if (totalDrinks.length > 0) {
				totalDrinks.forEach((item) => {
					if (
						[format(currentDate, "P")].includes(
							format(parseISO(item.timestamp), "P")
						)
					) {
						totalDrinksToday.push(item)
					}
				})
				setTodaysDrinks(totalDrinksToday)
			}
		}
	}, [totalDrinks, currentDate, total])

	// TODAYS ATTENDEES
	useEffect(() => {
		if (!total) {
			let allAttendeesToday = []
			if (allAttendees.length > 0) {
				allAttendees.forEach((item) => {
					if (
						[format(currentDate, "P")].includes(
							format(parseISO(item.timestamp), "P")
						)
					) {
						allAttendeesToday.push(item)
					}
				})
				let formattedAttendeesToday = []
				const seen = {}
				if (allAttendeesToday.length > 0) {
					formattedAttendeesToday = allAttendeesToday.map(
						(item) => item.user_id
					)
				}
				const newTotal = formattedAttendeesToday.filter((item) => {
					if (!seen[item]) {
						seen[item] = true
						return true
					}
					return false
				})
				setTodaysAttendees(newTotal.length)
			}
		}
	}, [allAttendees, currentDate, total])

	// GET TODAYS ML
	useEffect(() => {
		let array = todaysDrinks
		let ml = array.reduce((acc, item) => acc + item.ml, 0)
		setTodaysMl(ml)
	}, [todaysDrinks])

	// GET TODAYS UNITS
	useEffect(() => {
		let array = todaysDrinks
		let units = array.reduce((acc, item) => acc + item.units, 0)
		units = Math.round((units + Number.EPSILON) * 10) / 10
		setTodaysUnits(units)
	}, [todaysDrinks])

	// GET TODAYS VENUES
	useEffect(() => {
		const venueSet = new Set(
			todaysVisits
				.filter((item) => item.location_id !== null)
				.map((item) => item.location_id)
		)
		setTodaysVenues(venueSet.size)
	}, [todaysVisits])

	const storedIsWine = localStorage.getItem(CURRENT_MODE_STORAGE_KEY)
	const initialIsWine = storedIsWine ? JSON.parse(storedIsWine) : true
	const isWine = initialIsWine

	return (
		<div className="statsW">
			{/* beers drank */}
			{!isWine ? (
				<BeersIcon
					ml={total ? totalMl : todaysMl}
					currentDate={currentDate}
					comments={comments}
					loading={loading}
				/>
			) : (
				<WinesIcon
					ml={total ? totalMl : todaysMl}
					currentDate={currentDate}
					comments={comments}
					loading={loading}
				/>
			)}

			<div className="stats">
				{/* venues visited */}
				<Stat
					icon="store"
					title="Attended"
					number={
						total
							? totalVenues > 0
								? `${totalVenues} venue${totalVenues === 1 ? "" : "s"}`
								: "Nowhere"
							: todaysVenues > 0
							? `${todaysVenues} venue${todaysVenues === 1 ? "" : "s"}`
							: "Nowhere"
					}
					loading={loading}
				/>

				{/* units consumed */}
				<Stat
					icon="bottle-droplet"
					title="Alcohol"
					number={
						total
							? totalUnits > 0
								? `${totalUnits} unit${totalUnits === 1 ? "" : "s"}`
								: "None"
							: todaysUnits > 0
							? `${todaysUnits} unit${todaysUnits === 1 ? "" : "s"}`
							: "None"
					}
					loading={loading}
				/>

				{/* friends met */}
				{total ? (
					<Stat
						icon={totalAttendees > 0 ? "user-group" : "user"}
						title={"Met up with"}
						number={
							totalAttendees > 0
								? `${totalAttendees} friend${totalAttendees === 1 ? "" : "s"}`
								: "No one"
						}
						loading={loading}
					/>
				) : (
					<Stat
						icon={todaysAttendees > 0 ? "user-group" : "user"}
						title={"Met up with"}
						number={
							todaysAttendees > 0
								? `${todaysAttendees} friend${todaysAttendees === 1 ? "" : "s"}`
								: "No one"
						}
						loading={loading}
					/>
				)}
			</div>
		</div>
	)
}

export default Stats
