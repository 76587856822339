import { Link, useOutletContext } from "react-router-dom"
import Stats from "../../components/profile/Stats"
import { ShibaIcon } from "../../components/ShibaCount"
import { useQuery } from "@tanstack/react-query"
import { supabase } from "../../lib/supabaseClient"
import * as ROUTES from "../../constants/routes"
import NumberAnimation from "../../components/NumberAnimation"
import AvatarIcon from "../../components/AvatarIcon"
import { differenceInHours, formatDistance } from "date-fns"
import UserProfileNav from "../../components/profile/UserProfileNav"
import Spinner from "../../components/Spinner"
import AddFriendButton from "../../components/profile/AddFriendButton"
import FollowersStats from "../../components/profile/FollowersStats"
import Achievements from "../../components/achievements/Achievements"

export default function ProfilePage() {
	const {
		user,
		userFriends,
		profile,
		username,
		loadingProfile,
		friends,
		followers,
		favourite_locations,
		loadingLocations,
		favourite_drinks,
		loadingDrinks,
	} = useOutletContext()
	const userId = user?.user_id || null
	const profileId = profile?.user_id || null

	let current_visit = profile?.current_visit_id
	let is_current = false
	if (current_visit?.timestamp) {
		is_current =
			differenceInHours(new Date(), new Date(current_visit.timestamp)) < 2
	}

	const { data: count } = useQuery({
		queryKey: ["shibas-spotted", username],
		queryFn: async () => {
			const { data } = await supabase
				.from("shibas")
				.select("id, count")
				.eq("user_id", profileId)
			let totalCount = 0

			if (data) {
				for (const item of data) {
					totalCount += item.count
				}
			}
			return totalCount
		},
		enabled: !!profileId,
	})

	return (
		<>
			<UserProfileNav user={profile} />
			<div className="nav-spacer" />
			<div className="page-container">
				<header>
					<div className="profile-header">
						<div>
							<AvatarIcon
								url={profile?.avatar}
								size={80}
								loading={loadingProfile}
							/>
						</div>
						<div>
							<div className="name">
								{loadingProfile
									? "User not found"
									: `${profile?.f_name} ${profile?.l_name}`}
							</div>

							<FollowersStats
								created_at={profile?.created_at}
								loading={loadingProfile}
								followers={followers}
								friends={friends}
							/>
						</div>
					</div>
					{profileId !== userId && (
						<AddFriendButton
							userId={userId}
							userFriends={userFriends}
							friendId={profileId}
							username={username}
							friends={friends}
						/>
					)}
					{current_visit && (
						<div className={`last-logged-in${is_current ? " is-current" : ""}`}>
							{is_current ? "Logged in" : "Last seen"} :{" "}
							{current_visit.location?.id === 25 ? (
								<b>{current_visit.location?.name}</b>
							) : (
								<Link to={`${ROUTES.LOCATION}/${current_visit.location?.id}`}>
									{current_visit.location?.name}
								</Link>
							)}{" "}
							<span className="time">
								(
								{formatDistance(new Date(current_visit.timestamp), new Date(), {
									addSuffix: true,
								})}
								)
							</span>
						</div>
					)}
				</header>
				<main>
					<section id="stats-section">
						<div className="stat-numbers">
							{/* <div className="stat">
								<div className="label">Friends</div>
								<Link to={"friends"}>
									<div className="number">
										{!loadingFriends ? (
											<NumberAnimation number={friends ? friends.length : 0} />
										) : (
											<Spinner dark inline />
										)}
									</div>
								</Link>
							</div> */}
							<div className="stat">
								<div className="label">Venues</div>
								<Link to={"locations"}>
									<div className="number">
										{!loadingLocations ? (
											<NumberAnimation
												number={
													favourite_locations ? favourite_locations.length : 0
												}
											/>
										) : (
											<Spinner dark inline />
										)}
									</div>
								</Link>
							</div>
							<div className="stat">
								<div className="label">Drinks</div>
								<Link to={"drinks"}>
									<div className="number">
										{!loadingDrinks ? (
											<NumberAnimation
												number={favourite_drinks ? favourite_drinks.length : 0}
											/>
										) : (
											<Spinner dark inline />
										)}
									</div>
								</Link>
							</div>
						</div>
					</section>
					<section id="totals-section">
						<Stats userId={profileId} total={true} comments={true} />
					</section>

					<section id="spotted-shibas">
						<div className="spotted-shibas">
							<ShibaIcon button={false} />
							<div className="count">
								<strong>
									<NumberAnimation number={count || 0} />
								</strong>
								spotted Shibas
							</div>
						</div>
					</section>
					<section id="achievements-section">
						<h2 className="h2">Achievements</h2>
						<Achievements userId={profileId} achieved={true} />
					</section>
				</main>
			</div>
		</>
	)
}
