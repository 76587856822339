import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Link, useNavigate, useOutletContext } from "react-router-dom"
import { supabase } from "../../lib/supabaseClient"
import * as ROUTES from "../../constants/routes"
import executeQuery from "../../helpers/executeQuery"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import BeersIcon from "../../components/profile/Beers"

export default function AddDrinkPage() {
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { user, currentVisit } = useOutletContext()
	let userId = user?.user_id || null

	// Handle search
	const [query, setQuery] = useState("")
	const [queryError, setQueryError] = useState(false)
	const [searchResults, setSearchResults] = useState([])
	const [selectedDrink, setSelectedDrink] = useState(null)

	// Quick add
	const [size, setSize] = useState(0)
	const [sizeError, setSizeError] = useState(false)
	const [percentage, setPercentage] = useState(0)
	const [percentageDisabled, setPercentageDisabled] = useState(false)
	const [percentageError, setPercentageError] = useState(false)
	const [isPrivate, setIsPrivate] = useState(false)

	useEffect(() => {
		const handleSearch = async () => {
			const { data } = await executeQuery(
				supabase
					.from("drinks")
					.select("name, id, alcohol_percentage")
					.or(`name.ilike.%${query}%`)
					.neq("type", "9")
					.order("name", { ascending: true })
					.limit(5)
			)
			setSearchResults(data)
		}

		if (query.trim() === "") {
			setSearchResults([])
			return
		}
		handleSearch()
	}, [query])

	function handleOnChange(e) {
		setQuery(e.target.value)
	}

	const insertUserVisitDrink = useMutation({
		mutationFn: async (drink) => {
			const { data } = await executeQuery(
				supabase.from("user_visit_drinks").insert([drink]).select()
			)
			return data
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["current_visit", userId])
		},
	})

	const insertLocationDrink = useMutation({
		mutationFn: async (upload) => {
			const { data } = await executeQuery(
				supabase.from("location_drinks").insert([upload]).select()
			)
			return data
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["location_drinks"])
		},
	})

	async function handleOnSubmit(e) {
		e.preventDefault()

		try {
			if (!selectedDrink) {
				setQueryError(true)
				throw new Error("Please select a drink")
			}
			if (!size > 0) {
				setSizeError(true)
				throw new Error("Please select an amount")
			}
			if (!currentVisit) {
				throw new Error("No current visit")
			}

			let newLocationDrink = {
				drink_id: selectedDrink.id,
				location_id: currentVisit.location.id,
			}
			insertLocationDrink.mutate(newLocationDrink)

			let newUserVisitDrink = {
				user_visit_id: currentVisit?.id,
				drink_id: selectedDrink.id,
				ml: size,
				alcohol_percentage: percentage,
				type: "beer",
				private: isPrivate,
			}
			insertUserVisitDrink.mutate(newUserVisitDrink)

			navigate(ROUTES.DASHBOARD)
		} catch (error) {
			console.error(error.message)
		}
	}

	const QuickAdd = ({ icon, title, ml }) => {
		return (
			<div
				className="quick-add"
				onClick={() => {
					setSizeError(false)
					setSize(ml)
				}}>
				{icon && (
					<div className="icon">
						<FontAwesomeIcon icon={icon} />
					</div>
				)}
				{title && <div className="title">{title}</div>}
				{ml && <div className="amount">{`(${ml} ml)`}</div>}
			</div>
		)
	}

	function handleReturn() {
		navigate(ROUTES.DASHBOARD)
	}

	return (
		<>
			<div className="page-dashboard">
				<div className="page-container">
					<main>
						<div>
							<form onSubmit={handleOnSubmit}>
								<p>
									<FontAwesomeIcon
										icon={`arrow-left`}
										style={{ cursor: "pointer" }}
										onClick={() => handleReturn()}
									/>
								</p>
								<h3>What are you drinking?</h3>

								<fieldset>
									<BeersIcon ml={size} icon />
								</fieldset>
								<fieldset>
									<label htmlFor="drink">Your new drink:</label>
									<div className="search-bar">
										<button>
											<FontAwesomeIcon
												icon={`fas fa-${selectedDrink ? "check" : "search"}`}
											/>
										</button>
										<input
											id="drink"
											autoComplete="off"
											className="input"
											placeholder={`_`}
											type="text"
											name="drink"
											value={query || ""}
											onChange={handleOnChange}
										/>
										<button
											onClick={(e) => {
												e.preventDefault()
												setQuery("")
												setSelectedDrink(null)
												setPercentage(null)
												setPercentageDisabled(false)
											}}>
											<FontAwesomeIcon icon={`times`} />
										</button>
									</div>
									{queryError && <p className="error">Please chose a drink</p>}
									{searchResults.length > 0 && (
										<div className="search-bar-results">
											{!selectedDrink && (
												<ul>
													{searchResults.map((result) => (
														<li
															key={result.id}
															onClick={() => {
																setQuery(result.name)
																setPercentage(result.alcohol_percentage)
																setPercentageDisabled(true)
																setSelectedDrink(result)
																setSearchResults([])
																setQueryError(false)
															}}>
															<div>{result.name}</div>
															<div>
																<small>{result.address}</small>
															</div>
														</li>
													))}
												</ul>
											)}
										</div>
									)}
									<input
										name="drink-id"
										value={selectedDrink || ""}
										onChange={(e) => setSelectedDrink(e.target.value)}
										style={{ display: "none" }}
									/>
									<div style={{ textAlign: "center" }}>
										<small>
											<Link to={`../new-drink`}>
												Not in the list? <strong>Add it!</strong>
											</Link>
										</small>
									</div>
								</fieldset>

								<fieldset>
									<div className="row" style={{ gap: 30 }}>
										<div className="amount-field">
											<label htmlFor="size">Size:</label>
											<div className="input-group">
												<input
													id="size"
													type="number"
													placeholder="0"
													min={0}
													value={size || ""}
													onChange={(e) => {
														setSizeError(false)
														setSize(e.target.value)
													}}
												/>
												<div className="unit">ml</div>
											</div>
										</div>
										<div className="amount-field">
											<label htmlFor="percentage">Percentage:</label>
											<div className="input-group">
												<input
													id="percentage"
													type="number"
													placeholder="0.0"
													disabled={percentageDisabled}
													step="0.1"
													min={0}
													max={100}
													value={percentage || ""}
													onChange={(e) => {
														setPercentageError(false)
														setPercentage(e.target.value)
													}}
												/>
												<div className="unit">%</div>
											</div>
										</div>
									</div>
									{sizeError && (
										<p className="error" style={{ textAlign: "center" }}>
											Please add size
										</p>
									)}

									{percentageError && (
										<p className="error" style={{ textAlign: "center" }}>
											Please add percentage
										</p>
									)}
								</fieldset>

								<fieldset>
									<p>
										<strong>Quick adds:</strong>
									</p>
									<div className="quick-adds">
										<QuickAdd icon="wine-glass" title="half" ml={250} />
										<QuickAdd icon="wine-bottle" title="bottle" ml={330} />
										<QuickAdd icon="glass-water" title="schooner" ml={425} />
										<QuickAdd icon="beer" title="pint" ml={500} />
									</div>
								</fieldset>

								<fieldset>
									<button className="button">Submit</button>
								</fieldset>

								<fieldset
									style={{ textAlign: "center", marginTop: 15, lineHeight: 1 }}>
									<small>
										Make session private:
										<input
											style={{
												display: "inline-block",
												width: "auto",
												marginLeft: 10,
												verticalAlign: "bottom",
											}}
											type="checkbox"
											name="private"
											id="private"
											value={isPrivate}
											onClick={() => setIsPrivate(!isPrivate)}
										/>
									</small>
								</fieldset>
							</form>
						</div>
					</main>
				</div>
			</div>
		</>
	)
}
