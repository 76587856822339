import React, { useEffect } from "react"
import { Outlet } from "react-router-dom"

export default function AuthLayout() {
	// LOADING FLUID
	useEffect(() => {
		async function wait() {
			return new Promise((resolve) => setTimeout(resolve, 500))
		}
		async function fluidLaunch() {
			const fluidEl = document?.querySelector(".fluid")
			fluidEl?.classList?.remove("is-active")
		}
		async function load() {
			await wait()
			await fluidLaunch()
		}
		load()
	}, [])

	return (
		<div className="auth-page">
			<div className="container">
				<Outlet />
			</div>
		</div>
	)
}
