// EMAIL
export function isValidEmail(email) {
	const emailRegex = /^[a-zA-Z0-9.+-_]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
	return emailRegex.test(email)
}

// USERNAME
export function isUsernameValid(string) {
	return string.length > 3 && string.length < 21
}

export function isTooLong(string) {
	return string.length < 21
}

// NAMES
export function isMoreThanOne(string) {
	return string.length > 1
}

// PASSWORD
export function isValidPassword(password) {
	if (password.length < 6) {
		return false
	} else if (!/[a-z]/.test(password)) {
		return false
	} else if (!/[A-Z]/.test(password)) {
		return false
	} else if (!/[0-9]/.test(password)) {
		return false
	} else if (!/[^a-zA-Z0-9]/.test(password)) {
		return false
	} else return true
}

export function isValidPasswordError(password) {
	if (password === "") {
		return ""
	} else if (password.length < 6) {
		return "Password must be at least 6 characters long"
	} else if (!/[a-z]/.test(password)) {
		return "Password must contain lowercase letters"
	} else if (!/[A-Z]/.test(password)) {
		return "Password must contain uppercase letters"
	} else if (!/[0-9]/.test(password)) {
		return "Password must contain numbers"
	} else if (!/[^a-zA-Z0-9]/.test(password)) {
		return "Password must contain symbols"
	} else {
		return false
	}
}
