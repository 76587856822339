import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"
import LikeButton from "../LikeButton"

const LocationNav = ({ count, setCount, isLiked }) => {
	const navigate = useNavigate()

	function handleCancel() {
		navigate(-1)
	}

	return (
		<>
			<div className="location-nav">
				<div className="cancel" onClick={(e) => handleCancel(e)}>
					<FontAwesomeIcon icon="arrow-left" />
				</div>
				<div onClick={() => setCount(count + 1)}>
					<LikeButton number={false} count={count} isLiked={isLiked} />
				</div>
			</div>
		</>
	)
}

export default LocationNav
