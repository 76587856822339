import AchievementsNav from "../../components/achievements/AchievementsNav"
import { useSession } from "../../context/SessionContext"
import Achievements from "../../components/achievements/Achievements"

export default function AchievementsPage() {
	const { session } = useSession()
	let userId = session?.user?.id || null

	return (
		<>
			<div className="achievements-page">
				<AchievementsNav />
				<div className="nav-spacer" />
				<div className="page-container">
					<main>
						<div>
							<Achievements userId={userId} />
						</div>
					</main>
				</div>
			</div>
		</>
	)
}
