import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function MapStatic({
	lng = 0,
	lat = 0,
	zoom = 14,
	h = 200,
	w = 600,
	style = "navigation-day-v1",
}) {
	const [isLoading, setIsLoading] = useState(true)
	useEffect(() => {
		const fetchMapImage = async () => {
			const url = `https://api.mapbox.com/styles/v1/mapbox/${style}/static/${lng},${lat},${zoom}/${w}x${h}@2x?access_token=pk.eyJ1IjoidG9tdW5nZXJlciIsImEiOiJjbGM2dmE5NGcybTZuM3FwOHc4YWY0a3drIn0.K1kFbu6oiqXAFhmaxdxsVA`
			try {
				const response = await fetch(url)
				if (!response.ok) {
					throw new Error("Failed to fetch map image")
				}
				setIsLoading(false) // Set loading to false on successful fetch
			} catch (error) {
				console.error("Error fetching map image:", error)
				// Handle errors appropriately, e.g., display an error message
			}
		}

		fetchMapImage()
	}, [lat, lng, zoom, h, w, style]) // Update the effect when props change
	const mapImageUrl = `https://api.mapbox.com/styles/v1/mapbox/${style}/static/${lng},${lat},${zoom}/${w}x${h}@2x?access_token=pk.eyJ1IjoidG9tdW5nZXJlciIsImEiOiJjbGM2dmE5NGcybTZuM3FwOHc4YWY0a3drIn0.K1kFbu6oiqXAFhmaxdxsVA`

	return (
		<div className={`map-container${isLoading ? " is-loading" : " is-loaded"}`}>
			<div className="pin bounce-in-right">
				<FontAwesomeIcon icon="location-dot" />
			</div>
			<img src={mapImageUrl} alt="Map" />
		</div>
	)
}
