import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function WineButton({
	selectedDrink,
	setSelectedDrink,
	// setQueryError,
	setPercentage,
	id,
	icon,
	title,
}) {
	function handleOnClick() {
		let percentage =
			id === 16 ? 12.2 : id === 15 ? 12.5 : id === 14 ? 10 : 13.5 || 0.0
		setSelectedDrink(id)
		setPercentage(percentage)
		// setQueryError(false)
	}
	return (
		<div
			className={`wine-button ${selectedDrink === id ? "selected" : ""} ${
				id === 16
					? "champagne"
					: id === 15
					? "rose"
					: id === 14
					? "white"
					: "red"
			}`}
			onClick={handleOnClick}>
			<div className="icon">
				<FontAwesomeIcon icon={icon} />
			</div>
			<h4 className="title">{title}</h4>
		</div>
	)
}
