export const DASHBOARD = "/dashboard"

export const NOT_FOUND = "/not-found"

export const LANDING_PAGE = "/"

export const LOGIN = "/login"
export const FORGOT_PASSWORD = "/forgot-password"
export const UPDATE_PASSWORD = "/update-password"
export const UPDATE_PASSWORD_STEP_2 = "/update-password-1"
export const SIGN_UP = "/sign-up"
export const NEW_USER = "/new-user"

export const ADD_LOCATION = "/dashboard/add-location"
export const ADD_ATTENDEES = "/dashboard/add-attendees"
export const ADD_DRINK = "/dashboard/add-drink"
export const ADD_WINE = "/dashboard/add-wine"

export const PROFILE = "/profiles"
export const LOCATION = "/locations"
export const DRINK = "/drinks"

export const ME = "/me"
export const EDIT_PROFILE = "/accounts/edit"
export const HISTORY = "/history"
export const NOTIFICATIONS = "/notifications"

export const EXPLORE = "/explore"

export const ACHIEVEMENTS = "/achievements"

export const CALCULATOR = "/calculator"

export const RANDOM_LOCATION = "/random-location"

// export const RANKINGS = "/rankings"
