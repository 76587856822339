import { useNavigate, useOutletContext } from "react-router-dom"
import ResultUser from "../../components/explore/ResultUser"
import * as ROUTES from "../../constants/routes"
import Spinner from "../../components/Spinner"
import { Fragment, useEffect, useRef, useState } from "react"
import SearchBar from "../../components/explore/SearchBar"

export default function ListPageFriends() {
	const navigate = useNavigate()
	const focusRef = useRef()

	const { name, friends: friendsList, loadingFriends } = useOutletContext()
	let friends = friendsList?.map((item) => item.user) || []

	const [query, setQuery] = useState("")
	const [filteredList, setFilteredList] = useState(friends)

	useEffect(() => {
		if (query === "") {
			setFilteredList(friends)
		}
		const filtered = friends.filter((item) => {
			const searchTerm = query.toLowerCase()
			return (
				item.username.toLowerCase().includes(searchTerm) ||
				item.f_name.toLowerCase().includes(searchTerm) ||
				item.l_name.toLowerCase().includes(searchTerm)
			)
		})
		setFilteredList(filtered)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query, loadingFriends])

	function handleOnClick(item) {
		navigate(`${ROUTES.PROFILE}/${item?.username}`)
	}

	return (
		<>
			{filteredList.length > 4 && (
				<SearchBar focusRef={focusRef} query={query} setQuery={setQuery} />
			)}
			<section className="list-section">
				<h3 className="list-title">{name}friends</h3>
				{loadingFriends ? (
					<Spinner />
				) : filteredList.length > 0 ? (
					<ul className="list">
						{filteredList.map((item) => (
							<ResultUser
								key={item.user_id}
								user={item}
								handleOnClick={handleOnClick}
							/>
						))}
					</ul>
				) : (
					<div className="no-result">No friends added</div>
				)}
			</section>
		</>
	)
}
