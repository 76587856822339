import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function ResultDrink({ drink, handleOnClick }) {
	return (
		<li onClick={() => handleOnClick(drink)}>
			<div className="result" data-type="drink">
				{drink.type && (
					<div className="icon">
						<FontAwesomeIcon
							icon={
								drink.drink_subtypes?.drink_type?.id === 5
									? "wine-bottle"
									: "beer"
							}
						/>
					</div>
				)}
				<div className="text">
					{drink.name && <div className="name">{drink.name}</div>}
					{drink.drink_subtypes && drink.alcohol_percentage && (
						<div className="type">
							{drink.drink_subtypes?.name} (
							{drink.drink_subtypes?.drink_type?.name}) •{" "}
							{drink.alcohol_percentage}%
						</div>
					)}
				</div>
			</div>
		</li>
	)
}
