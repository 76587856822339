import React, { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { APP_NAME } from "../.."
import ExploreNav from "../../components/explore/ExploreNav"
import { RECENT_SEARCHES_STORAGE_KEY } from "../../constants/paths"

export default function ExploreLayout() {
	const [storedSearches, setStoredSearches] = useState([])
	const [query, setQuery] = useState("")

	const contextData = {
		storedSearches: storedSearches,
		query: query,
	}

	useEffect(() => {
		document.title = `Explore • ${APP_NAME}`

		const localStoredSearches = JSON.parse(
			localStorage.getItem(RECENT_SEARCHES_STORAGE_KEY)
		)
		setStoredSearches(localStoredSearches || [])
	}, [])

	return (
		<>
			<ExploreNav query={query} setQuery={setQuery} />
			<div className={`explore-page`}>
				<div className="container">
					<main>
						<Outlet context={contextData} />
					</main>
				</div>
			</div>
		</>
	)
}
