import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	Outlet,
	useMatch,
	useNavigate,
	useOutletContext,
} from "react-router-dom"
import FavNav from "../../components/profile/FavNav"
import { useSwipeable } from "react-swipeable"
import { useState } from "react"

export default function ListLayout() {
	const navigate = useNavigate()
	const {
		user,
		username,
		profile,
		loadingProfile,
		friends,
		loadingFriends,
		favourite_locations,
		loadingLocations,
		favourite_drinks,
		loadingDrinks,
	} = useOutletContext()

	const match1 = useMatch("profiles/:username/locations")
	const match2 = useMatch("profiles/:username/drinks")
	let m = match1 ? 1 : match2 ? 2 : 0

	function handleBack() {
		navigate("./")
	}

	const [swiping, setSwiping] = useState(false)

	const handlers = useSwipeable({
		onSwipeStart: (e) => {
			if (e.dir === "Left") {
				setSwiping("left")
			}
			if (e.dir === "Right") {
				setSwiping("right")
			}
		},
		onSwiped: (e) => {
			setSwiping(false)
			if (e.dir === "Left") {
				if (m === 0) {
					navigate("locations")
				} else if (m === 1) {
					navigate("drinks")
				}
			}
			if (e.dir === "Right") {
				setSwiping(false)
				if (m === 2) {
					navigate("locations")
				} else if (m === 1) {
					navigate("friends")
				}
			}
		},
	})

	let name = ""
	if (profile) {
		let f_name = profile?.f_name
		name = f_name.endsWith("s") ? `${f_name}' ` : `${f_name}'s `
	}

	return (
		<div className={`list-page`}>
			<div className="list-page-navbar">
				<div className="back" onClick={(e) => handleBack(e)}>
					<FontAwesomeIcon icon="arrow-left" />
				</div>
				<div className="title">{`@${profile?.username}`}</div>
			</div>
			<FavNav />
			<div
				className={`list-container swiping ${
					swiping ? swiping : "not"
				}-swiping`}
				{...handlers}>
				<div className="spacer" />

				<Outlet
					context={{
						user,
						username,
						name,
						profile,
						loadingProfile,
						friends,
						loadingFriends,
						favourite_locations,
						loadingLocations,
						favourite_drinks,
						loadingDrinks,
					}}
				/>
			</div>
		</div>
	)
}
