import { useEffect, useState } from "react"
import { supabase } from "../../lib/supabaseClient"
import { useQuery } from "@tanstack/react-query"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Select from "react-select"
import { format } from "date-fns"

export default function DrinkEdit({
	date,
	drink,
	drinks,
	setDrinks,
	removeDrink,
	setActiveModal,
}) {
	const [isNew, setIsNew] = useState(false)
	useEffect(() => {
		setIsNew(drink?.name ? false : true)
	}, [drink])

	const [time, setTime] = useState(format(new Date(date), "HH:mm"))
	const [dateTime, setDateTime] = useState(format(new Date(date), "yyyy-MM-dd"))
	const [newDrink, setNewDrink] = useState(
		drink?.name
			? {
					label: drink?.name,
					value: drink?.drink_id,
			  }
			: ""
	)
	const [ml, setMl] = useState(drink?.ml || 0)
	const [timeError, setTimeError] = useState(false)
	const [newDrinkError, setNewDrinkError] = useState(false)
	const [mlError, setMlError] = useState(false)

	const { isLoading: isLoadingDrinks, data: allDrinks } = useQuery({
		queryKey: ["drinks"],
		queryFn: async () => {
			const { data } = await supabase
				.from("drinks")
				.select("id, name, alcohol_percentage, type")
				.order("name", { ascending: true })
			return data
		},
	})

	function updateDrink(e) {
		e.preventDefault()

		if (!newDrink?.value) {
			setNewDrinkError("Please select a drink")
			return
		}
		if (!time) {
			setTimeError("Please select a time")
			return
		}
		if (ml <= 0) {
			setMlError("Please enter an amount in milliliters")
			return
		}
		let tempDrink = allDrinks?.find((d) => d.id === newDrink?.value)

		let updatedDrink = {
			...drink,
			timestamp: `${dateTime}T${time}:00+01:00`,
			drink_id: tempDrink?.id,
			ml: parseInt(ml),
			name: tempDrink?.name,
			alcohol_percentage: tempDrink?.alcohol_percentage,
			type: tempDrink?.type,
		}

		let updatedDrinks = drinks.filter((d) => d.id !== drink.id) || []
		updatedDrinks = [...updatedDrinks, updatedDrink]
		setDrinks(updatedDrinks)
		setActiveModal(false)
	}

	return (
		<form className="form" onSubmit={updateDrink}>
			<div className="back">
				<div
					className="icon"
					onClick={() => {
						setActiveModal(null)
						if (isNew) setDrinks(removeDrink(drink.id))
					}}>
					<FontAwesomeIcon icon="arrow-left" />
				</div>
			</div>
			<fieldset>
				<div className="row">
					<div style={{ flex: 1 }}>
						<Select
							className="react-select-container"
							classNamePrefix="react-select"
							defaultValue={newDrink}
							isLoading={isLoadingDrinks}
							onChange={(e) => {
								setNewDrink(e)
								setNewDrinkError(false)
							}}
							id="drink"
							theme={(theme) => ({
								...theme,
								borderRadius: 0,
								colors: {
									...theme.colors,
									primary25: "rgba(34, 34, 34, 0)",
									primary50: "rgba(34, 34, 34, 0.5)",
									primary: "rgba(34, 34, 34, 1)",
								},
							})}
							options={allDrinks?.map((d) => ({
								label: d.name,
								value: d.id,
							}))}
						/>
					</div>
					<div className="remove-visit">
						<div
							className="icon"
							onClick={() => {
								setActiveModal(false)
								setDrinks(removeDrink(drink.id))
							}}>
							<FontAwesomeIcon icon="trash" />
						</div>
					</div>
				</div>
				{newDrinkError && <p className="error">{newDrinkError}</p>}
			</fieldset>
			<fieldset className="fieldset">
				<div className="row">
					<div>
						<input
							id="date"
							type="date"
							disabled
							value={dateTime}
							onChange={(e) => {
								setDateTime(e.target.value)
							}}
							autoComplete="off"
						/>
					</div>
					<div>
						<input
							id="time"
							type="time"
							value={time}
							onChange={(e) => {
								setTime(e.target.value)
							}}
							autoComplete="off"
						/>
					</div>
				</div>
				{timeError && <p className="error">{timeError}</p>}
			</fieldset>
			<fieldset>
				<div className="row">
					<div>
						<input
							id="quantity"
							type="number"
							placeholder="0"
							min={0}
							value={ml || ""}
							onChange={(e) => {
								setMl(e.target.value)
								setMlError(false)
							}}
							autoComplete="off"
						/>
					</div>
					<div className="suffix">ml</div>
				</div>

				{mlError && <p className="error">{mlError}</p>}
			</fieldset>

			<p>
				<button className="button">
					{!isNew ? "update drink" : "add drink"}
				</button>
			</p>
			<div style={{ textAlign: "center" }}>
				<span
					className="cancel"
					onClick={() => {
						setActiveModal(null)
						if (isNew) setDrinks(removeDrink(drink.id))
					}}>
					cancel
				</span>
			</div>
		</form>
	)
}
