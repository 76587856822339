const Spinner = ({
	fullPage = true,
	dark = false,
	white = false,
	inline = false,
}) => {
	return (
		<span
			className={
				fullPage === true && inline === false ? "spinnerW" : "spinner"
			}>
			<span
				className={`dot-spinner${white ? " white" : ""}${dark ? " dark" : ""}`}>
				<span className="dot-spinner__dot" />
				<span className="dot-spinner__dot" />
				<span className="dot-spinner__dot" />
				<span className="dot-spinner__dot" />
				<span className="dot-spinner__dot" />
				<span className="dot-spinner__dot" />
				<span className="dot-spinner__dot" />
				<span className="dot-spinner__dot" />
			</span>
		</span>
	)
}

export default Spinner
