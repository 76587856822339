import { supabase } from "../lib/supabaseClient"

// const achievementStrategies = {
//   1: (user, target) => user.user_number <= target,
//   2: (user, target) => {
//     // Implement logic for checking the amount of drinks
//     return true;
//   },
//   3: (user, target) => {
//     // Implement logic for checking the amount of locations
//     return true;
//   },
// };

// async function launchModal() {
//   return 'hello'
// }

async function completeAchievement(id, target) {
	const { data, error: insertError } = await supabase
		.from("user_achievements")
		.update({ completed: true, progress: target })
		.eq("id", id)
		.select()
	if (insertError) throw insertError // Handle insert error
	return data // Return the newly created UserAchievement
}

export async function checkAchievementProgress(
	id,
	achievement_id,
	user,
	completed,
	target,
	progress,
	type
) {
	if (completed) return
	// type is subscribers
	if (type === 1) {
		if (user) {
			if (user.user_number <= target) {
				await completeAchievement(id, target)
			}
		}
	}
	// type is amount of drinks
	if (type === 2) {
		console.log(target)
	}
	// type is amount of locations
	if (type === 3) {
		console.log(target)
	}
}

export const checkAndCreateUserAchievement = async (achievementId, userId) => {
	if (!achievementId || !userId) return
	// 1. Check if UserAchievement exists
	const { data: existingAchievement, error: checkError } = await supabase
		.from("user_achievements")
		.select("*")
		.eq("user_id", userId)
		.eq("achievement_id", achievementId)
		.maybeSingle()

	if (checkError) throw checkError // Handle check error

	// 2. Add UserAchievement if it doesn't exist
	if (!existingAchievement) {
		const { data, error: insertError } = await supabase
			.from("user_achievements")
			.insert([{ user_id: userId, achievement_id: achievementId }])
			.single()
		if (insertError) throw insertError // Handle insert error
		return data // Return the newly created UserAchievement
	}

	return existingAchievement // Return existing UserAchievement if found
}

// example : on user creation check if user_number is lower than 100
// export function checkIfSuperEarlyBird() {}
