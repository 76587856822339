import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { APP_NAME } from "../.."
import Logo from "../../components/Logo"
import { useSession } from "../../context/SessionContext"
import * as ROUTES from "../../constants/routes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const LandingPage = () => {
	const navigate = useNavigate()
	const { session } = useSession()

	useEffect(() => {
		async function wait() {
			return new Promise((resolve) => setTimeout(resolve, 2000))
		}

		async function fluidLaunch() {
			const fluidEl = document.querySelector(".fluid")
			fluidEl?.classList?.add("is-active")
		}

		async function navigateToLogin() {
			const timerId = setTimeout(
				() => navigate(session ? ROUTES.DASHBOARD : ROUTES.LOGIN),
				2500
			)
			return () => clearTimeout(timerId) // Clear timer on unmount
		}

		async function loadPage() {
			await wait()
			await fluidLaunch()
			await navigateToLogin()
		}

		loadPage()
	}, [session, navigate])

	useEffect(() => {
		document.title = `${APP_NAME}`
	}, [])

	return (
		<div className="landing-page">
			<div className="container">
				<header>
					<div className="logoW">
						<Logo />
					</div>
					<h1>
						<span>Pints of Nice</span>
					</h1>
				</header>
				<main>
					<p>
						<strong>Consume in moderation!</strong>
					</p>
				</main>
				<footer>
					<p>
						<small>
							&copy; 2024 {APP_NAME}
							<br /> All rights reserved
						</small>
					</p>
					<p>
						<a
							href="https://instagram.com/pintsofnice"
							target="_blank"
							rel="noreferrer">
							<FontAwesomeIcon icon={`fab fa-instagram`} />
						</a>
					</p>
				</footer>
			</div>
		</div>
	)
}

export default LandingPage
